import { Injectable } from "@angular/core";
import { Http } from "@angular/http";
import { ProductVm, EditProductVm, SaveResult } from "../models";
import { BaseService } from "../core/services/base.service";

@Injectable()
export class ProductService extends BaseService
{
	constructor (http: Http)
	{
		super(http);
	}
	public load() : Promise<ProductVm[]>
	{
		return this.get<ProductVm[]>(this.baseUri + `Load`);
	}
	public getEditProductVm(id: number) : Promise<EditProductVm>
	{
		return this.get<EditProductVm>(this.baseUri + `getEditProductVm/${id}`);
	}
	public save(model: EditProductVm) : Promise<SaveResult>
	{
    model.id = model.id || 0
		return this.post<SaveResult>(this.baseUri + `Save`, model);
	}
	private baseUri: string = 'product/';
}