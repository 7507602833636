﻿import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { ProfileEditComponent } from './profile-edit/profile-edit.component';
import { ProfileService } from '../models';

@NgModule({
    imports: [
        SharedModule,
    ],
    declarations: [
        ProfileEditComponent,
    ],
    providers: [
        ProfileService,
    ],
    exports: [
        ProfileEditComponent,
    ],
    entryComponents: [
        ProfileEditComponent,
    ]
})
export class ProfileModule { }