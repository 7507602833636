﻿import { Directive, ElementRef, HostListener, Renderer, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export const DATE_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DateValueAccessorDirective),
    multi: true
};

/**
 * The accessor for writing a value and listening to changes on a date input element
 *
 *  ### Example
 *  `<input type="date" name="myBirthday" ngModel useValueAsDate>`
 */
@Directive({
    selector: '[useValueAsDate]',
    providers: [DATE_VALUE_ACCESSOR]
})
export class DateValueAccessorDirective implements ControlValueAccessor {

    @HostListener('input', ['$event.target.valueAsDate']) onChange = (_: any) => { };
    @HostListener('blur', []) onTouched = () => { };

    constructor(private renderer: Renderer, private elementRef: ElementRef) {
    }

    writeValue(value: any): void {
        let date = Date.parse(value);
        if (isNaN(date)) {
            return;
        }
        this.renderer.setElementProperty(this.elementRef.nativeElement, 'valueAsDate', new Date(date));
    }

    registerOnChange(fn: (_: any) => void): void { this.onChange = fn; }
    registerOnTouched(fn: () => void): void { this.onTouched = fn; }

    setDisabledState(isDisabled: boolean): void {
        this.renderer.setElementProperty(this.elementRef.nativeElement, 'disabled', isDisabled);
    }
}