import { Config } from "../core/config"

export class Urls {
  static ProductList = 'products'
  static ProductionRequestList = 'production-requests'
  static ProductionRequestDetails = 'production-request/detail/:id'

  static SalesOrderList = 'sales-orders'
  static SalesOrderDetails = 'sales-order/detail/:id'

  static ExtProductionRequestDetails = 'ext/production-request/detail/:id'
  static ExtSalesOrderDetails = 'ext/sales-order/detail/:id'
  static ExtShipRequestEdit = 'ext/ship-request/edit/:id'

  static ShipRequestList = 'ship-request'
  static ShipRequestEdit = 'ship-request/edit/:id'
  
  static SnRegistrationList = 'sn-registration'
  static SnRegistrationCreate = 'sn-registration/create'
  static SnRegistrationEdit = 'sn-registration/edit/:id'

  static SnDetails = 'sn/detail/'

  static RmaDetails = 'rmaOrder/detail/:id'

  static EventHistoryList = 'event-history'

  static GetEventFileDownload = (id) => Config.apiUrl + 'EventFile/Download/' + id
  static GetProductionRequestDetails = (id) => Urls.ProductionRequestDetails.replace(':id',id)
  static GetSalesOrderDetails = (id) => Urls.SalesOrderDetails.replace(':id',id)
  static GetShipRequestEdit = (id) => Urls.ShipRequestEdit.replace(':id',id)
  static GetShipRequestEditByShipment = (id) => Urls.GetShipRequestEdit(0) + '?shipmentId=' + id
  static GetRmaDetails = (id) => Urls.RmaDetails.replace(':id',id)
  
  static GetExtProductionRequestDetails = (id) => Urls.ExtProductionRequestDetails.replace(':id',id)
  static GetExtSalesOrderDetails = (id) => Urls.ExtSalesOrderDetails.replace(':id',id)
  static GetExtShipRequestEdit = (id) => Urls.ExtShipRequestEdit.replace(':id',id)
  static GetExtShipRequestEditByShipment = (id) => Urls.GetExtShipRequestEdit(0) + '?shipmentId=' + id
  
  static GetSnRegistrationEdit = (id) => Urls.SnRegistrationEdit.replace(':id',id)
  constructor() { }
}