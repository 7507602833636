﻿import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { EditProductVm } from '../../models';
import { Urls } from '../../routing/urls';
import { ProductService } from '../product-service';

@Component({
    selector: 'product-edit',
    templateUrl: './product-edit.component.html',
})
export class ProductEditComponent implements OnInit {
    @ViewChild('formElement') formElement: FormGroup;
    model: EditProductVm;

    isBusy: boolean;

    constructor(
        private service: ProductService,
        private route: ActivatedRoute,
        private router: Router
    ) { }

    async ngOnInit(): Promise<void> {
        this.model = new EditProductVm();
        this.model.id = +this.route.snapshot.params['id'];
        if (this.model.id) {
            this.model = await this.service.getEditProductVm(this.model.id);
        }
    }

    async submit(): Promise<void> {
        if (this.formElement.invalid) {
            return;
        }

        await this.service.save(this.model);
        this.navigateBack();
    }

    async setDeleted(): Promise<void> {
        this.isBusy = true;
        console.log("Discontinued: " + this.model.isDeleted);
        //await this.service.setDeleted(this.model.id, this.model.isDeleted);
        this.isBusy = false;
    }

    navigateBack(): void {
        this.router.navigate([Urls.ProductList])
    }
}