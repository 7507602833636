﻿import { Injectable } from '@angular/core';
import { Enums } from "../../models/view-models";
import { getUser, getToken } from '../../tools/auth-storage'

@Injectable()
export class AuthService {
    userName: string;
    token: string;

    get isAdmin(): boolean {
        const user = getUser()
        return user && user.role == Enums.Role.Admin;
    }

    get isInternal(): boolean {
        const user = getUser()
        return user && (user.role == Enums.Role.Internal ||  user.role == Enums.Role.Admin);
    }

    get isExternal(): boolean {
        const user = getUser()
        return user && user.role == Enums.Role.External;
    }

    get isAnonymous(): boolean {
        const token = getToken()
        return !token;
    }
}
