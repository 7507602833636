﻿import { Component, Input } from '@angular/core';

import { EditEntityShipRequestVm } from '../../models';

@Component({
    selector: 'ship-request-detail',
    templateUrl: './ship-request-detail.component.html',
})
export class ShipRequestDetailComponent {
    @Input() entity: EditEntityShipRequestVm;
    listOne = [32,52,58,33,50,34,49,55,35,39];
    listTwo = this.listOne.push(46);
}