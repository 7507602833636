﻿import { NgModule } from '@angular/core';

import { ModalModule } from 'ngx-bootstrap';

import { SharedModule } from '../shared/shared.module';
import { SerialNumberListComponent } from './serial-number-list/serial-number-list.component';
import { SerialNumberService } from './serial-number.service';

import {NgxPaginationModule} from 'ngx-pagination';

@NgModule({
    imports: [
        SharedModule,
        ModalModule.forRoot(),
        NgxPaginationModule
    ],
    declarations: [
        SerialNumberListComponent,
    ],
    providers: [
        SerialNumberService
    ],
    exports: [
        SerialNumberListComponent,
    ],
    entryComponents: [
        SerialNumberListComponent,
    ]
})
export class SerialNumberModule { }