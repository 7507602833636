import { Component, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { SendEmailBm, } from '../../models';
import { EventBaseComponent } from '../event-base/event-base.component';
import { EventsService } from '../events.service';
import { ResponseException } from "../../models/response-exception";

@Component({
    selector: 'event-send-email',
    templateUrl: './event-send-email.component.html'
})
export class EventSendEmailComponent extends EventBaseComponent {
    @ViewChild('formElement') formElement: FormGroup;
    error: ResponseException;

    bm: SendEmailBm;

    constructor(public eventsService: EventsService) {
        super(eventsService);
    }

    async ngOnInit(): Promise<void> {
        this.bm = new SendEmailBm(this.model);
    }

    async sendEmail(): Promise<void> {
        if (this.formElement.invalid) {
            return;
        }
        this.model.wait = true;
        this.error = await this.eventsService.sendEmail(this.bm).catch(e => e);
        this.model.wait = false;
        if (!(this.error instanceof ResponseException)) {
            this.backToEvents();
        }
    }
}