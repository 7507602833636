﻿import { Directive, Input, ElementRef, Renderer } from '@angular/core';

// import { RoutingService } from '../../routing/routing.service';

@Directive({
    selector: '[appRouterLink]',
})
export class AppRouterLinkDirective {
    @Input('appRouterLink')
    public inputData: any[];

    constructor(
        private renderer: Renderer,
        private elementRef: ElementRef,
        // private routingService: RoutingService,
    ) { }

    ngOnInit() {
        const routeDef = this.inputData[0];
        const routeParams = this.getRouteParameters(this.inputData);
        // const url = this.routingService.getUrlFromRouteDefinition(routeDef, routeParams);
        // this.renderer.setElementAttribute(this.elementRef.nativeElement, 'href', '#' + url);
    }

    private getRouteParameters(parameters: any[]) {
        let result = [];
        if (parameters.length > 1) {
            result = this.inputData.slice(0);
            result.shift();
        }
        return result;
    }
}