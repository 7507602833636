﻿import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Enums, DetailSalesOrderVm } from '../../../models/view-models'
import { ExternalSalesOrderService } from '../service';

@Component({
  selector: 'ext-sales-order-detail',
  templateUrl: './sales-order-detail.component.html',
})
export class ExtSalesOrderDetailComponent implements OnInit {
  model = new DetailSalesOrderVm();
  isBusy: boolean;

  public showOverlay = true;

  constructor(
    private service: ExternalSalesOrderService,
    private route: ActivatedRoute,
  ) { }

  async ngOnInit(): Promise<void> {
    this.model.id = +this.route.snapshot.params['id'];
    if (this.model.id) {
      this.load(this.model.id);
    }
  }

  async load(id: number): Promise<void> {
    this.showOverlay = true;

    this.model = await this.service.getDetailVm(id)
    this.model.sns.sort((a, b) => b.sn > a.sn ? 1 : -1)

    this.showOverlay = false;
  }

  navigateBack(): void {
    window.history.back();
  }

  getStatusName(value: Enums.SalesOrderStatus): string {
      return Enums.SalesOrderStatus[value];
  }
}