﻿import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { AnonymousRegisterSnVm, ContactVm, AddressInfo } from '../../models';

import { AlSnRegistrationService } from './service';

@Component({
    selector: 'al-sn-registration',
    templateUrl: './component.html',
})
export class AlSnRegistrationComponent implements OnInit {
    @ViewChild('formElement') formElement: FormGroup;
    snRegistration: AnonymousRegisterSnVm;

    serverError: string;
    serverValidationError: string;
    isBusy = false;
    isRegistered = false;
    downloadMxdFileLink: string;

    constructor(
        private service: AlSnRegistrationService
    ) { }

    async ngOnInit(): Promise<void> {
        this.snRegistration = new AnonymousRegisterSnVm()
        this.snRegistration.contact = new ContactVm()
        this.snRegistration.address = new AddressInfo()
    }

    async submit(): Promise<void> {
        this.serverValidationError = '';
        if (this.formElement.invalid) {
            this.scrollToBottom();
            return;
        }

        this.isBusy = true;
        let result = await this.service.save(this.snRegistration).catch(this.saveError.bind(this));
        if (result) {
            this.isRegistered = true;
            this.downloadMxdFileLink = result.link
        }

        this.isBusy = false;
    }

    private saveError(e: any): void {
        this.serverValidationError = e;
        this.scrollToBottom();
    }

    clearServerError(): void {
        this.serverValidationError = '';
    }

    navigateBack(): void {
        window.history.back();
    }

    download(): void {
        window.open(this.downloadMxdFileLink, "_blank");
    }

    reload(): void {
        window.location.reload();
    }

    private scrollToBottom(): void {
        setTimeout(() => { document.body.scrollTop = document.body.scrollHeight; });
    }
}