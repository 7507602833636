import { Injectable } from '@angular/core';
import { BaseService } from '../../core/services/base.service';
import { Http } from '@angular/http';
import * as models from '../../models';
import { FindResultVm } from './event-main/find-result.vm';

@Injectable()
export class ExtEventsService extends BaseService {

    constructor(http: Http) { super(http); }

    public findSn(model: models.FindSnArgs): Promise<FindResultVm> {
        return this.get<FindResultVm>(this.baseUri + 'FindSn', model);
    };

    private baseUri = 'ext/';
    
}