import { Component } from '@angular/core';

import * as models from '../../models';
import { EventBaseComponent } from '../event-base/event-base.component';
import { EventsService } from '../events.service';

@Component({
    selector: '<event-add-to-primary>',
    templateUrl: './event-add-to-primary.component.html'
})
export class EventAddToPrimaryComponent extends EventBaseComponent {
     public addToPrimaryList: any[];
     public selectedPrimary: any;

    constructor(public eventsService: EventsService) {
        super(eventsService);
    }

    ngOnInit(): void {
        this.model.wait = true;

        this.eventsService
            .getAddToPrimaryVm(this.model.sn)
            .then(res => {
                
                console.log("Response object:", res);
                
                if (!res.addToPrimaryList === undefined) {
                    if (!res.addToPrimaryList.length) {
                        this.model.error = "There is no available primary for selected Serial Number.";
                    }
                }

                this.addToPrimaryList = res.addToPrimaryList;
                this.model.wait = false;
            })
            .catch(error => {
                this.model.wait = false;
                this.model.error = error;
            });
    }

    public canAddToPrimary(): boolean {
        return !this.model.wait && !this.model.error && !!this.selectedPrimary;
    }

    public selectPrimary(primaryToSelect: any): void {
        this.selectedPrimary = primaryToSelect;
    }

    public addToPrimary(): void {
        const data = new models.AddToPrimaryBm();
        data.comment = this.model.comment;
        data.linkedSnId = this.selectedPrimary.id;
        data.sn = this.model.sn;

        this.model.wait = true;

        this.eventsService
            .addToPrimary(data)
            .then(() => {
                this.backToEvents();
            })
            .catch(error => {
                this.model.wait = false;
                this.model.error = error;
            });
    }
}
