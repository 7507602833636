﻿import { NgModule } from '@angular/core';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown'

import { SharedModule } from '../shared/shared.module';
import { UserPropertyGroupService } from '../models';
import { UserPropertyGroupListComponent } from './user-property-group-list/user-property-group-list.component';
import { UserPropertyGroupEditComponent } from './user-property-group-edit/user-property-group-edit.component';

@NgModule({
    imports: [
        SharedModule,
        
        BsDropdownModule.forRoot(),
    ],
    providers: [
        UserPropertyGroupService,
    ],
    declarations: [
        UserPropertyGroupListComponent,
        UserPropertyGroupEditComponent,
    ],
    exports: [
        UserPropertyGroupListComponent,
    ]
})
export class UserPropertyGroupModule { }