﻿import { DetailProductionRequestVm, } from "../../models/view-models";
import { SnDetailExt } from "./sn-detail-ext";
import { UserPropertyGroupModel } from "../../models/user-property-group.model";

export class DetailExt extends DetailProductionRequestVm {
    sns: SnDetailExt[] | undefined;

    constructor(
        vm: DetailProductionRequestVm = <DetailProductionRequestVm>{},
        propertyGroups: UserPropertyGroupModel[] | undefined = []
    ) {
        super(vm);
        if (this.sns !== undefined && this.sns && typeof(this.sns) !== 'undefined') {
            if (this.sns.length) {
                this.sns = this.sns.map(x => new SnDetailExt(x, propertyGroups));
            }
        }
    }
}