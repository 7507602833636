﻿import { NgModule } from '@angular/core';

import { ModalModule, BsDropdownModule } from 'ngx-bootstrap';

import { SharedModule } from '../shared/shared.module';
import { ShipRequestListComponent } from './ship-request-list/ship-request-list.component';
import { ShipRequestEditComponent } from './ship-request-edit/ship-request-edit.component';
import { ShipRequestDetailComponent } from './ship-request-detail/ship-request-detail.component';
import { ShipRequestShipmentsComponent } from './ship-request-shipments/ship-request-shipments.component';
import { ShipRequestService } from './ship-request.service';

import {NgxPaginationModule} from 'ngx-pagination';

@NgModule({
    imports: [
        SharedModule,
        ModalModule.forRoot(),
        BsDropdownModule.forRoot(),
        NgxPaginationModule
    ],
    declarations: [
        ShipRequestListComponent,
        ShipRequestEditComponent,
        ShipRequestDetailComponent,
        ShipRequestShipmentsComponent
    ],
    providers: [
        ShipRequestService
    ],
    exports: [
        ShipRequestListComponent,
        ShipRequestEditComponent,
        ShipRequestDetailComponent,
        ShipRequestShipmentsComponent
    ],
    entryComponents: [
        ShipRequestListComponent,
        ShipRequestEditComponent,
        ShipRequestDetailComponent,
        ShipRequestShipmentsComponent
    ]
})
export class ShipRequestModule { }