﻿import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import { AuthService } from '../../core/services/auth.service';
import { Util } from '../../tools/util';
import { ShipRequestService } from '../ship-request.service';

import {
    ShipRequestVm,
    Enums,
    KeyValuePair,
    ShipRequestsFilterVm,
    ShipRequestsAccountVm
} from '../../models';
import { Urls } from '../../routing/urls';

@Component({
    selector: 'ship-request-list',
    templateUrl: './ship-request-list.component.html',
})

export class ShipRequestListComponent implements OnInit {
    statuses: KeyValuePair<number, string>[];
    shipRequests: ShipRequestVm[];
    accounts: ShipRequestsAccountVm[];
    filter = new ShipRequestsFilterVm({
        createDateFrom: null,
        createDateTo: null,
        status: null,
        accountId: null,
        serialNumber: ''
    });

    public showOverlay = true;
    
    public pageSize: number = 50;

    p: number = 1;
    
    constructor(
        private authService: AuthService,
        private service: ShipRequestService,
        private router: Router
    ) {
    }

    async ngOnInit(): Promise<void> {
        this.statuses = this.getStatuses();
        this.filter.createDateFrom = this.getDateString(new Date(new Date().getFullYear(), 0, 1));//this.getDateString(new Date(this.filter.createDateFrom));
        this.filter.createDateTo = this.getDateString(new Date());//this.getDateString(new Date(this.filter.createDateTo));
        this.applyFilter();
    }

    async applyFilter(): Promise<void> {
        var msg = "This query might take a long time to process. Do you want to proceed?";
        var answer: any;

        var fdate = new Date(this.filter.createDateFrom);
        fdate = new Date(fdate.toUTCString());
        var tdate = new Date(this.filter.createDateTo);
        tdate = new Date(tdate.toUTCString());
        this.filter.createDateFrom = this.getDateString(fdate);
        this.filter.createDateTo = this.getDateString(tdate);

        // console.log('Status: ' + this.filter.status);
        // console.log('AccountID: ' + this.filter.accountId);
        // console.log('DateFrom: ' + this.filter.createDateFrom + ' | ' + fdate);
        // console.log('DateTo: ' + this.filter.createDateTo + ' | ' + tdate);
        
        // if (this.filter.status == null || this.filter.status.toString() === '3' || this.filter.status.toString().length === 0) {
        //     answer = confirm(msg);
        //     if (answer === false) {
        //         console.log('Cancelled execution');
        //         return;
        //     }
        //     else{
        //         console.log('Proceeded with execution');
        //         this.showOverlay = true;
        //         const vm = await this.service.getListVm(this.filter);
        //         this.shipRequests = vm.shipRequests;
        //         this.accounts = vm.accounts;
        //         this.showOverlay = false;
        //     }
        // }
        // else {
            //console.log('Proceeded without confirmation');
            this.showOverlay = true;
            const vm = await this.service.getListVm(this.filter);
            this.shipRequests = vm.shipRequests;
            this.accounts = vm.accounts;
            this.showOverlay = false;
        // }
    }
    
    navigateToDetails(id: number): void {
        if (this.canUserEdit) {
            this.router.navigate([Urls.GetShipRequestEdit(id)])
        }
    }

    get canUserEdit(): boolean {
        return this.authService.isAdmin || this.authService.isInternal;
    }

    getStatusName(value: Enums.ShipRequestStatus): string {
        return Enums.ShipRequestStatus[value];
    }

    getStatuses(): KeyValuePair<number, string>[] {
        return Util.enumToKeyValuePairs(Enums.ShipRequestStatus);
    }

    private getDateString(date: Date): string {
        var tdate = date == null ? new Date() : date;
        //console.log("tdate: " + date);
        var dd = String(tdate.getUTCDate()).padStart(2, '0');
        //console.log("dd: " + dd);
        var mm = String(tdate.getUTCMonth() + 1).padStart(2, '0'); //January is 0!
        //console.log("mm: " + mm);
        var yyyy = tdate.getUTCFullYear();
        var today = mm + '/' + dd + '/' + yyyy;
        return today;
    }

}