﻿import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { DashboardService } from '../models';
import { DashboardMainComponent } from './dashboard-main/dashboard-main.component';
import { DashboardInternalComponent } from './dashboard-internal/component';
import { DashboardExternalComponent } from './dashboard-external/component';
import { EventHistoryComponent } from '../event-history/event-history.component';

import {NgxPaginationModule} from 'ngx-pagination';

@NgModule({
    imports: [
        SharedModule,
        NgxPaginationModule
    ],
    providers: [
        DashboardService
    ],
    declarations: [
        DashboardMainComponent,
        DashboardInternalComponent,
        DashboardExternalComponent,
        EventHistoryComponent
    ],
    exports: [
        DashboardMainComponent,
    ],
    entryComponents: [
        DashboardMainComponent,
    ]
})
export class DashboardModule { }