import { SnRegistrationFilterVm } from '../../models';

export class SnRegistrationListFilter {
    serialNumber: string;
    filter: string;

    get model(): SnRegistrationFilterVm {
        const model = new SnRegistrationFilterVm();
        model.serialNumber = this.serialNumber;
        model.filter = this.filter;
        return model;
    }
}
