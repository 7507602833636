﻿import { Component, Input, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';

@Component({
    selector: 'modal-message',
    templateUrl: './modal-message.component.html'
})
export class ModalMessageComponent {
    @ViewChild('modal') modal: ModalDirective;
    @Input() modalTitle: string;
    @Input() message: string;
    @Input() classes: string;
    private onHide: () => {};

    show(onHide: () => {} = null): void {
        this.onHide = onHide;
        this.modal.show();
    }
    hide(): void {
        this.modal.hide();
        if (this.onHide) this.onHide();
    }
}