import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";

import { ValueVm } from "../../../models";
import { Urls } from "../../../routing/urls";

@Component({
  selector: "<ext-property-value>",
  templateUrl: "./ext-property-value.component.html",
})
export class ExtPropertyValueComponent {
  @Input() model: ValueVm;

  constructor(private router: Router) {}

  public goto(propertyValue: ValueVm) {
    if (propertyValue.code === "file") {
      const eventFileId = propertyValue.originValue;
      const url = Urls.GetEventFileDownload(eventFileId);
      window.open(url, "_blank");
      return;
    } else if (propertyValue.code === "productionrequest") {
      const url = Urls.GetExtProductionRequestDetails(propertyValue.originValue);
    } else if (propertyValue.code === "salesorder") {
      const url = Urls.GetExtSalesOrderDetails(propertyValue.originValue);
      this.router.navigateByUrl(url);
      this.router.navigateByUrl(url);
    } else if (propertyValue.code === "shipment") {
      const url = Urls.GetExtShipRequestEditByShipment(propertyValue.originValue);
      //console.log(url);
      this.router.navigateByUrl(url);
    }

    // } else if (propertyValue.code === 'product') {
    //     this.routingService.navigate(AppRoutes.products.edit, { id: propertyValue.originValue });
    // }
  }
}
