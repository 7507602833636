﻿import { Component, OnInit, Inject, ViewChild } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'

import * as models from '../../models';
import { SoftwareVersionService } from '../software-version.service'

@Component({
    selector: 'software-version-edit',
    templateUrl: './software-version-edit.component.html',
})
export class SoftwareVersionEditComponent implements OnInit {
    @ViewChild('softwareVersionEditForm') formElement: FormGroup;

    softwareVersion: models.SoftwareVersionVm = <models.SoftwareVersionVm>{ id: 0, software: { name: '' } };
    isSubmitClicked: boolean;
    public isBusy: boolean;
    isNew: boolean;

    constructor(
        private softwareVersionDataService: SoftwareVersionService,
        private route: ActivatedRoute,
        private router: Router
    ) { }

    async ngOnInit(): Promise<void> {
        const id = +this.route.snapshot.params['id'];

        if (id == 0) {
            this.isNew = true;
        } else {
            this.isBusy = true;
            this.softwareVersion = await this.softwareVersionDataService.getById(id);
            this.isBusy = false;
        }
    }

    async onSubmit(): Promise<void> {
        this.isSubmitClicked = true;
        if (this.formElement.invalid)
            return;

        this.isBusy = true;
        await this.softwareVersionDataService.save(this.softwareVersion);
        this.isBusy = false;
        window.history.go(-1);
    }

    async downloadFile(fileId: number): Promise<void> {
        await this.softwareVersionDataService.downloadFile(fileId);
    }

    navigateBack(): void {
        this.router.navigateByUrl('software-versions');
    }

    async delete(): Promise<void> {
        await this.softwareVersionDataService.deleteSoftwareVersion(this.softwareVersion.id);
        this.navigateBack();
    }
}