import { Component } from '@angular/core';

import * as models from '../../models';
import { EventBaseComponent } from '../event-base/event-base.component';
import { EventsService } from '../events.service';

@Component({
    selector: '<event-move-to-production>',
    templateUrl: './event-move-to-production.component.html'
})
export class EventMoveToProductionComponent extends EventBaseComponent {
    public custom = { error: '' };

    constructor(public eventsService: EventsService) {
        super(eventsService);
    }

    ngOnInit(): void {
        this.model.wait = true;

        this.eventsService
            .getMoveToProductionVm(this.model.sn)
            .then(res => {
                if(!res.sn.length)
                {
                    this.custom.error = '';
                }
                this.model.wait = false;
            })
            .catch(error => {
                this.model.wait = false;
                this.model.error = error;
            });
    }

    public moveToProduction(): void {
        this.model.wait = true;

        const data = new models.MoveToProductionBm();
        data.sn = this.model.sn;

        this.eventsService
            .moveToProduction(data)
            .then(() => {
                this.backToEvents();
            })
            .catch(error => {
                this.model.wait = false;
                this.model.error = error;
            });
    }
}