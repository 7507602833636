import { Injectable } from "@angular/core"
import { BaseService } from "../../core/services/base.service";
import { ProductionRequestVm, DetailProductionRequestVm } from "../../models";
import { Http } from "@angular/http";

@Injectable()
export class ExternalProductionRequestService extends BaseService
{
	constructor (http: Http)
	{
		super(http);
	}
	public getListVm() : Promise<ProductionRequestVm[]>
	{
		return this.get<ProductionRequestVm[]>(this.baseUri + `GetListVm`);
	}
	public getDetailVm(id: number) : Promise<DetailProductionRequestVm>
	{
		return this.get<DetailProductionRequestVm>(this.baseUri + `GetDetailVm/${id}`);
	}
	private baseUri: string = 'ext/production-request/';
}