import { Component } from '@angular/core';

import * as models from '../../models';
import { EventBaseComponent } from '../event-base/event-base.component';
import { EventsService } from '../events.service';

@Component({
    selector: '<event-assembly-started>',
    templateUrl: './event-assembly-started.component.html'
})
export class EventAssemblyStartedComponent extends EventBaseComponent {
    products: models.KeyValuePair< number, string> [];
    product: models.KeyValuePair<number, string>;
    comment: string;

    constructor(public eventsService: EventsService) {
        super(eventsService);
    }

    ngOnInit(): void {
        this.model.wait = true;

        this.eventsService
            .getAssemblyStartedVm()
            .then(res => {
                this.products = res;
                this.model.wait = false;
            })
            .catch(error => {
                this.model.wait = false;
                this.model.error = error;
            });
    }

    public get canStartAssembly(): boolean {
        return !this.model.wait && !!this.product;
    }

    public startAssembly(): void {
        this.model.wait = true;

        const data = new models.StartAssemblyBm();
        data.comment = this.comment;
        data.productId = this.product.key;
        data.sn = this.model.sn;

        this.eventsService
            .startAssembly(data)
            .then(() => {
                this.backToEvents();
            })
            .catch(error => {
                this.model.wait = false;
                this.model.error = error;
            });
    }
}