import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router'

import { DashboardService, DashboardRecentEventVm  } from '../models';

@Component({
    selector: 'event-history',
    templateUrl: './event-history.component.html',
})
export class EventHistoryComponent implements OnInit {
  events: DashboardRecentEventVm[];
  
  public showOverlay = true;

  public pageSize: number = 20;

  p: number = 1;

  constructor(
    private dataService: DashboardService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  async ngOnInit(): Promise<void> {
    this.showOverlay = true;
    const listVm = await this.dataService.getHistoryListVm();
    this.showOverlay = false;
    this.events = listVm.events;
  }

  navigateToSerialNumberDetails(id: number): void {
    this.router.navigateByUrl('sn/detail/' + id)
  }

  navigateToRegistrationDetails(id: number): void {
    this.router.navigateByUrl('sn-registration/edit/' + id)
  }
  
}