﻿import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { PropertyService } from '../models';
import { PropertyListComponent } from './property-list/property-list.component';

@NgModule({
    imports: [
        SharedModule
    ],
    providers: [
        PropertyService
    ],
    declarations: [
        PropertyListComponent,
    ],
    exports: [
        PropertyListComponent,
    ],
    entryComponents: [
        PropertyListComponent,
    ]
})
export class PropertyModule { }