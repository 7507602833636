﻿import { EventFlowVm, SnDetailsVm } from '../../models/';
import { FindResultVm } from './find-result.vm';

export class EventMainModel extends FindResultVm {
    public sn: string = '';
    public wait: boolean = false;
    public customViewCode: string = '';
    public customFlow: EventFlowVm;
    public error: string = '';
    public comment: string = '';
    public commentHint: string = '';

    public constructor() {
        super();
        this.initObjectProperties();
    }

    public update(init?: Partial<EventMainModel>): void {
        Object.assign(this, init);
        this.initObjectProperties();
    }

    private initObjectProperties() {
        this.flows = this.flows || [];
        this.auxiliaryFlows = this.auxiliaryFlows || [];
        this.snForSelect = this.snForSelect || [];
        this.details = new SnDetailsVm(this.details);
    }
}