import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms'
import { HttpClientModule } from '@angular/common/http'

import { BsDropdownModule } from 'ngx-bootstrap/dropdown'
import { CollapseModule } from 'ngx-bootstrap/collapse'

import { AccountModule } from './account/account.module'
import { AppComponent } from './app.component';
import { AppRoutingModule } from './routing/app-routing.module';
import { CoreModule } from './core/core.module'
import { DashboardModule } from './dashboard/dashboard.module';
import { EventsModule } from './events/events.module'
import { FlowDebugModule } from './flow-debug/flow-debug.module'
import { PermissionModule } from './permission/permission.module'
import { ProductModule } from './product/product.module'
import { ProductionRequestModule } from './production-request/production-request.module'
import { SalesOrderModule } from './sales-order/sales-order.module'
import { ProfileModule } from './profile/profile.module'
import { PropertyModule } from './property/property.module'
import { RmaOrderModule } from './rma-order/rma-order.module'
import { SerialNumberModule } from './serial-number/serial-number.module'
import { SettingsModule } from './settings/settings.module'
import { SignInModule } from './sign-in/sign-in.module';
import { ShipRequestModule } from './ship-request/ship-request.module'
import { SnRegistrationModule } from './sn-registration/sn-registration.module'
import { SoftwareModule } from './software/software.module'
import { SoftwareVersionModule } from './software-version/software-version.module'
import { UserModule } from './user/user.module'
import { UserGroupModule } from './user-group/user-group.module'
import { UserPropertyGroupModule } from './user-property-group/user-property-group.module';

import { AlienModule } from './alien/module'
import { ExtModule } from './ext/module'
import { ExtProductionRequestModule } from './ext/production-request/module'
import { ExtSalesOrderModule } from './ext/sales-order/module'
import { ExtShipRequestModule } from './ext/ship-request/ext-ship-request.module'

import { ErrorModule } from './error/module'

import { PaginationModule, PaginationConfig } from 'ngx-bootstrap/pagination';

//import { KeysPipe } from './events/event-link-to-pcb/event-link-to-pcb.component';
//import { Pipe, PipeTransform } from '@angular/core';

@NgModule({
  declarations: [
    AppComponent/*,
    Pipe,*/
    /*KeysPipe,*/
    /*NgbPagination*/
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BsDropdownModule.forRoot(),
    CollapseModule.forRoot(),

    AccountModule,
    CoreModule,
    DashboardModule,    
    EventsModule,
    FlowDebugModule,
    PermissionModule,
    ProductModule,
    ProductionRequestModule,
    SalesOrderModule,
    ProfileModule,
    PropertyModule,
    RmaOrderModule,
    SerialNumberModule,
    SettingsModule,
    ShipRequestModule,
    SignInModule,
    SnRegistrationModule,
    SoftwareModule,
    SoftwareVersionModule,
    UserModule,
    UserGroupModule,
    UserPropertyGroupModule,

    ExtModule,
    ExtProductionRequestModule,
    ExtSalesOrderModule,
    ExtShipRequestModule,    
    AlienModule,

    ErrorModule,

    PaginationModule.forRoot(),
    FormsModule/*,
    Pipe*/

  ],
  providers: [PaginationConfig],
  bootstrap: [AppComponent]
})
export class AppModule { }
