import { Component } from '@angular/core';

import * as models from '../../models';
import { EventBaseComponent } from '../event-base/event-base.component';
import { EventsService } from '../events.service';


@Component({
    selector: '<event-link-to-pcb>',
    styles: [`
    .even {color: #ccc;}
    .odd {color: #c0c0;}`],
    templateUrl: './event-link-to-pcb.component.html'
})

export class EventLinkToPcbComponent extends EventBaseComponent {
    public custom = { error: '', comment: '', linkedPcbId: '', linkedSn: '' };
    public linkToPcbList: any[];
    public sns: any[];
    public selectedPcb: any;
    public selectedPcbId: any;

    constructor(public eventsService: EventsService) {
        super(eventsService);
    }

    ngOnInit(): void {
        this.model.wait = true;

        this.eventsService
            .getLinkToPcbVm(this.model.sn)
            .then(res => {
                
                console.log("Response object:", res);
                
                if (!res.linkToPcbList === undefined) {
                    if (!res.linkToPcbList.length) {
                        this.custom.error = "There is no available PCB to link with selected Serial Number.";
                    }
                }

                this.linkToPcbList = res.linkToPcbList;
               
                this.model.wait = false;
            })
            .catch(error => {
                console.log(error);
                this.model.wait = false;
                this.model.error = error;
            });
    }

    public canLinkToPcb(): boolean {
        return !this.model.wait && !this.model.error && !!this.selectedPcb;
    }

    public selectPcb(pcbToSelect: any): void {
        this.selectedPcb = pcbToSelect;
    }

        public linkToPcb(): void {
        const data = new models.LinkToPcbBm();
        data.comment = this.model.comment;
        data.linkedPcbSnId = this.custom.linkedPcbId.toString();
        data.linkedSn = this.custom.linkedSn;
        data.sn = this.model.sn;

        console.log("PCB Id to link to: ", data.linkedPcbSnId);
        console.log("PCB Sn to link to: ", data.linkedSn);

        this.model.wait = true;

        this.eventsService
            .linkToPcb(data)
            .then(() => {
                this.backToEvents();
            })
            .catch(error => {
                this.model.wait = false;
                this.model.error = error;
            });
    }
}
