﻿import { FilterVm } from '../../models';

export class ProductionRequestListFilter {
    createDateFrom: Date;
    createDateTo: Date;
    isVisibleToClientOnly: boolean;
    productType: number;
    status: string;
    user: number;

    get model(): FilterVm {
        const model = new FilterVm();
        if (this.createDateFrom) {
            model.createDateFrom = this.getBeginingOfTheDay(this.createDateFrom);
        }
        if (this.createDateTo) {
            model.createDateTo = this.getBeginingOfTheDay(this.createDateTo);
        }
        model.isVisibleToClientOnly = this.isVisibleToClientOnly;
        model.productType = this.productType;
        model.status = this.status;
        model.user = this.user;
        return model;
    }

    private getBeginingOfTheDay(date: Date): Date {
        return new Date(date.setHours(0, 0, 0, 0));
    }

}