import { Enums } from "../../models";

export class RmaOrderVm
{
	constructor (init?: Partial<RmaOrderVm>)
	{
		Object.assign(this, init);
		
	}
	public no: string;
	public userName: string;
	public createDate: Date;
	public dueDate: Date;
	public note: string;
	public reason: string;
	public status: Enums.RmaOrderStatus;
	public snNo: string;
	public snId: number;
	public id: number;
}