﻿import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';

import { ProductionRequestVm, Enums } from '../../../models';
import { ExternalProductionRequestService } from '../service';

@Component({
    selector: 'production-request-list',
    templateUrl: './component.html',
})
export class ExtProductionRequestListComponent implements OnInit {
  productionRequests: ProductionRequestVm[];
  
  public showOverlay = true;

  constructor(
      private service: ExternalProductionRequestService,
      private router: Router
  ) { }

  async ngOnInit(): Promise<void> {
      this.applyFilter();
  }

  navigateToDetails(id: number): void {
    this.router.navigateByUrl('ext/production-request/detail/' + id)
  }

  async applyFilter(): Promise<void> {
     this.showOverlay = true;

      this.productionRequests = await this.service.getListVm();
      this.productionRequests.forEach(x => {
          (<any>x).isHighlighted = (x.status == Enums.ProductionRequestStatus.Created);
      })

      this.showOverlay = false;
  }

  getStatusName(value: Enums.ProductionRequestStatus): string {
      return Enums.ProductionRequestStatus[value];
  }
}