import { Component } from '@angular/core'
import { Router,
  Event as RouterEvent,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError } from '@angular/router'

import { getUser, remove } from './tools/auth-storage'
import { Urls } from './routing/urls';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  isAuth = false
  isExternal = false
  isInternal = false
  isAdmin = false
  userName = ''
  title = 'app';
  isCollapsed = true
  links = Urls
  
  public showOverlay = true;

  constructor(private router: Router) {
    router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event)
    })
  }

  // Shows and hides the loading spinner during RouterEvent changes
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.showOverlay = true;
    }
    if (event instanceof NavigationEnd) {
      this.showOverlay = false;
    }

  // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.showOverlay = false;
    }
    if (event instanceof NavigationError) {
      this.showOverlay = false;
    }
  }

  updateUser() {
    const user = getUser()
    const isAuth = user != null && user.token != null
    if(this.isAuth === isAuth) {
      return
    }

    this.isAuth = isAuth
    if(user) {
      this.isExternal = user.role === 'external'
      this.isAdmin = user.role === 'admin'
      this.isInternal = this.isAdmin || user.role === 'internal'
      this.userName = user.userName
    }
    
    if(!isAuth && this.router.url !== '/sign-in') {
      this.router.navigateByUrl('/sign-in')
    } else if(isAuth && this.router.url === '/sign-in') {
      this.router.navigateByUrl('/dashboard')
    }
  }

  startLoop() {
    const that = this

    setTimeout(() => {
      that.updateUser()
      that.startLoop();
    }, 750);
  }

  ngOnInit() { 
    this.startLoop()
  }

  signOff() {
    remove()
  }

 
}
