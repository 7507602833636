﻿import { Component, OnInit, Inject } from '@angular/core'

import { ConfigItemService, ConfigItemVm } from '../../models'
import { Router } from '@angular/router'

@Component({
    selector: 'config-items',
    templateUrl: './config-items.component.html',
})
export class ConfigItemsComponent implements OnInit {
  isBusy: boolean
  configItmes: ConfigItemVm[];

  constructor(
      private dataService: ConfigItemService,
      private router: Router
  ) {
  }

  async ngOnInit(): Promise<void> {
      this.configItmes = await this.dataService.getListVm();
  }

  async save(): Promise<void> {
      this.isBusy = true;
      await this.dataService.save(this.configItmes);
      this.isBusy = false;
      this.navigateToSettings();
  }

  navigateToSettings(): void {
    this.router.navigateByUrl('settings')
  }
}