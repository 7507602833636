import { Injectable } from "@angular/core"
import { BaseService } from "../core/services/base.service";
import { ListItemVm, SnRegistrationsAccountVm, SaveVm, SaveResult } from "../models";
import { Http } from "@angular/http"
import { SnRegistrationsViewVm } from "./sn-registration-view.vm";

@Injectable()
export class SnRegistrationService extends BaseService
{
	constructor (http: Http)
	{
		super(http);
	}
	public getListVm(sn: string) : Promise<ListItemVm[]>
	{
		return this.get<ListItemVm[]>(this.baseUri + `GetListVm/${sn}`);
	}
	public getListFilteredVm(filter: string) : Promise<ListItemVm[]>
	{
		return this.get<ListItemVm[]>(this.baseUri + `GetListFilteredVm/${filter}`);
	}
	public getCreateVm() : Promise<SnRegistrationsAccountVm[]>
	{
		return this.get<SnRegistrationsAccountVm[]>(this.baseUri + `GetCreateVm`);
	}
	public getVm(id: number) : Promise<SnRegistrationsViewVm>
	{
		return this.get<SnRegistrationsViewVm>(this.baseUri + `${id}`);
	}
	public save(vm: SaveVm) : Promise<SaveResult>
	{
		return this.post<SaveResult>(this.baseUri + `Save`, vm);
	}
	private baseUri: string = 'SnRegistration/';
}