﻿import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'

// import { Cookie } from 'ng2-cookies/ng2-cookies';
import { ModalDirective } from 'ngx-bootstrap';

import * as models from '../../models';
import { SerialNumberService } from '../serial-number.service';
import { SerialNumberListModel } from './serial-number-list.model';
import { SnPropertyVm, ShipmentRequestsProductVm } from '../../models/view-models'
import { UserPropertyGroupModel } from "../../models/user-property-group.model"
import { UserPropertyGroupType } from '../../models/user-property-group-type'

const _searchModel = new SerialNumberListModel();

@Component({
    selector: 'serial-number-list',
    templateUrl: './serial-number-list.component.html',
    styleUrls: ['./serial-number-list.component.scss']
})
export class SerialNumberListComponent implements OnInit {
    @ViewChild('modalColumns') public childModal: ModalDirective;

    searchModel: SerialNumberListModel;
    //propertyGroups: UserPropertyGroupModel[];
    //snItems = new Array<models.SnItemVm>();
    snItems = new Array<models.SnItemNewVm>();
    totalSnItemsCount = 0;
    //properties: SnPropertyVm[];
    //propertiesMap: { [key: number]: string } = {};
    //public visiblePropertyIds = new Array<number>();
    products: ShipmentRequestsProductVm[];

    //public isBusy: boolean;   
    public showOverlay = true;
    private SnRx = new Subject<string>();
    private StRx = new Subject<string>();

    public pageSize: number = 20;

    p: number = 1;
   
    public constructor(
        private userPropertyGroupService: models.UserPropertyGroupService,
        private serialNumberService: SerialNumberService,
        private router: Router) {
            this.searchModel = _searchModel
    }

    askToFindSn() {
        const sn = this.searchModel.serialNumber
        this.SnRx.next(sn);
    }

    askToSearchContext() {
        const st = this.searchModel.searchText
        this.StRx.next(st);
      }

    resetProduct() {
        this.searchModel.productCategory = 0;
      }


    public async ngOnInit(): Promise<void> {
        // const propertyGroups = await this.userPropertyGroupService.getList(UserPropertyGroupType.List);
        //this.products = [{id: 0, modelNo: ''}];
        // this.propertyGroups = UserPropertyGroupModel.createVisibleGroups(propertyGroups);

         const that = this

         this.SnRx.pipe(
             debounceTime(600),
             distinctUntilChanged()
           ).subscribe((sn) => {
             //this.resetProduct()
             that.reloadSns()
           })

           this.StRx.pipe(
             debounceTime(600),
             distinctUntilChanged()
           ).subscribe((st) => {
            //this.resetProduct()
             that.reloadSns()
           })
        
        this.reloadSns();
    }

    public async reloadSns(): Promise<void> {
        this.showOverlay = true;

        _searchModel.skipCount = 0;
        const snListVm = await this.loadSns();
        //console.log("Response #2: ", snListVm)
        // this.propertiesMap = snListVm.properties.reduce((acc, prop) => {
        //     acc[prop.id] = prop.name;
        //     return acc;
        // }, this.propertiesMap);
        // this.properties = snListVm.properties;

        //this.products = this.products.concat(snListVm.products);
        //this.products = [{id: 0, modelNo: ''}];
        this.products = this.products.concat(snListVm.productList);
        this.snItems = snListVm.snItems;
        this.totalSnItemsCount = this.snItems.length;
        //this.visiblePropertyIds = this.getListOfVisibleColumns();
        //console.log("Response #3: ", this.snItems);
        //console.log("Response #4: ", this.products);
        //console.log("Count: " , this.totalSnItemsCount, this.snItems.length);
        this.p = 1;
        this.showOverlay = false;
    }

    public isLoadMorePossible(): boolean {
        return false;//this.totalSnItemsCount > this.snItems.length;
    }

    // public async loadMoreSns(): Promise<void> {
    //     _searchModel.skipCount = this.snItems.length;
    //     const snListVm = await this.loadSns();
    //     this.snItems = this.snItems.concat(snListVm.snItems);
    // }

    public isSortedByColumn(propertyId: number): boolean {
        return _searchModel.orderByPropertyId === propertyId;
    }

    // public isVisibleColumn(propertyId: number): boolean {
    //     return this.visiblePropertyIds.indexOf(propertyId) >= 0;
    // }

    public setOrderAndLoadSns(propertyId: number): void {
        if (propertyId === _searchModel.orderByPropertyId) {
            _searchModel.isAscendingSort = !_searchModel.isAscendingSort;
        } else {
            _searchModel.isAscendingSort = true;
            _searchModel.orderByPropertyId = propertyId;
        }
        this.reloadSns();
    }

    // public toggleColumnVisibility(propertyId: number): void {
    //     const index = this.visiblePropertyIds.indexOf(propertyId);
    //     if (index >= 0) {
    //         this.visiblePropertyIds.splice(index, 1);
    //     } else {
    //         this.visiblePropertyIds.push(propertyId);
    //     }
    //     this.setColumnsToCookies(this.visiblePropertyIds);
    // }

    public showSnDetail(snItem: models.SnItemVm): void {
        this.router.navigate(['/sn/detail/' + snItem.serialNumber])
    }

    // private async loadSns(): Promise<models.SnListVm> {
    //     //this.showOverlay = true;
    //     //this.isBusy = true;
    //     const snListVm = await this.serialNumberService.load(_searchModel);
    //     //console.log("Response #2: ", snListVm);
    //     this.totalSnItemsCount = snListVm.totalSnItemsCount;
    //     this.products = snListVm.products;
    //     //this.isBusy = false;
    //     //this.showOverlay = false;
    //     return snListVm;
    // }

    private async loadSns(): Promise<models.SnListNewVm> {
        this.showOverlay = true;
        let snListVm = new models.SnListNewVm;
        if (_searchModel.productCategory === 0) {
            snListVm = await this.serialNumberService.getall(_searchModel);
        }
        else {
            //snListVm = await this.serialNumberService.getByProduct(_searchModel.productCategory);
            //console.log(_searchModel);
            snListVm = await this.serialNumberService.getByProduct(_searchModel);
        }
        //console.log("Response #1: ",  snListVm);
        this.products = [{id: 0, modelNo: 'All'}];
        this.products = this.products.concat([{id: null, modelNo: 'Uknown'}])
        this.totalSnItemsCount = snListVm.totalSnItemsCount;
        this.showOverlay = false;
        return snListVm;
    }

    async export(): Promise<void> {
        await this.serialNumberService.export(_searchModel);
    }

    // private getListOfVisibleColumns(): number[] {
    //     // const ids = JSON.parse(Cookie.get(this.columnsCookieKey));
    //     // if (ids) {
    //     //     return ids;
    //     // }
    //     return Object.keys(this.propertiesMap).map(x => parseInt(x));
    // }

    private columnsCookieKey = 'sns.visibleProperties';

    private setColumnsToCookies(obj: any): void {
        // Cookie.set(this.columnsCookieKey, JSON.stringify(obj));
    }

    // getPropertyIdsByGroup(group: UserPropertyGroupModel): number[] {
    //     return group.properties
    //         .map(x => x.id)
    //         .filter(x => this.visiblePropertyIds.find(y => y == x));
    // }

    getFirstWord(str: string): string {
        return str.split(' ', 1)[0];
    }

    getOhterWords(str: string): string {
        var result = str.split(' ', );
        result.shift();
        return result.join(' ');
    }
}