﻿import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { SoftwareService, EditSoftwareVm } from '../../models';

@Component({
    selector: 'software-edit',
    templateUrl: './software-edit.component.html',
})
export class SoftwareEditComponent implements OnInit {
    model: EditSoftwareVm;
    isNew = true;
    public isBusy = false;

    constructor(
        private dataService: SoftwareService,
        private route: ActivatedRoute,
        private router: Router
    ) { }

    async ngOnInit(): Promise<void> {
        this.model = new EditSoftwareVm();
        this.model.id = +this.route.snapshot.params['id'];
        if (this.model.id) {
            this.isNew = false;
            this.model = await this.dataService.getEditVm(this.model.id);

            // console.log("MODEL: " + this.model.code);
            // console.log("URL: " + this.model.url);
            
            // if (this.model.code == "SeUtility") { this.model.url = "SensorEdgeUtilitySetup.exe"; }
            // if (this.model.code == "golf") { this.model.url = "GolfSetup.exe"; }
            // if (this.model.code == "gripgolf") { this.model.url = "SensorGripGolfSetup.exe"; }
            // if (this.model.code == "gripbaseball") { this.model.url = "SensorGripBaseballSetup.exe"; }
            // if (this.model.code == "batter") { this.model.url = "BattersPlateProSetup.exe"; }
            // if (this.model.code == "pitcher") { this.model.url = "SensorEdgePitchingAppSetup.exe"; }

            // console.log("MODEL: " + this.model.code);
            // console.log("URL: " + this.model.url);
        }
    }

    async save(): Promise<void> {
        await this.dataService.save(this.model);
        this.navigateBack();
    }

    navigateBack(): void {
        this.router.navigate(['/softwares'])
    }
}