import { NgModule } from '@angular/core';
import { SignInService } from './sign-in.service';
import { SignInComponent } from './sign-in.component';
import { FormsModule } from '@angular/forms';

@NgModule({
    providers: [
      SignInService
    ],
    declarations: [
      SignInComponent
    ],
    exports: [
      SignInComponent
    ],
    entryComponents: [
      SignInComponent
    ],
    imports: [
        FormsModule
    ]
})
export class SignInModule { }