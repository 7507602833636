import { Injectable } from '@angular/core';
import { Http } from '@angular/http';

import { BaseService } from '../core/services/base.service'
import * as models from '../models';
import { FindResultVm } from './event-main/find-result.vm';

@Injectable()
export class EventsService extends BaseService {
    
    private baseUri = 'event/';

    constructor(http: Http) { super(http); }

    findSn(model: models.FindSnArgs): Promise<FindResultVm> {
        return this.get<FindResultVm>(this.baseUri + 'FindSn', model);
    };

    create(model: models.CreateBm): Promise<any> {
        return this.post(this.baseUri + 'Create', model);
    };

    undo(sn: string): Promise<any> {
        return this.post(this.baseUri + 'Undo', null, { sn: sn });
    };

    getAddToProductionRequestVm(sn: string): Promise<models.AddToProductionsOrderVm> {
        return this.get<models.AddToProductionsOrderVm>(this.baseUri + 'GetAddToProductionRequestVm', { sn: sn });
    };
    addToProductionRequest(model: models.AddToProductionsOrderBm): Promise<any> {
        return this.post(this.baseUri + 'addToProductionRequest', model);
    };

    getAddToSalesOrderVm(sn: string): Promise<models.AddToSalesOrderVm> {
        return this.get<models.AddToSalesOrderVm>(this.baseUri + 'GetAddToSalesOrderVm', { sn: sn });
    };
    addToSalesOrder(model: models.AddToSalesOrderBm): Promise<any> {
        return this.post(this.baseUri + 'addToSalesOrder', model);
    };

    getMoveToProductionVm(sn: string): Promise<models.MoveToProductionVm> {
        return this.get<models.MoveToProductionVm>(this.baseUri + 'GetMoveToProductionVm', { sn: sn });
    };
    moveToProduction(model: models.MoveToProductionBm): Promise<any> {
        return this.post(this.baseUri + 'moveToProduction', model);
    };

    getLinkToPcbVm(sn:string): Promise<models.LinkToPcbVm> {
        return this.get<models.LinkToPcbVm>(this.baseUri + 'GetLinkToPcbVm', {sn: sn});
    }
    linkToPcb(model: models.LinkToPcbBm): Promise<any> {
        return this.post(this.baseUri + 'linkToPcb', model);
    }

    getAddToShipRequestVm(sn: string): Promise<models.AddToShipRequestVm> {
        return this.get<models.AddToShipRequestVm>(this.baseUri + 'GetAddToShipRequestVm', { sn: sn });
    };
    addToShipRequest(model: models.AddToShipRequestBm): Promise<any> {
        return this.post(this.baseUri + 'AddToShipRequest', model);
    };

    getAddToPrimaryVm(sn: string): Promise<models.AddToPrimaryVm> {
        return this.get<models.AddToPrimaryVm>(this.baseUri + 'GetAddToPrimaryVm', { sn: sn });
    };

    addToPrimary(model: models.AddToPrimaryBm): Promise<any> {
        return this.post(this.baseUri + 'AddToPrimary', model);
    }

    getAddToSecondaryVm(sn: string): Promise<models.AddToSecondaryVm> {
        return this.get<models.AddToSecondaryVm>(this.baseUri + 'GetAddToSecondaryVm', { sn: sn });
    };

    addToSecondary(model: models.AddToSecondaryBm): Promise<any> {
        return this.post(this.baseUri + 'AddToSecondary', model);
    }

    getAssemblyStartedVm(): Promise<models.KeyValuePair<number, string>[]> {
        return this.get<models.KeyValuePair<number, string>[]>(this.baseUri + 'GetAssemblyStartedVm');
    };
    startAssembly(model: models.StartAssemblyBm): Promise<any> {
        return this.post(this.baseUri + 'StartAssembly', model);
    };

    getChangeAccountVm(): Promise<models.KeyValuePair<number, string>[]> {
        return this.get<models.KeyValuePair<number, string>[]>(this.baseUri + 'getChangeAccountVm');
    };
    changeAccount(model: models.ChangeAccountVm): Promise<any> {
        return this.post(this.baseUri + 'changeAccount', model);
    };

    getChangeProductVm(): Promise<models.KeyValuePair<number, string>[]> {
        return this.get<models.KeyValuePair<number, string>[]>(this.baseUri + 'getChangeProductVm');
    };
    changeProduct(model: models.ChangeProductBm): Promise<any> {
        return this.post(this.baseUri + 'changeProduct', model);
    };

    getShippedVm(sn: string): Promise<models.ShippedVm> {
        return this.get<models.ShippedVm>(this.baseUri + 'GetShippedVm', { sn: sn });
    };
    doShipped(model: models.DoShippedBm): Promise<any> {
        return this.post(this.baseUri + 'DoShipped', model);
    };

    issueRma(model: models.IssueRmaBm): Promise<any> {
        return this.post(this.baseUri + 'IssueRma', model);
    };

    uploadFile(model: models.UploadFileVm): Promise<any> {
        return this.post(this.baseUri + 'uploadFile', model);
    };

    getAdminUpdateVm(): Promise<models.AdminUpdateVm> {
        return this.get<models.AdminUpdateVm>(this.baseUri + 'GetAdminUpdateVm');
    };

    adminUpdate(model: models.AdminUpdateBm): Promise<any> {
        return this.post(this.baseUri + 'AdminUpdate', model);
    };

    sendEmail(model: models.SendEmailBm): Promise<any> {
        return this.post(this.baseUri + 'SendEmail', model);
    };

}