﻿import { NgModule } from '@angular/core';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown'

import { SharedModule } from '../shared/shared.module';
import { ProductionRequestListComponent } from './production-request-list/production-request-list.component';
import { ProductionRequestDetailComponent } from './production-request-detail/production-request-detail.component';
import { ProductionRequestCreateComponent } from './production-request-create/production-request-create.component';
import { ProductionRequestService } from '../models';

import {NgxPaginationModule} from 'ngx-pagination';

@NgModule({
    imports: [
        SharedModule,
        BsDropdownModule.forRoot(),
        NgxPaginationModule
    ],
    declarations: [
        ProductionRequestListComponent,
        ProductionRequestDetailComponent,
        ProductionRequestCreateComponent,
    ],
    providers: [
        ProductionRequestService,
    ],
    exports: [
        ProductionRequestListComponent,
        ProductionRequestDetailComponent,
        ProductionRequestCreateComponent,
    ],
    entryComponents: [
        ProductionRequestListComponent,
        ProductionRequestDetailComponent,
        ProductionRequestCreateComponent,
    ]
})
export class ProductionRequestModule { }