﻿import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { FlowDebugService } from '../models';
import { FlowDebugListComponent } from './flow-debug-list/flow-debug-list.component';

@NgModule({
    imports: [
        SharedModule
    ],
    providers: [
        FlowDebugService
    ],
    declarations: [
        FlowDebugListComponent,
    ],
    exports: [
        FlowDebugListComponent,
    ],
    entryComponents: [
        FlowDebugListComponent,
    ]
})
export class FlowDebugModule { }