import { BaseService } from "../core/services/base.service";
import { UserVm, EditUserVm, SaveResult, GenerateSetPasswordKeyVm } from "../models";
import { Injectable } from "@angular/core";
import { Http } from "@angular/http";

@Injectable()
export
class UserService extends BaseService
{
	constructor (http: Http)
	{
		super(http);
	}
	public getListVm() : Promise<UserVm[]>
	{
		const uriParams = {  };
		return this.get<UserVm[]>(this.baseUri + `GetListVm`, uriParams);
	}
	public getEditVm(id: number) : Promise<EditUserVm>
	{
		const uriParams = {  };
		return this.get<EditUserVm>(this.baseUri + `GetEditVm/${id}`, uriParams);
	}
	public save(model: EditUserVm) : Promise<SaveResult>
	{
		return this.post<SaveResult>(this.baseUri + `Save`, model);
	}
	public generateSetPasswordKey(model: GenerateSetPasswordKeyVm) : Promise<string>
	{
		return this.post<string>(this.baseUri + `GenerateSetPasswordKey`, model);
	}
	private baseUri: string = 'user/';
}