﻿import { UserPropertyGroupVm } from './view-models';

export class UserPropertyGroupModel extends UserPropertyGroupVm {
    arePropertiesVisible: boolean;
    constructor(init?: Partial<UserPropertyGroupModel>) {
        super(init);
    }

    static createGroups(groups: UserPropertyGroupVm[]): UserPropertyGroupModel[] {
        console.log(groups);
        if (groups !== undefined)
        {
            return groups
                .sort((a, b) => !b.id || a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1)
                .map(x => {
                    x.properties = x.properties.sort((a, b) => a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1);
                    return new UserPropertyGroupModel(x);
                });
        }
    }

    static createVisibleGroups(groups: UserPropertyGroupVm[]): UserPropertyGroupModel[] {
        if (groups !== undefined)
        {
            return UserPropertyGroupModel
                .createGroups(groups)
                .filter(x => x.isVisible && x.properties.length > 0);
        }
    }
}