﻿import { Component, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap'

import { UserPropertyGroupService, UserPropertyGroupVm, } from '../../models';
import { Util } from '../../tools/util';
import { ModalMessageComponent } from '../../shared/components/modal-message/modal-message.component';

@Component({
    selector: 'user-property-group-edit',
    templateUrl: './user-property-group-edit.component.html',
})
export class UserPropertyGroupEditComponent {
    @ViewChild('serverErrorModal') private serverErrorModal: ModalMessageComponent;
    @ViewChild('modal') private modal: ModalDirective;
    @Output() private save = new EventEmitter();

    public group: UserPropertyGroupVm;
    private originalGroup: UserPropertyGroupVm;
    private groupNames: string[];
    private isSaveClicked: boolean;
    private isGroupNameValid: boolean;
    private isBusy: boolean;
    public serverError: string;

    constructor(
        private dataService: UserPropertyGroupService,
    ) {
    }

    show(group: UserPropertyGroupVm, groupNames: string[]): void {
        this.isGroupNameValid = true;
        this.originalGroup = group;
        this.group = new UserPropertyGroupVm(group);
        this.groupNames = groupNames;
        this.modal.show();
    }

    private close(): void {
        if (this.isBusy) return;
        this.modal.hide();
    }

    private async saveGroup(): Promise<void> {
        this.isSaveClicked = true;
        if (!this.isGroupNameValid) return;

        this.isBusy = true;
        await this.dataService.saveGroup(this.group).catch(e => this.serverError = e);
        this.isBusy = false;

        if (this.serverError) {
            this.serverErrorModal.show();
            return;
        }

        this.close();
        this.save.emit();
    }

    private validateGroupName(name: string): void {
        this.isGroupNameValid = name.length > 0 && this.groupNames.every(x => x != name);
    }
}