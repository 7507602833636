import { Component } from '@angular/core';

import * as models from '../../models';
import { EventBaseComponent } from '../event-base/event-base.component';
import { EventsService } from '../events.service';

@Component({
    selector: '<event-add-to-secondary>',
    templateUrl: './event-add-to-secondary.component.html'
})
export class EventAddToSecondaryComponent extends EventBaseComponent {
     public addToSecondaryList: any[];
     public selectedSecondary: any;

    constructor(public eventsService: EventsService) {
        super(eventsService);
    }

    ngOnInit(): void {
        this.model.wait = true;

        this.eventsService
            .getAddToSecondaryVm(this.model.sn)
            .then(res => {
                
                console.log("Response object:", res);
                
                if (!res.addToSecondaryList === undefined) {
                    if (!res.addToSecondaryList.length) {
                        this.model.error = "There is no available secondary for selected Serial Number.";
                    }
                }

                this.addToSecondaryList = res.addToSecondaryList;
                this.model.wait = false;
            })
            .catch(error => {
                this.model.wait = false;
                this.model.error = error;
            });
    }

    public canAddToSecondary(): boolean {
        return !this.model.wait && !this.model.error && !!this.selectedSecondary;
    }

    public selectSecondary(secondaryToSelect: any): void {
        this.selectedSecondary = secondaryToSelect;
    }

    public addToSecondary(): void {
        const data = new models.AddToSecondaryBm();
        data.comment = this.model.comment;
        data.linkedSnId = this.selectedSecondary.id;
        data.sn = this.model.sn;

        this.model.wait = true;

        this.eventsService
            .addToSecondary(data)
            .then(() => {
                this.backToEvents();
            })
            .catch(error => {
                this.model.wait = false;
                this.model.error = error;
            });
    }
}
