﻿import { Component, OnInit, ViewChild } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { Router } from '@angular/router'

import { SnRegistrationService } from '../sn-registration.service'
import { SnRegistrationsAccountVm, SaveVm, AddressVm } from '../../models'
import { Urls } from 'src/app/routing/urls';

@Component({
    selector: 'sn-registration-create',
    templateUrl: './sn-registration-create.component.html',
})
export class SnRegistrationCreateComponent implements OnInit {
    @ViewChild('formElement') formElement: FormGroup;
    model: SaveVm;
    accounts: SnRegistrationsAccountVm[]

    serverValidationError: string

    constructor(
        private service: SnRegistrationService,
        private router: Router
    ) { }

    async ngOnInit(): Promise<void> {
        this.accounts = await this.service.getCreateVm();
        this.model = new SaveVm();
        this.model.address = new AddressVm()
    }

    async submit(): Promise<void> {
        this.serverValidationError = '';
        if (this.formElement.invalid) {
            return;
        }
        await this.service.save(this.model).catch(e => this.serverValidationError = e);
        if (!this.serverValidationError) {
            this.navigateBack();
        }
    }

    navigateBack(): void {
        this.router.navigateByUrl(Urls.SnRegistrationList)
    }
}