﻿import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { SoftwareVersionListComponent } from './software-version-list/software-version-list.component';
import { SoftwareVersionEditComponent } from './software-version-edit/software-version-edit.component';
import { SoftwareVersionService } from './software-version.service';

@NgModule({
    imports: [
        SharedModule,
    ],
    declarations: [
        SoftwareVersionListComponent,
        SoftwareVersionEditComponent
    ],
    providers: [
        SoftwareVersionService
    ],
    exports: [
        SoftwareVersionListComponent,
        SoftwareVersionEditComponent
    ],
    entryComponents: [
        SoftwareVersionListComponent,
        SoftwareVersionEditComponent
    ]
})
export class SoftwareVersionModule { }