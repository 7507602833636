import { Injectable } from '@angular/core';
import { Http } from '@angular/http';

import { BaseService } from '../../core/services/base.service'
import * as models from '../../models';

@Injectable()
export class LogRecordService extends BaseService {
    private baseUri = "logRecord/";

    constructor(http: Http){ super(http);}

    getLogRecords(filter: models.LogItemFilter): Promise<Array<models.LogRecordVm>>{
        return this.get<Array<models.LogRecordVm>>(this.baseUri + "Load", filter);
    }
}
