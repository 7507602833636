﻿import { NgModule } from '@angular/core';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown'

import { SharedModule } from '../shared/shared.module';
import { SalesOrderListComponent } from './sales-order-list/sales-order-list.component';
import { SalesOrderDetailComponent } from './sales-order-detail/sales-order-detail.component';
import { SalesOrderCreateComponent } from './sales-order-create/sales-order-create.component';
import { SalesOrderService } from '../models';

import {NgxPaginationModule} from 'ngx-pagination';

@NgModule({
    imports: [
        SharedModule,
        BsDropdownModule.forRoot(),
        NgxPaginationModule
    ],
    declarations: [
        SalesOrderListComponent,
        SalesOrderDetailComponent,
        SalesOrderCreateComponent,
    ],
    providers: [
        SalesOrderService,
    ],
    exports: [
        SalesOrderListComponent,
        SalesOrderDetailComponent,
        SalesOrderCreateComponent,
    ],
    entryComponents: [
        SalesOrderListComponent,
        SalesOrderDetailComponent,
        SalesOrderCreateComponent,
    ]
})
export class SalesOrderModule { }