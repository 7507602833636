import { NgModule } from "@angular/core";
import { ModalModule, BsDropdownModule } from "ngx-bootstrap";

import { SharedModule } from "../../shared/shared.module";
import { CommonModule } from "@angular/common";
import { ExtShipRequestListComponent } from "./list/ext-ship-request-list.component";
import { ExtShipRequestEditComponent } from "./edit/component";
import { ExtShipRequestService } from "./ext-ship-request.service";
import { ShipRequestDetailComponent } from "./ship-request-detail/ship-request-detail.component";
import { ShipRequestShipmentsComponent } from "./ship-request-shipments/ship-request-shipments.component";
import { NgxPaginationModule } from "ngx-pagination";

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    NgxPaginationModule,
  ],
  providers: [ExtShipRequestService],
  declarations: [
    ExtShipRequestListComponent,
    ExtShipRequestEditComponent,
    ShipRequestDetailComponent,
    ShipRequestShipmentsComponent,
  ],
  exports: [ShipRequestDetailComponent, ShipRequestShipmentsComponent, ExtShipRequestListComponent],
})
export class ExtShipRequestModule {}
