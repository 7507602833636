import { Injectable } from "@angular/core";
import { BaseService } from "../core/services/base.service";
import { Http } from "@angular/http";
import { AccountVm, EditAccountVm, SaveResult } from "../models";
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export 
class AccountService extends BaseService
{
	constructor (http: Http)
	{
		super(http);
	}
	public getListVm() : Promise<AccountVm[]>
	{
		return this.get<AccountVm[]>(this.baseUri + `GetListVm`);
	}
	public getEditVm(id: number) : Promise<EditAccountVm>
	{
		return this.get<EditAccountVm>(this.baseUri + `GetEditVm/${id}`);
	}
	public save(model: EditAccountVm) : Promise<SaveResult>
	{
		model.id = model.id || 0
		return this.post<SaveResult>(this.baseUri + `Save`, model);
	}
	private baseUri: string = 'account/';
}