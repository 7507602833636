import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'

@Component({
    selector: 'dashboard-external',
    templateUrl: './component.html',
})
export class DashboardExternalComponent implements OnInit {    
    constructor(
        private router: Router
    ) { }

    ngOnInit() {
        this.router.navigateByUrl('ext/ship-request')
    }
}