﻿import { NgModule } from '@angular/core';

import {NgxPaginationModule} from 'ngx-pagination';

import { SharedModule } from '../shared/shared.module';
import { SnRegistrationListComponent } from     './sn-registration-list/sn-registration-list.component';
import { SnRegistrationEditComponent } from   './sn-registration-edit/sn-registration-edit.component';
import { SnRegistrationService } from './sn-registration.service'
import { SnRegistrationCreateComponent } from './sn-registration-create/sn-registration-create.component';

@NgModule({
    imports: [
        SharedModule, NgxPaginationModule
    ],
    declarations: [
        SnRegistrationListComponent,
        SnRegistrationEditComponent,
        SnRegistrationCreateComponent,
    ],
    providers: [
        SnRegistrationService
    ],
    exports: [
        SnRegistrationListComponent,
        SnRegistrationEditComponent,
        SnRegistrationCreateComponent,
    ],
    entryComponents: [
        SnRegistrationListComponent,
        SnRegistrationEditComponent,
        SnRegistrationCreateComponent,
    ]
})
export class SnRegistrationModule { }