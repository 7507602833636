import { Component, EventEmitter, Input, Output } from '@angular/core';

import { EventsService } from './../events.service';
import { EventMainModel } from '../event-main/event-main.model';

@Component({
    selector: '<event-base>',
    template: '<div></div>'
})
export class EventBaseComponent {
    @Input() model: EventMainModel;
    @Output() done = new EventEmitter();

    constructor(public eventsService: EventsService) {
    }

    backToEvents() {
        this.model.wait = false;
        this.model.comment = '';
        this.model.error = '';
        this.done.emit();
    }
}