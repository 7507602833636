import { Component, OnInit } from '@angular/core';
import { ExternalShipRequestVm } from '../../../models'
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ExtShipRequestService } from '../ext-ship-request.service';

@Component({
  selector: 'ext-ship-request-list',
  templateUrl: './ext-ship-request-list.component.html',
  styleUrls: ['./ext-ship-request-list.component.css']
})
export class ExtShipRequestListComponent implements OnInit {
  list: ExternalShipRequestVm[];

  public showOverlay = true;
  public page: number;
  public total: number;
  public totalPages: number;
  public pageSize: number = 50;

  p: number = 1;

//   timedOut() {
//     alert('Times up!');
//     this.showOverlay = false;
// }

  constructor(
    private service: ExtShipRequestService,
    private router: Router,
    private activatedRoute: ActivatedRoute) { }

  async ngOnInit(): Promise<void> {
    // this.activatedRoute.queryParams.subscribe((params) => {
    //   this.page = params["page"];

    //   window.scrollTo(0, 0);

    // });
    this.showOverlay = true;
    this.list = await this.service.getListVm()
    this.showOverlay = false;
  }

  navigateToDetails(id: number): void {
    this.router.navigate(['/ext/ship-request/edit/' + id])
  }
}
