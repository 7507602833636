﻿import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'

import { PermissionService, SoftwareRolePermissionVm, SoftwarePermissionsVm, Enums } from '../../models/view-models';
import { SoftwarePermission } from './software-permission'
import { RolePermission } from './role-permission'

@Component({
    selector: 'software-permissions',
    templateUrl: './software-permissions.component.html',
})
export class SoftwarePermissionsComponent implements OnInit {
    selectedPermissionType: Enums.PermissionType;
    model: SoftwarePermissionsVm;
    softwarePermissions: Array<SoftwarePermission>;

    constructor(
        private permissionService: PermissionService,
        private router: Router
    ) {}

    async ngOnInit(): Promise<void> {
        this.selectedPermissionType = Enums.PermissionType.SoftwareDownload;
        this.model = await this.permissionService.getSoftwarePermissionsVm();
        this.softwarePermissions = this.getSoftwaresPermissions();
    }

    async save(): Promise<void> {
        await this.permissionService.saveSoftwarePermissionsVm(this.model.permissions);
        this.router.navigateByUrl('settings');
    }

    changePermission(rolePermission: RolePermission): void {
        rolePermission.value = !rolePermission.value;

        const permissionIndex = this.getPermissionIndex(rolePermission.softwareId, rolePermission.role);
        if (permissionIndex > -1) {
            this.model.permissions.splice(permissionIndex, 1);
        } else {
            const permission = new SoftwareRolePermissionVm({
                permissionType: this.selectedPermissionType,
                role: rolePermission.role,
                softwareId: rolePermission.softwareId
            })
            this.model.permissions.push(permission);
        }
    }

    private isPermissionExist(softwareId: number, role: Enums.Role): boolean {
        return this.getPermissionIndex(softwareId, role) > -1;
    }

    private getPermissionIndex(softwareId: number, role: Enums.Role): number {
        return this.model.permissions.findIndex(x => x.role == role && x.softwareId == softwareId);
    }

    private getSoftwaresPermissions(): SoftwarePermission[] {
        const softwarePermissions = new Array<SoftwarePermission>();

        for (let software of this.model.softwares) {
            const rolePermissions = new Array<RolePermission>();

            for (let role of this.model.roles) {
                rolePermissions.push(new RolePermission({
                    softwareId: software.id,
                    role: role,
                    value: this.isPermissionExist(software.id, role)
                }));
            }

            softwarePermissions.push(new SoftwarePermission({
                software: software,
                rolePermissions: rolePermissions
            }));
        }

        return softwarePermissions;
    }
}