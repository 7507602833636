import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";

import { ValueVm } from "../../../models";
import { Urls } from "../../../routing/urls";

@Component({
  selector: "<property-value>",
  templateUrl: "./property-value.component.html",
})
export class PropertyValueComponent {
  @Input() model: ValueVm;

  constructor(private router: Router) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
  }

  public goto(propertyValue: ValueVm) {
    if (propertyValue.code === "file") {
      const eventFileId = propertyValue.originValue;
      const url = Urls.GetEventFileDownload(eventFileId);
      window.open(url, "_blank");
      return;
    } else if (propertyValue.code === "productionrequest") {
      const url = Urls.GetProductionRequestDetails(propertyValue.originValue);
      this.router.navigateByUrl(url);
    } else if (propertyValue.code === "salesorder") {
      const url = Urls.GetSalesOrderDetails(propertyValue.originValue);
      this.router.navigateByUrl(url);
    } else if (propertyValue.code === "shipment") {
      const url = Urls.GetShipRequestEditByShipment(propertyValue.originValue);
      //console.log(url);
      this.router.navigateByUrl(url);
    } else if (propertyValue.code === "assignedpcbsn") {
      const url = Urls.SnDetails + propertyValue.value;
      //console.log(url);
      this.router.navigateByUrl(url); 
      //this.router.onSameUrlNavigation = 'reload';
    } else if (propertyValue.code === "assignedsn") {
      const url = Urls.SnDetails + propertyValue.value;
      //console.log(url);
      this.router.navigateByUrl(url); 
      //this.router.onSameUrlNavigation = 'reload';
    }
    else if (propertyValue.code === "rma") {
      const url = Urls.GetRmaDetails(propertyValue.originValue);
      //console.log(url);
      this.router.navigateByUrl(url); 
    }

    // } else if (propertyValue.code === 'product') {
    //     this.routingService.navigate(AppRoutes.products.edit, { id: propertyValue.originValue });
    // }
  }
}
