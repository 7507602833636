﻿import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';

import { SoftwareService, SoftwareVm } from '../../models';

@Component({
    selector: 'software-list',
    templateUrl: './software-list.component.html',
})
export class SoftwareListComponent implements OnInit {
    list: SoftwareVm[];

    constructor(
        private dataService: SoftwareService,
        private router: Router
    ) {
    }

    async ngOnInit(): Promise<void> {
        this.list = await this.dataService.getListVm();
    }

    navigateToEdit(id: number): void {
        this.router.navigate(['/software/edit/' + id])
    }

}