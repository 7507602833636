﻿import { Component, OnInit /*, Inject, Renderer2*/ } from "@angular/core";
import { Router /*, ActivatedRoute, Params*/ } from "@angular/router";

import { SnRegistrationService } from "../sn-registration.service";
import { ListItemVm } from "../../models";
import { SnRegistrationListFilter } from './sn-registration-list-filter';
import { Urls } from "src/app/routing/urls";

@Component({
  selector: "sn-registration-list",
  templateUrl: "./sn-registration-list.component.html",
})
export class SnRegistrationListComponent implements OnInit {
  filter = new SnRegistrationListFilter();
  list: ListItemVm[];

  public showOverlay = true;
  public pageSize: number = 50;

  public p: number = 1;

  constructor(
    private dataService: SnRegistrationService,
    private router: Router,
   ) {}

  async ngOnInit(): Promise<void> {
    this.filter.serialNumber = '';
    await this.getListVm('ALL');
  }

  navigateToDetails(id: number): void {
    this.router.navigateByUrl(Urls.GetSnRegistrationEdit(id));
  }

  async getListVm(sn: string): Promise<void> {
    this.showOverlay = true;
    
    if(sn === '') {
      sn = 'ALL';
    }
        
    this.list = await this.dataService.getListVm(sn);

    window.scrollTo(0, 0);
    this.p = 1;

    this.showOverlay = false;
  }

  async getListFilteredVm(filter: string): Promise<void> {
    this.showOverlay = true;
    
    this.list = await this.dataService.getListFilteredVm(filter);

    window.scrollTo(0, 0);
    this.p = 1;

    this.showOverlay = false;
  }

  
}
