import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'

import * as models from '../../models';
import { EventsService } from '../events.service';
import { EventMainModel } from './event-main.model';
import { UserPropertyGroupModel } from "../../models/user-property-group.model"
//import { Pipe, PipeTransform } from '@angular/core';

const _model = new EventMainModel()

/* @Pipe({name: 'replaceLineBreaks'})
 export class ReplaceLineBreaks implements PipeTransform {
     transform(value: string): string {
         return value.replace(/\n/g, '&lt;br/&gt;');
     }
   }*/

@Component({
    selector: '<event-main>',
    templateUrl: './event-main.component.html',
    styleUrls: ['./event-main.component.scss']
})
export class EventMainComponent implements OnInit {
    private propertyGroups: UserPropertyGroupModel[];
    model: EventMainModel;
    notEmptyGroups: UserPropertyGroupModel[];
    jsonForm: models.JsonForm;
    private finsSnRx = new Subject<string>();

    customFlowCodes: string[] = [
        'addToProductionRequest',
        'addToSalesOrder',
        'moveToProduction',
        'linkToPcb',
        'addToShipmentRequest',
        'addToPrimary',
        'addToSecondary',
        'assemblyStarted_disabled',
        'adminUpdate',
        'sendEmail',
        'changeAccount',
        'changeProduct',
        'shipped',
        'issuedRMA',
        'uploadFile',
    ];

    public showOverlay = true;

    constructor(
        private eventsService: EventsService,
        private titleService: Title,
        private route: ActivatedRoute,
    ) {
        this.model  = _model
    }

    askToFindSn() {
      const sn = this.model.sn
      this.finsSnRx.next(sn);
    }

    ngOnInit(): void {
        const routeSn = this.route.snapshot.params['id'] || ''
        this.model.sn = routeSn || this.model.sn
        const that = this

        this.finsSnRx.pipe(
          debounceTime(400),
          distinctUntilChanged()
        ).subscribe((sn) => {
          that.findSn()
        })
        this.findSn()
    }

    selectSn(sn: { sn: string }): void {
        this.model.sn = sn.sn;
        this.findSn();
    }

    async findSn(): Promise<void> {
        this.showOverlay = true;

        const data = new models.FindSnArgs({ sn: this.model.sn })
        const result = await this.eventsService.findSn(data)
        this.model.update(result);
        this.titleService.setTitle('SensorTrack' + (this.model.sn ? `: ${this.model.sn}` : ''));
        this.propertyGroups = UserPropertyGroupModel.createVisibleGroups(result.propertyGroups);
        this.notEmptyGroups = this.propertyGroups.filter(x => this.isAnyPropertyInGroup(x));

        this.showOverlay = false;
    }

    isCustomFlow(flowCode: string): boolean {
        return this.customFlowCodes.indexOf(flowCode) > -1;
    }

    undo(): void {
        this.model.wait = true;
        this.eventsService
            .undo(this.model.sn)
            .then(() => {
                this.findSn();
                this.model.wait = false;
            });
    }

    getUndoEventName(): string {
        let item = this.model.details.history.find(item => !item.isDeleted);
        return !item ? '' : 'Undo ' + item.eventName;
    }

    do(flow: models.EventFlowVm): void {
        if (flow.jsonForm) {
            this.model.customViewCode = '';
            this.model.customFlow = flow;
            this.jsonForm = JSON.parse(flow.jsonForm);
            return;
        }
        this.jsonForm = null;

        if (this.isCustomFlow(flow.code)) {
            this.model.customViewCode = flow.code;
            this.model.customFlow = flow;
            return;
        }
        this.model.commentHint = '';
        this.model.customViewCode = '';
        this.model.error = "";
        if (flow.commentIsRequired && !this.model.comment) {
            this.model.commentHint = flow.commentHint || 'Comments...';
            return;
        }
        this.model.wait = true;
        const data = new models.CreateBm({
            sn: this.model.sn, eventFlowId: flow.id, comment: this.model.comment
        });
        this.eventsService
            .create(data)
            .then(() => {
                this.model.comment = '';
                this.findSn();
                this.model.wait = false;
            })
            .catch(e => {
                this.model.wait = false;
                this.model.error = e;
            });

    }

    backToEvents(): void {
        this.model.customViewCode = '';
        this.jsonForm = null;
        this.findSn();
    }

    getPropertiesByGroup(group: UserPropertyGroupModel): models.ValueVm[] {
        return this.model.details.values.filter(x => group.properties.find(y => y.code === x.code));
    }

    private isAnyPropertyInGroup(group: UserPropertyGroupModel): boolean {
        if (!this.model.details.values) return false;
        return this.model.details.values.some(x => group.properties.some(y => y.code === x.code));
    }


}