import { NgModule } from '@angular/core'
import { BsDropdownModule } from 'ngx-bootstrap/dropdown'

import { SharedModule } from '../shared/shared.module';
import { EventAddToProductionRequestComponent } from './event-add-to-production-request/event-add-to-production-request.component';
import { EventAddToSalesOrderComponent } from './event-add-to-sales-order/event-add-to-sales-order.component';
import { EventMoveToProductionComponent } from './event-move-to-production/event-move-to-production.component';
import { EventLinkToPcbComponent } from './event-link-to-pcb/event-link-to-pcb.component';
import { EventAddToShipmentRequestComponent } from './event-add-to-shipment-request/event-add-to-shipment-request.component';
import { EventAssemblyStartedComponent } from './event-assembly-started/event-assembly-started.component';
import { EventChangeAccountComponent } from './event-change-account/event-change-account.component';
import { EventChangeProductComponent } from './event-change-product/event-change-product.component';
import { EventShippedComponent } from './event-shipped/event-shipped.component';
import { EventIssuedRmaComponent } from './event-issued-rma/event-issued-rma.component';
import { EventMainComponent } from './event-main/event-main.component';
import { EventUploadFileComponent } from './event-upload-file/event-upload-file.component';
import { EventJsonFormComponent } from './event-json-form/event-json-form.component';
import { EventAdminUpdateComponent } from './event-admin-update/event-admin-update.component';
import { EventSendEmailComponent } from './event-send-email/event-send-email.component';
import { EventsService } from './events.service';
import { EventBaseComponent } from './event-base/event-base.component';
import { EventAddToPrimaryComponent } from './event-add-to-primary/event-add-to-primary.component';
import { EventAddToSecondaryComponent } from './event-add-to-secondary/event-add-to-secondary.component';
import { ReplaceLineBreaks } from './replace.pipe';

@NgModule({
    imports: [SharedModule, BsDropdownModule.forRoot(),],
    declarations: [
        EventAddToProductionRequestComponent,
        EventAddToSalesOrderComponent,
        EventMoveToProductionComponent,
        EventLinkToPcbComponent,
        EventAddToShipmentRequestComponent,
        EventAssemblyStartedComponent,
        EventBaseComponent,
        EventChangeAccountComponent,
        EventChangeProductComponent,
        EventShippedComponent,
        EventIssuedRmaComponent,
        EventMainComponent,
        EventUploadFileComponent,
        EventJsonFormComponent,
        EventAdminUpdateComponent,
        EventSendEmailComponent,
        EventAddToPrimaryComponent,
        EventAddToSecondaryComponent,
        ReplaceLineBreaks
    ],
    providers: [
        EventsService,
        ReplaceLineBreaks
    ],
    exports: [
        EventMainComponent,
    ],
    entryComponents: [
        EventMainComponent,
    ]

    

})
export class EventsModule { }
