﻿import { Component, OnInit } from '@angular/core';

import { FlowDebugService, FlowDebugVm, FilterFlowVm } from '../../models';

@Component({
    selector: 'flow-debug-list',
    templateUrl: './flow-debug-list.component.html',
})
export class FlowDebugListComponent implements OnInit {
    sn = '';
    vm: FlowDebugVm;

    constructor(
        private dataService: FlowDebugService) {
    }

    async ngOnInit(): Promise<void> {
        this.vm = new FlowDebugVm();
        this.vm.flows = new Array<FilterFlowVm>();
        this.reload();
    }

    async reload(): Promise<void> {
        if (!this.sn.trim().length) return;
        this.vm = await this.dataService.getVm(this.sn);
    }
}