﻿import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'

import { PropertyService, PropertiesPropertyVm, Enums } from '../../models'
import { Util } from '../../tools/util'

@Component({
    selector: 'property-list',
    templateUrl: './property-list.component.html',
})
export class PropertyListComponent implements OnInit {
    isBusy: boolean;
    properties: PropertiesPropertyVm[];

    constructor(
        private dataService: PropertyService,
        private router: Router
    ) {
    }

    async ngOnInit(): Promise<void> {
        this.properties = await this.dataService.getList();
    }

    getPropertyTypeName(type: Enums.PropertyType): string {
        return Util.getSplittedEnumName(Enums.PropertyType, type);
    }

    getLocationName(type: Enums.PropertyLocation): string {
        return Util.getSplittedEnumName(Enums.PropertyLocation, type);
    }

    navigateBack(): void {
        this.router.navigateByUrl('settings')
    }

    async save(): Promise<void> {
        await this.dataService.saveProperties(this.properties);
        this.navigateBack();
    }
}