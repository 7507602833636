﻿import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { EditUserVm, UserAccountVm } from '../../models/view-models'
import { UserService } from '../user.service';

@Component({
    selector: 'user-edit',
    templateUrl: './user-edit.component.html',
})
export class UserEditComponent implements OnInit {
    @ViewChild('form') form: FormGroup;
    model: EditUserVm;
    link: string;
    isBusy = false;
    isNew = true;
    roles: string[];

    constructor(
        private service: UserService,
        private route: ActivatedRoute,
        private router: Router
    ) {
        this.roles = ['external','internal','admin']
    }

    async ngOnInit(): Promise<void> {
        this.model = new EditUserVm();
        this.model.accounts = new Array<UserAccountVm>();        
        const id = +(this.route.snapshot.params['id'] || 0)
        this.model.id = id
        this.load(this.model.id);
    }

    async load(id: number): Promise<void> {
        if (id) {
            this.model = await this.service.getEditVm(id);
            this.isNew = false;
        }
        console.log(this.model.id)
    }

    async submit(): Promise<void> {
        this.isBusy = true;
        await this.service.save(this.model);
        this.isBusy = false;
        this.navigateBack();
    }

    async generateLink(): Promise<void> {
        this.isBusy = true;
        const key = await this.service.generateSetPasswordKey(this.model);
        this.isBusy = false;
        this.link = location.protocol + "//" + window.location.host + "/account/setPassword?username=" + this.model.userName + "&code=" + key;
    }

    navigateBack(): void {
        this.router.navigate(['/users'])
    }

    isModelRoleExternal() : boolean {
        return this.model.role == 'external'
    }
}