﻿import { Enums } from "../../models/view-models";

export class RolePermission {
    softwareId: number;
    role: Enums.Role
    value: boolean;

    constructor(init?: Partial<RolePermission>) {
        Object.assign(this, init);
    }
}