﻿import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { AccountService } from './account.service'
import { AccountListComponent } from './account-list/account-list.component';
import { AccountEditComponent } from './account-edit/account-edit.component';

@NgModule({
    imports: [
        SharedModule
    ],
    providers: [
        AccountService
    ],
    declarations: [
        AccountListComponent,
        AccountEditComponent
    ],
    exports: [
        AccountListComponent,
        AccountEditComponent
    ],
    entryComponents: [
        AccountListComponent,
        AccountEditComponent
    ]
})
export class AccountModule { }