import { Injectable } from "@angular/core";
import { BaseService } from "../../core/services/base.service";
import { Http } from "@angular/http";
import { ExternalEditShipRequestVm, ExternalShipRequestVm, ShipRequestPredefinedDataVm, ExternalSaveShipRequestVm, SaveResult } from "../../models";

@Injectable()
export class ExtShipRequestService extends BaseService
{
	constructor (http: Http)
	{
	super(http);
	}
	public getEditVm(id: number) : Promise<ExternalEditShipRequestVm>
	{
		return this.get<ExternalEditShipRequestVm>(this.baseUri + `GetEditVm/${id}`);
	}
	public getEditVmByShipmentId(shipmentId: number) : Promise<ExternalEditShipRequestVm>
	{
		return this.get<ExternalEditShipRequestVm>(this.baseUri + `/GetEditVmByShipmentId/${shipmentId}`);
	}
	public getListVm() : Promise<ExternalShipRequestVm[]>
	{
		return this.get<ExternalShipRequestVm[]>(this.baseUri + `GetListVm`);
	}
	public getPredifinedDataItems() : Promise<ShipRequestPredefinedDataVm[]>
	{
		return this.get<ShipRequestPredefinedDataVm[]>(this.baseUri + `GetPredifinedDataItems`);
	}
	public save(vm: ExternalSaveShipRequestVm) : Promise<SaveResult>
	{
		return this.post<SaveResult>(this.baseUri + `Save`, vm);
	}
	public cancel(id: number, reason: string) : Promise<any>
	{
		const uriParams = { id: id, reason };
		return this.post<any>(this.baseUri + `Cancel`, uriParams);
	}
	public deletePredefinedData(keyName: string) : Promise<any>
	{
		return this.post<any>(this.baseUri + `DeletePredefinedData`, null, { keyName: keyName })
	}
private baseUri: string = 'ext/shipRequest/';
}