import { Component, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { DoShippedBm } from '../../models';
import { EventBaseComponent } from '../event-base/event-base.component';
import { EventsService } from '../events.service';

@Component({
    selector: '<event-shipped>',
    templateUrl: './event-shipped.component.html'
})
export class EventShippedComponent extends EventBaseComponent {
    @ViewChild('formElement') formElement: FormGroup;
    formData: DoShippedBm;
    replacementSnList = new Array<string>();

    constructor(public eventsService: EventsService) {
        super(eventsService);
    }

    async ngOnInit(): Promise<void> {
        this.model.wait = true;
        const res = await this.eventsService.getShippedVm(this.model.sn);
        this.model.wait = false;

        if (res.replacementSnList) {
            this.replacementSnList = res.replacementSnList;
        }
        this.formData = new DoShippedBm(this.model);
        Object.assign(this.formData, res);

        if (res.shipDate) {
            this.formData.shipDate = new Date(res.shipDate);
        }
    }

    async submit(): Promise<void> {
        this.model.error = '';
        if (this.formElement.invalid) {
            return;
        }

        this.model.wait = true;
        await this.eventsService
            .doShipped(this.formData)
            .catch(error => this.model.error = error);
        this.model.wait = false;

        if (this.model.error) return;
        this.backToEvents();
    }
}