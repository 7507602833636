﻿import { Component, Input, Inject } from '@angular/core';
import { Router } from '@angular/router';

import { Shipment } from '../../models';

@Component({
    selector: 'ship-request-shipments',
    templateUrl: './ship-request-shipments.component.html',
})
export class ShipRequestShipmentsComponent {
    @Input() shipments: Shipment[];

    constructor(
        private router: Router
    ) {
    }

    navigateToDetails(shipment: Shipment): void {
        this.router.navigate(['/sn/detail/' + shipment.serialNumber])
    }
}