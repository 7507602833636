import { Component, ElementRef } from '@angular/core';

import * as models from '../../models';
import { EventBaseComponent } from '../event-base/event-base.component';
import { EventsService } from '../events.service';

@Component({
    selector: '<event-upload-file>',
    templateUrl: './event-upload-file.component.html'
})
export class EventUploadFileComponent extends EventBaseComponent {
    public file: File;
    public error: string;

    constructor(public eventsService: EventsService) {
        super(eventsService);
    }

    ngOnInit(): void {
    }

    public fileChanged(element: HTMLInputElement): void {
        this.file = element.files[0];
        this.error = !this.file ? 'Please, select file' : '';
    }

    public uploadFile(): void {
        this.model.wait = true;
        const fileReader = new FileReader();
        fileReader.onloadend = this.onFileLoadEnd.bind(this);
        fileReader.readAsDataURL(this.file);
    }

    private onFileLoadEnd(event): void {
        const data = new models.UploadFileVm();
        data.comment = this.model.comment;
        data.fileName = this.file.name;
        data.sn = this.model.sn;
        data.content = event.target.result;

        this.eventsService
            .uploadFile(data)
            .then(() => this.backToEvents())
            .catch(error => {
                this.model.wait = false;
                this.model.error = error;
            });
    }
}