﻿import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Enums, DetailProductionRequestVm } from '../../../models/view-models'
import { ExternalProductionRequestService } from '../service';

@Component({
  selector: 'ext-production-request-detail',
  templateUrl: './production-request-detail.component.html',
})
export class ExtProductionRequestDetailComponent implements OnInit {
  model = new DetailProductionRequestVm();
  isBusy: boolean;

  public showOverlay = true;

  constructor(
    private service: ExternalProductionRequestService,
    private route: ActivatedRoute,
  ) { }

  async ngOnInit(): Promise<void> {
    this.model.id = +this.route.snapshot.params['id'];
    if (this.model.id) {
      this.load(this.model.id);
    }
  }

  async load(id: number): Promise<void> {
    this.showOverlay = true;

    this.model = await this.service.getDetailVm(id)
    this.model.sns.sort((a, b) => b.sn > a.sn ? 1 : -1)

    this.showOverlay = false;
  }

  navigateBack(): void {
    window.history.back();
  }

  getStatusName(value: Enums.ProductionRequestStatus): string {
      return Enums.ProductionRequestStatus[value];
  }
}