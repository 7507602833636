﻿import { NgModule } from '@angular/core'

import { SharedModule } from '../shared/shared.module'
import { UserGroupService } from './user-group.service'
import { UserGroupListComponent } from './user-group-list/user-group-list.component'
import { UserGroupEditComponent } from './user-group-edit/user-group-edit.component'

@NgModule({
    imports: [
        SharedModule
    ],
    providers: [
        UserGroupService
    ],
    declarations: [
        UserGroupListComponent,
        UserGroupEditComponent
    ],
    exports: [
        UserGroupListComponent,
        UserGroupEditComponent
    ],
    entryComponents: [
        UserGroupListComponent,
        UserGroupEditComponent
    ]
})
export class UserGroupModule { }