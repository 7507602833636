import { Injectable } from '@angular/core'
import { Http } from '@angular/http'
import { BaseService } from "../core/services/base.service";
import { EditShipRequestVm, ShipRequestPredefinedDataVm, ShipRequestsFilterVm, GetEditArgs, ShipRequestListVm, SaveShipRequestVm, SaveResult, SaveShipRequestPredefinedDataVm, 
  BodyParameter } from '../models'

@Injectable()
export class ShipRequestService extends BaseService
{
	constructor (http: Http)
	{
		super(http);
	}
	public getEditVm(id: number, rmaOrderId?: number) : Promise<EditShipRequestVm>
	{
		const uriParams = { rmaOrderId: rmaOrderId };
		return this.get<EditShipRequestVm>(this.baseUri + `/GetEditVm/${id}`, uriParams);
	}
	public getEditVmByShipmentId(shipmentId: number) : Promise<EditShipRequestVm>
	{
		return this.get<EditShipRequestVm>(this.baseUri + `/GetEditVmByShipmentId/${shipmentId}`);
	}
	public getPredefinedDataItems() : Promise<ShipRequestPredefinedDataVm[]>
	{
		return this.get<ShipRequestPredefinedDataVm[]>(this.baseUri + `/GetPredefinedDataItems`);
	}
	public getEditVmAdmin(args: GetEditArgs) : Promise<EditShipRequestVm>
	{
		const uriParams = { args: args };
		return this.get<EditShipRequestVm>(this.baseUri + `/GetEditVm`, uriParams);
	}
	public getListVm(filter: ShipRequestsFilterVm) : Promise<ShipRequestListVm>
	{
		return this.get<ShipRequestListVm>(this.baseUri + `/GetListVm`, filter);
	}
	public save(vm: SaveShipRequestVm) : Promise<SaveResult>
	{
		return this.post<SaveResult>(this.baseUri + `/Save`, vm);
	}
	public cancel(id: number, reason: BodyParameter<string>) : Promise<any>
	{
		const uriParams = { id: id };
		return this.post<any>(this.baseUri + `/Cancel`, reason, uriParams);
	}
	public setStatusAccept(id: number) : Promise<SaveResult>
	{
		const uriParams = {  };
		return this.post<SaveResult>(this.baseUri + `/SetStatusAccept/${id}`, null, uriParams);
	}
	public setStatusDone(id: number) : Promise<SaveResult>
	{
		const uriParams = {  };
		return this.post<SaveResult>(this.baseUri + `/SetStatusDone/${id}`, null, uriParams);
	}
	public setStatusProcessing(id: number) : Promise<SaveResult>
	{
		return this.post<SaveResult>(this.baseUri + `/SetStatusProcessing/${id}`, null);
	}
	public savePredefinedData(vm: SaveShipRequestPredefinedDataVm) : Promise<SaveResult>
	{
		const uriParams = {  };
		return this.post<SaveResult>(this.baseUri + `/SavePredefinedData`, vm, uriParams);
  	}
  
	public deletePredefinedData(keyName: string) : Promise<any>
	{
		const uriParams = { keyName: keyName };
		return this.post<any>(this.baseUri + `/DeletePredefinedData`, null, uriParams);
  	}

  	public delete(id: number) : Promise<any>
	{
		return this.post<any>(this.baseUri + `/delete/` + id, null);
  	}
  	  
	private baseUri: string = 'shipRequest';
}