import { Injectable } from "@angular/core"
import { BaseService } from "../../core/services/base.service";
import { SalesOrderVm, DetailSalesOrderVm } from "../../models";
import { Http } from "@angular/http";

@Injectable()
export class ExternalSalesOrderService extends BaseService
{
	constructor (http: Http)
	{
		super(http);
	}
	public getListVm() : Promise<SalesOrderVm[]>
	{
		return this.get<SalesOrderVm[]>(this.baseUri + `GetListVm`);
	}
	public getDetailVm(id: number) : Promise<DetailSalesOrderVm>
	{
		return this.get<DetailSalesOrderVm>(this.baseUri + `GetDetailVm/${id}`);
	}
	private baseUri: string = 'ext/sales-order/';
}