import { Component, Input } from '@angular/core';

import { AuthService } from '../../core/services/auth.service';

@Component({
    selector: 'settings-main',
    templateUrl: './main.component.html'
})
export class SettingsMainComponent {
    isAdmin: boolean;
    isInternal: boolean;

    constructor(
        private authService: AuthService,
    ) {
        this.isAdmin = this.authService.isAdmin;
        this.isInternal = this.authService.isInternal;
    }
}