import { Injectable } from '@angular/core';
import { Http, ResponseContentType } from '@angular/http';

import { BaseService } from '../core/services/base.service'
import {
    SaveResult,
    SoftwareVersionFilter,
    SoftwareVersionVm
} from '../models';

@Injectable()
export class SoftwareVersionService extends BaseService {
    private baseUri = "softwareVersion/";

    constructor(http: Http) { super(http); }

    getAll(filter: SoftwareVersionFilter): Promise<Array<SoftwareVersionVm>> {
        return this.get<Array<SoftwareVersionVm>>(this.baseUri, filter);
    }

    getById(id: number): Promise<SoftwareVersionVm> {
        return id > 0 ? this.get<SoftwareVersionVm>(this.baseUri + id) : new Promise(function (resolve, reject) { resolve(null) });
    }

    save(model: SoftwareVersionVm): Promise<SoftwareVersionVm> {
        return this.post(this.baseUri + model.id, model);
    }

    downloadFile(fileId: number): Promise<void> {
        return this.download<void>(this.baseUri + `downloadFile/${fileId}`)
    }

    deleteSoftwareVersion(id: number): Promise<SaveResult> {
        return this.sendDeleteReqeuest<SaveResult>(this.baseUri + `/${id}`);
    }
}
