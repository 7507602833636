﻿import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router'

import { UserPropertyGroupEditComponent } from '../user-property-group-edit/user-property-group-edit.component';
import { UserPropertyGroupService, Enums} from '../../models';
import { UserPropertyGroupModel } from '../../models/user-property-group.model';
import { Util } from '../../tools/util';
import { ModalConfirmComponent } from '../../shared/components/modal-confirm/modal-confirm.component';

@Component({
    selector: 'user-property-group-list',
    templateUrl: './user-property-group-list.component.html',
    styleUrls: ['./user-property-group-list.component.scss']
})
export class UserPropertyGroupListComponent implements OnInit {
    @ViewChild('editModal') editModal: UserPropertyGroupEditComponent;
    @ViewChild('deleteModal') deleteModal: ModalConfirmComponent;

    isBusy: boolean;
    groups: UserPropertyGroupModel[];
    groupToDelete: UserPropertyGroupModel;
    category: string;

    constructor(
        private dataService: UserPropertyGroupService,
        private route: ActivatedRoute
    ) {
    }

    async ngOnInit(): Promise<void> {
        this.category = this.route.snapshot.queryParams.category

        const that = this
        this.getGroups()
            .then(x => that.groups = x)
    }

    showNewGroupDialog(): void {
        var group = new UserPropertyGroupModel({
            name: '',
            isVisible: true,
            isCollapsed: false,
            category: this.category
        });
        this.showEditDialog(group);
    }

    showEditDialog(group: UserPropertyGroupModel): void {
        if (this.isBusy) return;
        const otherGroups = this.getOtherGroups(group);
        this.editModal.show(group, otherGroups.map(x => x.name));
    }

    showDeleteDialog(group: UserPropertyGroupModel): void {
        this.groupToDelete = group;
        this.deleteModal.show(`Delete Group with name ${this.groupToDelete.name}`);
    }

    async deleteGroup(): Promise<void> {
        this.isBusy = true;
        await this.dataService.deleteGroup(this.groupToDelete.id);
        this.groupToDelete = null;
        await this.updateGroups();
        this.isBusy = false;
    }

    async updateGroups(): Promise<void> {
        this.isBusy = true;
        const newGroups = await this.getGroups();
        for (let newGroup of newGroups) {
            const oldGroup = this.groups.find(x => x.id == newGroup.id);
            if (oldGroup)
                newGroup.arePropertiesVisible = oldGroup.arePropertiesVisible;
        }
        this.groups = newGroups;
        this.isBusy = false;
    }

    async changePropertyGroup(propertyId: number, groupId: number): Promise<void> {
        this.isBusy = true;
        await this.dataService.changeGroup(propertyId, groupId);
        await this.updateGroups();
        this.isBusy = false;
    }

    getPropertyTypeName(type: Enums.PropertyType): string {
        return Util.getSplittedEnumName(Enums.PropertyType, type);
    }

    getLocationName(type: Enums.PropertyLocation): string {
        return Util.getSplittedEnumName(Enums.PropertyLocation, type);
    }

    getOtherGroups(group: UserPropertyGroupModel): UserPropertyGroupModel[] {
        return this.groups.filter(x => x.name !== group.name);
    }

    async getGroups(): Promise<UserPropertyGroupModel[]> {
        const groups = await this.dataService.getList(this.category);
        return UserPropertyGroupModel.createGroups(groups);
    }

    get allGroupsHaveProperties(): boolean {
        return this.groups && this.groups.every(x => x.properties.length > 0);
    }
}