﻿import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'

import { SalesOrderService, SalesOrderVm, Enums, SalesOrdersProductVm, SalesOrderListVm, UserVm } from '../../models';
import { SalesOrderListFilter } from './sales-order-list-filter';
import { Urls } from '../../routing/urls';

@Component({
    selector: 'sales-order-list',
    templateUrl: './sales-order-list.component.html',
})
export class SalesOrderListComponent implements OnInit {
  filter = new SalesOrderListFilter();
  salesOrderList: SalesOrderListVm;
  salesOrders: SalesOrderVm[];
  products: SalesOrdersProductVm[];
  users: UserVm[];

  public showOverlay = true;

  public pageSize: number = 20;

  p: number = 1;

  constructor(
      private service: SalesOrderService,
      private router: Router
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.filter.createDateFrom = new Date(new Date().getFullYear(), 0, 1);
    this.filter.createDateTo = new Date();
    this.applyFilter();
  }

  navigateToDetails(id: number): void {
    this.router.navigateByUrl(Urls.GetSalesOrderDetails(id))
  }

  async applyFilter(): Promise<void> {
    this.showOverlay = true;
      this.salesOrderList = await this.service.getListVm(this.filter.model);
      this.products = this.salesOrderList.products;
      this.salesOrders = this.salesOrderList.salesOrders;
      this.users = this.salesOrderList.users;
      this.salesOrders.forEach(x => {
          (<any>x).isHighlighted = (x.status === Enums.SalesOrderStatus.Created);
      })

    this.p = 1;
    this.showOverlay = false;
  }

  async resetFilter(): Promise<void> {
    this.filter = new SalesOrderListFilter();
    this.filter.createDateFrom = new Date(new Date().getFullYear(), 0, 1);
    this.filter.createDateTo = new Date();
    
    this.showOverlay = true;
      this.salesOrderList = await this.service.getListVm(this.filter.model);
      this.products = this.salesOrderList.products;
      this.salesOrders = this.salesOrderList.salesOrders;
      this.users = this.salesOrderList.users;
      this.salesOrders.forEach(x => {
          (<any>x).isHighlighted = (x.status === Enums.SalesOrderStatus.Created);
      })
    
    this.p = 1;
    this.showOverlay = false;
  }

  getStatusName(value: Enums.SalesOrderStatus): string {
      return Enums.SalesOrderStatus[value];
  }
}