﻿import { Component, Input, Inject } from "@angular/core";
import { Router } from "@angular/router";

import { Shipment } from "../../../models";

@Component({
  selector: "ship-request-shipments",
  templateUrl: "./ship-request-shipments.component.html",
})
export class ShipRequestShipmentsComponent {
  @Input() shipments: Shipment[];

  public showOverlay = false;

  constructor(private router: Router) {}

  private baseUri: string = "/ext/sn/detail/";

  navigateToDetails(shipment: Shipment): void {
      const uri = this.baseUri + shipment.serialNumber;
      //console.log(uri);
    //this.showOverlay = true;

    this.router.navigate([uri]);

    //this.showOverlay = false;
  }
}
