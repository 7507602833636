﻿import { NgModule } from '@angular/core';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown'

import { SharedModule } from '../../shared/shared.module'
import { ExtSalesOrderListComponent } from './list/component'
import { ExtSalesOrderDetailComponent } from './detail/sales-order-detail.component'
import { ExternalSalesOrderService } from './service';

import {NgxPaginationModule} from 'ngx-pagination';

@NgModule({
    imports: [
        SharedModule,
        BsDropdownModule.forRoot(),
        NgxPaginationModule
    ],
    declarations: [
        ExtSalesOrderListComponent,
        ExtSalesOrderDetailComponent,
    ],
    providers: [
        ExternalSalesOrderService
    ],
    exports: [
        ExtSalesOrderListComponent,
        ExtSalesOrderDetailComponent,
    ],
    entryComponents: [
        ExtSalesOrderListComponent,
        ExtSalesOrderDetailComponent,
    ]
})
export class ExtSalesOrderModule { }