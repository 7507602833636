﻿import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';

import { UserVm } from '../../models'
import { UserService } from '../user.service';

@Component({
    selector: 'user-list',
    templateUrl: './user-list.component.html',
})
export class UserListComponent implements OnInit {
    list: UserVm[];

    constructor(
        private service: UserService,
        private router: Router
    ) {
    }

    async ngOnInit(): Promise<void> {
        this.list = await this.service.getListVm();
        //console.log(this.list);
    }

    navigateToEdit(id: number): void {
        this.router.navigate(['/user/edit/' + id])
    }
}