import { Component, ViewChild, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

import * as models from '../../models';
import { EventBaseComponent } from '../event-base/event-base.component';
import { EventsService } from '../events.service';

@Component({
    selector: 'event-json-form',
    templateUrl: './event-json-form.component.html'
})
export class EventJsonFormComponent extends EventBaseComponent {
    @ViewChild('formElement') formElement: FormGroup;

    @Input() jsonForm: models.JsonForm;

    constructor(public eventsService: EventsService) {
        super(eventsService);
    }

    ngOnInit(): void {
    }

    async execute(): Promise<void> {
        const data = new models.CreateBm({
            sn: this.model.sn,
            eventFlowId: this.model.customFlow.id,
            jsonForm: this.jsonForm
        });

        this.model.wait = true;

        await this.eventsService.create(data).catch(e => {
            this.model.wait = false;
            this.model.error = e.exceptionMessage || e.message || e;
        });

        if (!this.model.error)
            this.backToEvents();
    }

    getControlTypeName(type: models.Enums.JsonFormControlType): string {
        return models.Enums.JsonFormControlType[type];
    }

}