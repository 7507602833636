import { KeyValuePair } from '../models';

declare global {
    var d: {
        log: (...args: any[]) => void;
    };
}


window['d'] = {
    log: (...args: any[]): void => {
        console.log.call(null, ...args);
    }
};

export class Util {
    static generateUuid(): string {
        var d = new Date().getTime();
        if (window.performance && typeof window.performance.now === 'function') {
            d += performance.now();
        }
        var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c == "x" ? r : (r & 0x3 | 0x8)).toString(16);
        });
        return uuid;
    }

    static splitCamelCase(value): string {
        if (!value) return '';
        return value.replace(/([A-Z])/g, ' $1').trim();
    };

    static getSplittedEnumName(enumType: any, value: number) {
        return Util.splitCamelCase(enumType[value]);
    }

    static enumToKeyValuePairs(enumType: any, shouldSplitName: boolean = true): KeyValuePair<number, string>[] {
        const values = Object.keys(enumType).map(k => enumType[k]);
        const names = values.filter(v => typeof v === "string") as string[];
        return names.map(name => {
            return {
                key: enumType[name] as number,
                value: shouldSplitName ? Util.splitCamelCase(name) : name
            }
        });
    }
}
