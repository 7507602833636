import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule }   from '@angular/forms'

import { AlSnRegistrationComponent } from './sn-registration/component'
import { AlSnRegistrationService } from './sn-registration/service'

@NgModule({
  imports: [
    CommonModule,
    FormsModule
  ],
  declarations: [
    AlSnRegistrationComponent,
  ],
  providers: [
    AlSnRegistrationService
  ],
  exports: [
    AlSnRegistrationComponent,
  ],
  entryComponents: [
    AlSnRegistrationComponent,
  ]
})
export class AlienModule { }