﻿import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { EditUserGroupVm, UserExVm } from '../../models'
import { UserGroupService } from '../user-group.service'

@Component({
    selector: 'user-group-edit',
    templateUrl: './user-group-edit.component.html',
})
export class UserGroupEditComponent implements OnInit {
    @ViewChild('form') form: FormGroup;
    model: EditUserGroupVm;
    isBusy = false;

    constructor(
        private dataService: UserGroupService,
        private route: ActivatedRoute,
        private router: Router
    ) { }

    async ngOnInit(): Promise<void> {
        this.model = new EditUserGroupVm();
        this.model.users = new Array<UserExVm>();
        this.model.id = +this.route.snapshot.params['id'];
        this.load(this.model.id);
    }

    async load(id: number): Promise<void> {
        if (id) {
            this.model = await this.dataService.getEditVm(id);
        }
    }

    async submit(): Promise<void> {
        if (this.form.invalid) {
            return;
        }

        this.isBusy = true;
        const result = await this.dataService.save(this.model);
        this.isBusy = false;

        if (this.model.id) {
            this.navigateBack();
        } else {
            this.load(result.id);
        }
    }

    async delete(): Promise<void> {
        await this.dataService.delete(this.model.id);
        this.navigateBack();
    }

    async exclude(user: UserExVm): Promise<void> {
        await this.dataService.excludeUser({ userGroupId: this.model.id, userId: user.id });
        user.isIncluded = false;
    }

    async include(user: UserExVm): Promise<void> {
        await this.dataService.includeUser({ userGroupId: this.model.id, userId: user.id });
        user.isIncluded = true;
    }

    navigateBack(): void {
        this.router.navigate(['/userGroups'])
    }
}