﻿import { Response } from '@angular/http';

export class ResponseException {
    code: string;
    text: string
    fullMessage: string

    constructor(error) {
        if (error instanceof Response) {
            this.fullMessage = `${error.status} - ${error.statusText || ''} ${error.text()}`;
            this.code = error.statusText;
            this.text = error.text();
        } else {
            this.fullMessage = error.message ? error.message : error.toString();
        }
    }

    toString(): string {
        return this.fullMessage;
    }
}