import { Injectable } from '@angular/core'
import { Http } from '@angular/http'
import { BaseService } from '../core/services/base.service';

export
class BaseSaveShipRequestVm
{
	constructor (init?: Partial<BaseSaveShipRequestVm>)
	{
		Object.assign(this, init);
		
	}
	public id: number;
	public companyName: string;
	public contact: ContactVm;
	public shipAddress: AddressVm;
	public transportationPayment: PaymentInfoVm;
	public feePayment: PaymentInfoVm;
	public isRefurbished: boolean;
	public declaredValue: number;
	public comment: string;
	public deliverBy: Date;
	public shipServiceId: number;
	public productid: number;
	public numberOfPlates: number;
	public emailForNotification: string;
}
export
class BaseEditShipRequestVm
{
	constructor (init?: Partial<BaseEditShipRequestVm>)
	{
		Object.assign(this, init);
		
	}
	public isReadOnly: boolean;
	public entity: EditEntityShipRequestVm;
	public shipments: Shipment[];
	public shipServices: ShipServiceVm[];
	public countries: Country[];
	public statuses: KeyValuePair<number, string>[];
	public billTransportationToListItems: ListItem[];
	public billDutiesToListItems: ListItem[];
	public products: ShipmentRequestsProductVm[];
	public product: ProductVm;
	public productid: number;
}
export
class Error
{
	constructor (init?: Partial<Error>)
	{
		Object.assign(this, init);
		
	}
}
export
class UserGroup
{
	constructor (init?: Partial<UserGroup>)
	{
		Object.assign(this, init);
		
	}
	public id: number;
	public name: string;
	public users: User[];
	public isDeleted: boolean;
}
export
class ShipService
{
	constructor (init?: Partial<ShipService>)
	{
		Object.assign(this, init);
		
	}
	public id: number;
	public carrier: string;
	public name: string;
	public isDeleted: boolean;
}
export
class SoftwareRolePermission
{
	constructor (init?: Partial<SoftwareRolePermission>)
	{
		Object.assign(this, init);
		
	}
	public id: number;
	public softwareId: number;
	public software: any;
	public permissionType: Enums.PermissionType;
	public role: Enums.Role;
	public isDeleted: boolean;
}
export
class ConfigItem
{
	constructor (init?: Partial<ConfigItem>)
	{
		Object.assign(this, init);
		
	}
	public id: number;
	public key: string;
	public value: string;
	public isDeleted: boolean;
}
export
class AddressInfo
{
	constructor (init?: Partial<AddressInfo>)
	{
		Object.assign(this, init);
		
	}
	public streetAddress1: string;
	public streetAddress2: string;
	public streetAddress3: string;
	public zip: string;
	public city: string;
	public state: string;
	public country: string;
	public code: string;
	public phone: string;
}
export
class ListItem
{
	constructor (init?: Partial<ListItem>)
	{
		Object.assign(this, init);
		
	}
	public id: number;
	public isDeleted: boolean;
	public name: string;
	public value: string;
	public isDefault: boolean;
	public listType: any;
}
export
class Country
{
	constructor (init?: Partial<Country>)
	{
		Object.assign(this, init);
		
	}
	public id: number;
	public name: string;
	public sortNo: number;
	public isHome: boolean;
	public isDeleted: boolean;
	public code: string;
}
export
class User
{
	constructor (init?: Partial<User>)
	{
		Object.assign(this, init);
		
	}
	public id: number;
	public userName: string;
	public isAdmin: boolean;
	public fullName: string;
	public passwordHash: string;
	public apiToken: string;
	public role: Enums.Role;
	public account: Account;
	public accountId: number;
	public groups: UserGroup[];
	public userPropertyGroups: any[];
	public transactions: any[];
	public nickName: string;
	public isDisabled: boolean;
}
export
class PaymentInfo
{
	constructor (init?: Partial<PaymentInfo>)
	{
		Object.assign(this, init);
		
	}
	public payer: string;
	public accountNo: string;
}
export
class Shipment
{
	constructor (init?: Partial<Shipment>)
	{
		Object.assign(this, init);
		
	}
	public id: number;
	public changer: User;
	public changerId: number;
	public changeDate: Date;
	public shipDate: Date;
	public serialNumber: string;
	public masterTrackingNumber: string;
	public deviceTrackingNumber: string;
	public trackingNumber: string;
	public request: any;
	public requestId: number;
	public isDeleted: boolean;
}
export
class ShipRequestPredefinedData
{
	constructor (init?: Partial<ShipRequestPredefinedData>)
	{
		Object.assign(this, init);
		
	}
	public id: number;
	public isDeleted: boolean;
	public keyName: string;
	public companyName: string;
	public contact: ContactInfo;
	public shipAddress: AddressInfo;
	public shipServiceId: number;
	public transportationPayment: PaymentInfo;
	public feePayment: PaymentInfo;
	public emailForNotification: string;
}
export
class JsonForm
{
	constructor (init?: Partial<JsonForm>)
	{
		Object.assign(this, init);
		
	}
	public Title: string;
	public ActionButtonName: string;
	public Controls: JsonFormControl[];
}
export
class JsonFormControl
{
	constructor (init?: Partial<JsonFormControl>)
	{
		Object.assign(this, init);
		
	}
	public Caption: string;
	public Type: Enums.JsonFormControlType;
	public Property: string;
	public Value: string;
}
export
class Account
{
	constructor (init?: Partial<Account>)
	{
		Object.assign(this, init);
		
	}
	public phoneNumber: string;
	public phoneExtension: string;
	public email: string;
	public job: string;
	public id: number;
	public addressInfo: AddressInfo;
	public companyName: string;
	public isDeleted: boolean;
}
export
class ContactInfo
{
	constructor (init?: Partial<ContactInfo>)
	{
		Object.assign(this, init);
		
	}
	public name: string;
	public phoneNumber: string;
	public phoneExtension: string;
	public email: string;
	public job: string;
}

export
class EditProductVm
{
	constructor (init?: Partial<EditProductVm>)
	{
		Object.assign(this, init);
		
	}
	public name: string;
	public modelNo: string;
	public id: number;
	public prodGroup: string;
	public description: string;
	public isDeleted: boolean;
}
export
class ProductVm
{
	constructor (init?: Partial<ProductVm>)
	{
		Object.assign(this, init);
		
	}
	public name: string;
	public description: string;
	public modelNo: string;
	public group: string;
	public isDeleted: boolean;
	public id: number;
}
export
class UserAccountVm
{
	constructor (init?: Partial<UserAccountVm>)
	{
		Object.assign(this, init);
		
	}
	public id: number;
	public name: string;
}
export
class EditUserVm
{
	constructor (init?: Partial<EditUserVm>)
	{
		Object.assign(this, init);
	}
	public nickName: string;
	public password: string;
	public isDisabled: boolean;
	public fullName: string;
	public userName: string;
	public role: Enums.Role;
	public id: number;
	public accounts: UserAccountVm[];
	public accountId: number;
}

export
class GenerateSetPasswordKeyVm
{
	constructor (init?: Partial<GenerateSetPasswordKeyVm>)
	{
		Object.assign(this, init);
		
	}
	public id: number;
}
export
class UserVm
{
	constructor (init?: Partial<UserVm>)
	{
		Object.assign(this, init);
		
	}
	public nickName: string;
	public fullName: string;
	public userName: string;
	public role: Enums.Role;
	public id: number;
	public account: string;
	public isActive: boolean;
}
export
class EditUserGroupVm
{
	constructor (init?: Partial<EditUserGroupVm>)
	{
		Object.assign(this, init);
		
	}
	public name: string;
	public users: UserExVm[];
	public id: number;
}
export
class UserGroupUserVm
{
	constructor (init?: Partial<UserGroupUserVm>)
	{
		Object.assign(this, init);
		
	}
	public userId: number;
	public userGroupId: number;
}
export
class UserGroupVm
{
	constructor (init?: Partial<UserGroupVm>)
	{
		Object.assign(this, init);
		
	}
	public name: string;
	public userNames: string;
	public id: number;
}
export
class UserExVm
{
	constructor (init?: Partial<UserExVm>)
	{
		Object.assign(this, init);
		
	}
	public name: string;
	public userName: string;
	public role: Enums.Role;
	public isIncluded: boolean;
	public id: number;
}
export
class SnPropertyVm
{
	constructor (init?: Partial<SnPropertyVm>)
	{
		Object.assign(this, init);
		
	}
	public id: number;
	public name: string;
}
export
class ListItemVm
{
	constructor (init?: Partial<ListItemVm>)
	{
		Object.assign(this, init);
		
	}
	public id: number;
	public registrationDate: Date;
	public serialNumber: string;
	public company: string;
	public contactInfo: ContactInfo;
	public addressInfo: AddressInfo;
	public total: number;
}
export
class GetEditArgs
{
	constructor (init?: Partial<GetEditArgs>)
	{
		Object.assign(this, init);
		
	}
	public shipmentId: number;
}
export
class KeyValuePair<TKey, TValue>
{
	constructor (init?: Partial<KeyValuePair<TKey,TValue>>)
	{
		Object.assign(this, init);
		
	}
	public key: TKey;
	public value: TValue;
}
export
class HistoryItemVm
{
	constructor (init?: Partial<HistoryItemVm>)
	{
		Object.assign(this, init);
		
	}
	public values: ValueVm[];
	public userName: string;
	public eventName: string;
	public date: Date;
}
export
class RmaOrderNewVm
{
	constructor (init?: Partial<RmaOrderNewVm>)
	{
		Object.assign(this, init);
		
	}
	public no: string;
	public note: string;
	public reason: string;
	public dueDate: Date;
	public sn: string;
	public id: number;
}
export
class SnDetailVm
{
	constructor (init?: Partial<SnDetailVm>)
	{
		Object.assign(this, init);
		
	}
	public id: number;
	public values: ValueVm[];
	public sn: string;
}
export
class SoftwareFileVm
{
	constructor (init?: Partial<SoftwareFileVm>)
	{
		Object.assign(this, init);
		
	}
	public name: string;
	public id: number;
}
export
class SoftwareVersionVm
{
	constructor (init?: Partial<SoftwareVersionVm>)
	{
		Object.assign(this, init);
		
	}
	public id: number;
	public software: SoftwareVm;
	public no: string;
	public description: string;
	public releaseDate: Date;
	public support: boolean;
	public files: SoftwareFileVm[];
	public url: string;
}
@Injectable()
export
class SoftwareService extends BaseService
{
	constructor (http: Http)
	{
		super(http);
	}
	public getListVm() : Promise<SoftwareVm[]>
	{
		const uriParams = {  };
		return this.get<SoftwareVm[]>(this.baseUri + `GetListVm`, uriParams);
	}
	public getEditVm(id: number) : Promise<EditSoftwareVm>
	{
		const uriParams = {  };
		return this.get<EditSoftwareVm>(this.baseUri + `GetEditVm/${id}`, uriParams);
	}
	public save(model: EditSoftwareVm) : Promise<SaveResult>
	{
		const uriParams = {  };
		return this.post<SaveResult>(this.baseUri + `Save`, model, uriParams);
	}
	private baseUri: string = 'software/';
}
export
class SoftwareVm
{
	constructor (init?: Partial<SoftwareVm>)
	{
		Object.assign(this, init);
		
	}
	public name: string;
	public code: string;
	public id: number;
	public url: string;
}
@Injectable()
export
class PropertyService extends BaseService
{
	constructor (http: Http)
	{
		super(http);
	}
	public getList() : Promise<PropertiesPropertyVm[]>
	{
		const uriParams = {  };
		return this.get<PropertiesPropertyVm[]>(this.baseUri + `list`, uriParams);
	}
	public saveProperties(items: PropertiesPropertyVm[]) : Promise<SaveResult>
	{
		const uriParams = {  };
		return this.post<SaveResult>(this.baseUri + `save-properties`, items, uriParams);
	}
	private baseUri: string = 'property/';
}
export
class PropertiesPropertyVm
{
	constructor (init?: Partial<PropertiesPropertyVm>)
	{
		Object.assign(this, init);
		
	}
	public id: number;
	public name: string;
	public code: string;
	public type: Enums.PropertyType;
	public location: Enums.PropertyLocation;
	public isExternal: boolean;
	public isExternalVisible: boolean;
}
@Injectable()
export
class ProfileService extends BaseService
{
	constructor (http: Http)
	{
		super(http);
	}
	public save(model: SaveProfileVm) : Promise<any>
	{
		const uriParams = {  };
		return this.post<any>(this.baseUri + `/Save`, model, uriParams);
	}
	private baseUri: string = 'profile';
}
export
class SaveProfileVm
{
	constructor (init?: Partial<SaveProfileVm>)
	{
		Object.assign(this, init);
		
	}
	public password: string;
}
export
class FilterVm
{
	constructor (init?: Partial<FilterVm>)
	{
		Object.assign(this, init);
	}
	public createDateFrom: Date;
	public createDateTo: Date;
	public isVisibleToClientOnly: boolean;
	public productType: number;
	public status: string;
	public user: number;
}
export
class SnRegistrationFilterVm
{
	constructor (init?: Partial<SnRegistrationFilterVm>)
	{
		Object.assign(this, init);
	}
	public serialNumber: string;
	public filter: string;
}
export
class CustomerVm
{
	constructor (init?: Partial<CustomerVm>)
	{
		Object.assign(this, init);
		
	}
	public id: number;
	public companyName: string;
}
export
class SnStatusStatisticVm
{
	constructor (init?: Partial<SnStatusStatisticVm>)
	{
		Object.assign(this, init);
		
	}
	public statusName: string;
	public count: number;
}
export
class DetailProductionRequestVm
{
	constructor (init?: Partial<DetailProductionRequestVm>)
	{
		Object.assign(this, init);
		
	}
	public account: string;
	public po: string;
	public no: string;
	public quantity: number;
	public createDate: Date;
	public dueDate: Date;
	public userName: string;
	public product: string;
	public status: Enums.ProductionRequestStatus;
	public sns: SnDetailsVm[];
	public id: number;
	public isVisibleToClient: boolean;
	public comment: string;
}
export
class DetailSalesOrderVm
{
	constructor (init?: Partial<DetailSalesOrderVm>)
	{
		Object.assign(this, init);
		
	}
	public account: string;
	public po: string;
	public no: string;
	public quantity: number;
	public createDate: Date;
	public dueDate: Date;
	public userName: string;
	public product: string;
	public status: Enums.SalesOrderStatus;
	public sns: SnDetailsVm[];
	public id: number;
	public isVisibleToClient: boolean;
	public comment: string;
	public shipped: number;
}
@Injectable()
export
class PermissionService extends BaseService
{
	constructor (http: Http)
	{
		super(http);
	}
	public getSoftwarePermissionsVm() : Promise<SoftwarePermissionsVm>
	{
		const uriParams = {  };
		return this.get<SoftwarePermissionsVm>(this.baseUri + `SoftwarePermissionsVm`, uriParams);
	}
	public saveSoftwarePermissionsVm(newPermissions: SoftwareRolePermissionVm[]) : Promise<any>
	{
		const uriParams = {  };
		return this.post<any>(this.baseUri + `SoftwarePermissionsVm`, newPermissions, uriParams);
	}
	private baseUri: string = 'permissions/';
}
export
class SoftwarePermissionsVm
{
	constructor (init?: Partial<SoftwarePermissionsVm>)
	{
		Object.assign(this, init);
		
	}
	public permissions: SoftwareRolePermissionVm[];
	public softwares: SoftwareVm[];
	public roles: Enums.Role[];
}
export
class SoftwareRolePermissionVm
{
	constructor (init?: Partial<SoftwareRolePermissionVm>)
	{
		Object.assign(this, init);
		
	}
	public id: number;
	public permissionType: Enums.PermissionType;
	public role: Enums.Role;
	public softwareId: number;
}
export
class FlowDebugVm
{
	constructor (init?: Partial<FlowDebugVm>)
	{
		Object.assign(this, init);
		
	}
	public noSn: boolean;
	public flows: FilterFlowVm[];
}
export
class GetFlowDebugArgs
{
	constructor (init?: Partial<GetFlowDebugArgs>)
	{
		Object.assign(this, init);
		
	}
	public serialNumber: string;
}
export
class SendEmailBm
{
	constructor (init?: Partial<SendEmailBm>)
	{
		Object.assign(this, init);
		
	}
	public sn: string;
	public receipient: string;
	public subject: string;
	public body: string;
}
export
class AdminUpdateVm
{
	constructor (init?: Partial<AdminUpdateVm>)
	{
		Object.assign(this, init);
		
	}
	public propertyValues: ValueVm[];
}
export
class ChangeAccountVm
{
	constructor (init?: Partial<ChangeAccountVm>)
	{
		Object.assign(this, init);
		
	}
	public sn: string;
	public accountId: number;
	public comment: string;
}
export
class DoShippedBm
{
	constructor (init?: Partial<DoShippedBm>)
	{
		Object.assign(this, init);
		
	}
	public sn: string;
	public oldSn: string;
	public shouldReplaceSn: boolean;
	public comment: string;
	public shipDate: Date;
	public masterTrackingNumber: string;
	public deviceTrackingNumber: string;
}
export
class EventFlowVm
{
	constructor (init?: Partial<EventFlowVm>)
	{
		Object.assign(this, init);
		
	}
	public name: string;
	public commentHint: string;
	public code: string;
	public commentIsRequired: boolean;
	public id: number;
	public jsonForm: string;
}
export
class ShippedVm
{
	constructor (init?: Partial<ShippedVm>)
	{
		Object.assign(this, init);
		
	}
	public replacementSnList: string[];
	public shipDate: Date;
	public masterTrackingNumber: string;
	public deviceTrackingNumber: string;
}
export
class StartAssemblyBm
{
	constructor (init?: Partial<StartAssemblyBm>)
	{
		Object.assign(this, init);
		
	}
	public sn: string;
	public productId: number;
	public comment: string;
}
export
class UploadFileVm
{
	constructor (init?: Partial<UploadFileVm>)
	{
		Object.assign(this, init);
		
	}
	public fileName: string;
	public sn: string;
	public comment: string;
	public content: string;
}
export
class HistoryVm
{
	constructor (init?: Partial<HistoryVm>)
	{
		Object.assign(this, init);
		
	}
	public values: ValueVm[];
	public userName: string;
	public eventName: string;
	public date: Date;
	public isDeleted: boolean;
}
export
class SnDetailsVm
{
	constructor (init?: Partial<SnDetailsVm>)
	{
		Object.assign(this, init);
		
	}
	public values: ValueVm[];
	public history: HistoryVm[];
	public sn: string;
}
export
class SnVm
{
	constructor (init?: Partial<SnVm>)
	{
		Object.assign(this, init);
		
	}
	public id: number;
	public sn: string;
}
export
class DashboardHistoryVm
{
	constructor (init?: Partial<DashboardHistoryVm>)
	{
		Object.assign(this, init);
		
	}
	public values: ValueVm[];
	public userName: string;
	public eventName: string;
	public date: Date;
}
export
class DashboardListVm
{
	constructor (init?: Partial<DashboardListVm>)
	{
		Object.assign(this, init);
		
	}
	public shipRequests: DashboardShipRequestVm[];
	public events: DashboardRecentEventVm[];
	public productionRequests: DashboardProductionRequestVm[];
	public salesOrders: DashboardSalesOrderVm[];
}
export
class DashboardHistoryListVm
{
	constructor (init?: Partial<DashboardHistoryListVm>)
	{
		Object.assign(this, init);
		
	}
	public events: DashboardRecentEventVm[];
}
export
class DashboardProductionRequestVm
{
	constructor (init?: Partial<DashboardProductionRequestVm>)
	{
		Object.assign(this, init);
		
	}
	public po: string;
	public dueDate: Date;
	public productName: string;
	public status: Enums.ProductionRequestStatus;
	public id: number;
	public quantity: number;
	public sns: number;
}
export
class DashboardSalesOrderVm
{
	constructor (init?: Partial<DashboardSalesOrderVm>)
	{
		Object.assign(this, init);
		
	}
	public po: string;
	public dueDate: Date;
	public productName: string;
	public status: Enums.SalesOrderStatus;
	public id: number;
	public quantity: number;
	public sns: number;
	public shipped: number;
}
export
class DashboardShipRequestVm
{
	constructor (init?: Partial<DashboardShipRequestVm>)
	{
		Object.assign(this, init);
		
	}
	public requestDate: Date;
	public accountName: string;
	public id: number;
	public requiredCount: number;
	public addedCount: number;
	public existedCount: number;
	public shipTo: string;
	public name: string;
	public email: string;
	public country: string;
}
export
class DashboardRecentEventVm
{
	constructor (init?: Partial<DashboardRecentEventVm>)
	{
		Object.assign(this, init);
		
	}
	public serialNumber: string;
	public history: DashboardHistoryVm;
}
@Injectable()
export
class DashboardService extends BaseService
{
	constructor (http: Http)
	{
		super(http);
	}
	public getListVm() : Promise<DashboardListVm>
	{
		const uriParams = {  };
		return this.get<DashboardListVm>(this.baseUri + `getListVm`, uriParams);
	}
	public getHistoryListVm() : Promise<DashboardHistoryListVm>
	{
		const uriParams = {  };
		return this.get<DashboardListVm>(this.baseUri + `getHistoryListVm`, uriParams);
	}
	private baseUri: string = 'dashboard/';
}
@Injectable()
export
class ConfigItemService extends BaseService
{
	constructor (http: Http)
	{
		super(http);
	}
	public getListVm() : Promise<ConfigItemVm[]>
	{
		const uriParams = {  };
		return this.get<ConfigItemVm[]>(this.baseUri + `getListVm`, uriParams);
	}
	public save(items: ConfigItemVm[]) : Promise<SaveResult>
	{
		const uriParams = {  };
		return this.post<SaveResult>(this.baseUri + `save`, items, uriParams);
	}
	private baseUri: string = 'config-items/';
}
export
class ConfigItemVm
{
	constructor (init?: Partial<ConfigItemVm>)
	{
		Object.assign(this, init);
		
	}
	public id: number;
	public key: string;
	public value: string;
}
export
class ShipServiceVm
{
	constructor (init?: Partial<ShipServiceVm>)
	{
		Object.assign(this, init);
		
	}
	public carrier: string;
	public isDomestic: boolean;
	public services: KeyValuePair<number, string>[];
}
@Injectable()
export
class AnonymousSnRegistrationApiService extends BaseService
{
	constructor (http: Http)
	{
		super(http);
	}
	public getEditVm() : Promise<AnonymousEditVm>
	{
		const uriParams = {  };
		return this.get<AnonymousEditVm>(this.baseUri + `/api/AnonymousSnRegistration/GetEditVm`, uriParams);
	}
	public save(vm: AnonymousRegisterSnVm) : Promise<string>
	{
		const uriParams = {  };
		return this.post<string>(this.baseUri + `/api/AnonymousSnRegistration/Save`, vm, uriParams);
	}
	private baseUri: string = '';
}
export
class ExternalShipRequestVm
{
	constructor (init?: Partial<ExternalShipRequestVm>)
	{
		Object.assign(this, init);
		
	}
	public requestDate: Date;
	public id: number;
	public existedCount: number;
	public requiredCount: number;
	public shipTo: string;
	public name: string;
	public email: string;
	public country: string;
}
export
class ExternalSaveShipRequestVm extends BaseSaveShipRequestVm
{
	constructor (init?: Partial<ExternalSaveShipRequestVm>)
	{
		super(init);
		Object.assign(this, init);
		
	}
}
export
class EditAccountVm
{
	constructor (init?: Partial<EditAccountVm>)
	{
		Object.assign(this, init);
		
	}
	public addressInfo: AddressVm;
	public companyName: string;
	public job: string;
	public email: string;
	public phoneExtension: string;
	public phoneNumber: string;
	public id: number;
}
export
class AccountVm
{
	constructor (init?: Partial<AccountVm>)
	{
		Object.assign(this, init);
		
	}
	public phoneNumber: string;
	public phoneExtension: string;
	public email: string;
	public job: string;
	public addressInfo: AddressVm;
	public companyName: string;
	public id: number;
}
export
class SoftwareVersionFilter
{
	constructor (init?: Partial<SoftwareVersionFilter>)
	{
		Object.assign(this, init);
		
	}
	public softwareId: number;
	public limit: number;
	public support: Enums.BoolFilterEnum;
}
export
class EditSoftwareVm
{
	constructor (init?: Partial<EditSoftwareVm>)
	{
		Object.assign(this, init);
		
	}
	public name: string;
	public code: string;
	public id: number;
	public url: string;
}
export
class SnRegistrationsAccountVm
{
	constructor (init?: Partial<SnRegistrationsAccountVm>)
	{
		Object.assign(this, init);
		
	}
	public id: number;
	public name: string;
}
export
class SaveVm
{
	constructor (init?: Partial<SaveVm>)
	{
		Object.assign(this, init);
		
	}
	public id: number;
	public contactId: number;
	public accountId: number;
	public serialNumber: string;
	public company: string;
	public purchaseDate: Date;
	public placeOfPurchase: string;
	public address: AddressVm;
}
export
class ExternalProfileContactVm
{
	constructor (init?: Partial<ExternalProfileContactVm>)
	{
		Object.assign(this, init);
		
	}
	public name: string;
	public phoneNumber: string;
	public phoneExtension: string;
	public email: string;
	public job: string;
}
export
class ExternalProfileEditVm
{
	constructor (init?: Partial<ExternalProfileEditVm>)
	{
		Object.assign(this, init);
		
	}
	public company: string;
	public apiToken: string;
	public contact: ExternalProfileContactVm;
	public address: AddressVm;
	public password: string;
}
export
class ExternalProfileSaveVm
{
	constructor (init?: Partial<ExternalProfileSaveVm>)
	{
		Object.assign(this, init);
		
	}
	public company: string;
	public apiToken: string;
	public contact: ExternalProfileContactVm;
	public address: AddressInfo;
	public password: string;
}
export
class SaveUserPropertyGroupVm
{
	constructor (init?: Partial<SaveUserPropertyGroupVm>)
	{
		Object.assign(this, init);
		
	}
	public id: number;
	public name: string;
	public category: string;
	public isVisible: boolean;
	public isCollapsed: boolean;
}
@Injectable()
export
class UserPropertyGroupService extends BaseService
{
	constructor (http: Http)
	{
		super(http);
	}
	public getList(category: string) : Promise<UserPropertyGroupVm[]>
	{
		const uriParams = { category: category };
		return this.get<UserPropertyGroupVm[]>(this.baseUri + `list`, uriParams);
	}
	public saveGroup(model: SaveUserPropertyGroupVm) : Promise<SaveResult>
	{
		const uriParams = {  };
		return this.post<SaveResult>(this.baseUri + `save-group`, model, uriParams);
	}
	public deleteGroup(groupdId: number) : Promise<SaveResult>
	{
		const uriParams = { groupdId: groupdId };
		return this.post<SaveResult>(this.baseUri + `delete-group`, null, uriParams);
	}
	public changeGroup(propertyId: number, groupdId: number) : Promise<SaveResult>
	{
		const uriParams = { propertyId: propertyId, groupdId: groupdId };
		return this.post<SaveResult>(this.baseUri + `change-property-group`, null, uriParams);
	}
	private baseUri: string = 'user-property-group/';
}
export
class PropertySave
{
	constructor (init?: Partial<PropertySave>)
	{
		Object.assign(this, init);
		
	}
	public id: number;
	public code: string;
	public name: string;
	public group: string;
	public type: Enums.PropertyType;
	public location: Enums.PropertyLocation;
	public isDeleted: boolean;
}
export
class PropertyVm
{
	constructor (init?: Partial<PropertyVm>)
	{
		Object.assign(this, init);
		
	}
	public id: number;
	public name: string;
	public code: string;
	public type: Enums.PropertyType;
	public location: Enums.PropertyLocation;
}
export
class UserPropertyGroupVm
{
	constructor (init?: Partial<UserPropertyGroupVm>)
	{
		Object.assign(this, init);
		
	}
	public id: number;
	public name: string;
	public category: string;
	public isVisible: boolean;
	public isCollapsed: boolean;
	public properties: PropertyVm[];
}
export
class ShipRequestsFilterVm
{
	constructor (init?: Partial<ShipRequestsFilterVm>)
	{
		Object.assign(this, init);
		
	}
	public createDateFrom: string;
    public createDateTo: string;
	public status: Enums.ShipRequestStatus;
	public accountId: number;
	public serialNumber: string;
}
export
class ShipRequestListVm
{
	constructor (init?: Partial<ShipRequestListVm>)
	{
		Object.assign(this, init);
		
	}
	public shipRequests: ShipRequestVm[];
	public accounts: ShipRequestsAccountVm[];
}
export
class ShipRequestsAccountVm
{
	constructor (init?: Partial<ShipRequestsAccountVm>)
	{
		Object.assign(this, init);
		
	}
	public name: string;
	public id: number;
}
export
class CornerTypeVm
{
	constructor (init?: Partial<CornerTypeVm>)
	{
		Object.assign(this, init);
		
	}
	public id: number;
	public name: string;
}
export
class ConnTypeVm
{
	constructor (init?: Partial<ConnTypeVm>)
	{
		Object.assign(this, init);
		
	}
	public id: number;
	public name: string;
}
export
class InsertTypeVm
{
	constructor (init?: Partial<InsertTypeVm>)
	{
		Object.assign(this, init);
		
	}
	public id: number;
	public name: string;
	public accountId: number;
}
export
class EditShipRequestVm extends BaseEditShipRequestVm
{
    //product: ProductVm;
	constructor (init?: Partial<EditShipRequestVm>)
	{
		super(init);
		Object.assign(this, init);
		
	}
	public rmaOrderId: number;
	public accounts: ShipRequestsAccountVm[];
	public cornerTypes: CornerTypeVm[];
	public insertTypes: InsertTypeVm[];
	public connTypes: ConnTypeVm[];
}
export
class SaveShipRequestPredefinedDataVm extends BaseSaveShipRequestVm
{
	constructor (init?: Partial<SaveShipRequestPredefinedDataVm>)
	{
		super(init);
		Object.assign(this, init);
		
	}
	public keyName: string;
}
export
class SnListVm
{
	constructor (init?: Partial<SnListVm>)
	{
		Object.assign(this, init);
		
	}
	public properties: SnPropertyVm[];
	public totalSnItemsCount: number;
	public snItems: SnItemVm[];
	public snItems2: SnItemVm[];
	public products: ProductVm[];
}
export
class SnListNewVm
{
	constructor (init?: Partial<SnListNewVm>)
	{
		Object.assign(this, init);
		
	}
	//public properties: SnPropertyVm[];
	public totalSnItemsCount: number;
	//public snItems: SnItemVm[];
	public snItems: SnItemNewVm[];
	//public snItems2: SnItemVm[];
	public productList: ShipmentRequestsProductVm[];
}
export
class SaveShipRequestVm extends BaseSaveShipRequestVm
{
	constructor (init?: Partial<SaveShipRequestVm>)
	{
		super(init);
		Object.assign(this, init);
		
	}
	public rmaOrderId: number;
	public accountId: number;
	public isNew: boolean;
}
export
class ShipRequestVm
{
	constructor (init?: Partial<ShipRequestVm>)
	{
		Object.assign(this, init);
		
	}
	public requestDate: Date;
	public status: Enums.ShipRequestStatus;
	public isRefurbished: boolean;
	public creatorName: string;
	public accountName: string;
	public id: number;
	public existedCount: number;
	public requiredCount: number;
	public shipTo: string;
	public name: string;
	public email: string;
	public country: string;
}
export
class LogRecordVm
{
	constructor (init?: Partial<LogRecordVm>)
	{
		Object.assign(this, init);
		
	}
	public user: string;
	public timestamp: string;
	public message: string;
	public stackTrace: string;
	public severity: Enums.LogSeverity;
	public version: string;
}
export
class ShipmentRequestsProductVm
{
	constructor (init?: Partial<ShipmentRequestsProductVm>)
	{
		Object.assign(this, init);
		
	}
	public id: number;
	public modelNo: string;
	//public name: string;
}
export
class ProductionRequestsProductVm
{
	constructor (init?: Partial<ProductionRequestsProductVm>)
	{
		Object.assign(this, init);
		
	}
	public id: number;
	public modelNo: string;
}
export
class SerialNumberProductVm
{
	constructor (init?: Partial<SerialNumberProductVm>)
	{
		Object.assign(this, init);
		
	}
	public id: number;
	public modelNo: string;
}
@Injectable()
export
class ProductionRequestService extends BaseService
{
	constructor (http: Http)
	{
		super(http);
	}
	public getListVm(vm: FilterVm) : Promise<ProductionRequestListVm>
	{
		const uriParams = {  };
		return this.post<ProductionRequestListVm>(this.baseUri + `GetListVm`, vm, uriParams);
	}
	public getNewVm() : Promise<ProductionRequestNewVm>
	{
		const uriParams = {  };
		return this.get<ProductionRequestNewVm>(this.baseUri + `GetNewVm`, uriParams);
	}
	public getDetailVm(id: number) : Promise<DetailProductionRequestVm>
	{
		const uriParams = {  };
		return this.get<DetailProductionRequestVm>(this.baseUri + `GetDetailVm/${id}`, uriParams);
	}
	public save(model: ProductionRequestNewVm) : Promise<SaveResult>
	{
		const uriParams = {  };
		return this.post<SaveResult>(this.baseUri + `Save`, model, uriParams);
	}
	public setVisibleToClient(prductionRequestId: number, IsVisibleToClient: boolean) : Promise<SaveResult>
	{
		const uriParams = { prductionRequestId: prductionRequestId, IsVisibleToClient: IsVisibleToClient };
		return this.post<SaveResult>(this.baseUri + `VisibleToClient`, null, uriParams);
	}
	public setStatus(id: number, status: Enums.ProductionRequestStatus) : Promise<SaveResult>
	{
		const uriParams = { id: id, status: status };
		return this.post<SaveResult>(this.baseUri + `status`, null, uriParams);
	}
	public delete(id: number) : Promise<SaveResult>
	{
		const uriParams = {  };
		return this.post<SaveResult>(this.baseUri + `Delete/${id}`, null, uriParams);
	}
	public export(id: number, propertiesToExport: string[]) : Promise<void>
	{
		const uriParams = {  };
		return this.upload<void>(this.baseUri + `Export/${id}`, propertiesToExport, uriParams);
	}
	private baseUri: string = 'productionRequest/';
}
export
class ProductionRequestNewVm
{
	constructor (init?: Partial<ProductionRequestNewVm>)
	{
		Object.assign(this, init);
		
	}
	public po: string;
	public dueDate: Date;
	public quantity: number;
	public productId: number;
	public products: ProductionRequestsProductVm[];
	public customerId: number;
	public customers: CustomerVm[];
	public isVisibleToClient: boolean;
	public comment: string;
}
export
class ProductionRequestVm
{
	constructor (init?: Partial<ProductionRequestVm>)
	{
		Object.assign(this, init);
		
	}
	public po: string;
	public no: string;
	public quantity: number;
	public createDate: Date;
	public dueDate: Date;
	public userName: string;
	public productName: string;
	public status: Enums.ProductionRequestStatus;
	public statistic: SnStatusStatisticVm[];
	public isVisibleToClient: boolean;
	public id: number;
}
export
class ProductionRequestListVm
{
	constructor (init?: Partial<ProductionRequestListVm>)
		{
			Object.assign(this, init);
		}
		public requests: ProductionRequestVm[];
		public products: ProductionRequestsProductVm[];
		public users: UserVm[];
}
// Sales Order Begin
export
class SalesOrdersProductVm
{
	constructor (init?: Partial<SalesOrdersProductVm>)
	{
		Object.assign(this, init);
		
	}
	public id: number;
	public modelNo: string;
}
@Injectable()
export
class SalesOrderService extends BaseService
{
	constructor (http: Http)
	{
		super(http);
	}
	public getListVm(vm: FilterVm) : Promise<SalesOrderListVm>
	{
		const uriParams = {  };
		return this.post<SalesOrderListVm>(this.baseUri + `GetListVm`, vm, uriParams);
	}
	public getNewVm() : Promise<SalesOrderNewVm>
	{
		const uriParams = {  };
		return this.get<SalesOrderNewVm>(this.baseUri + `GetNewVm`, uriParams);
	}
	public getDetailVm(id: number) : Promise<DetailSalesOrderVm>
	{
		const uriParams = {  };
		return this.get<DetailSalesOrderVm>(this.baseUri + `GetDetailVm/${id}`, uriParams);
	}
	public save(model: SalesOrderNewVm) : Promise<SaveResult>
	{
		const uriParams = {  };
		return this.post<SaveResult>(this.baseUri + `Save`, model, uriParams);
	}
	public setVisibleToClient(salesOrdertId: number, IsVisibleToClient: boolean) : Promise<SaveResult>
	{
		const uriParams = { salesOrdertId: salesOrdertId, IsVisibleToClient: IsVisibleToClient };
		return this.post<SaveResult>(this.baseUri + `VisibleToClient`, null, uriParams);
	}
	public setStatus(id: number, status: Enums.SalesOrderStatus) : Promise<SaveResult>
	{
		const uriParams = { id: id, status: status };
		return this.post<SaveResult>(this.baseUri + `status`, null, uriParams);
	}
	public delete(id: number) : Promise<SaveResult>
	{
		const uriParams = {  };
		return this.post<SaveResult>(this.baseUri + `Delete/${id}`, null, uriParams);
	}
	public export(id: number, propertiesToExport: string[]) : Promise<void>
	{
		const uriParams = {  };
		return this.upload<void>(this.baseUri + `Export/${id}`, propertiesToExport, uriParams);
	}
	private baseUri: string = 'SalesOrder/';
}
export
class SalesOrderNewVm
{
	constructor (init?: Partial<SalesOrderNewVm>)
	{
		Object.assign(this, init);
		
	}
	public po: string;
	public dueDate: Date;
	public quantity: number;
	public productId: number;
	public products: SalesOrdersProductVm[];
	public customerId: number;
	public customers: CustomerVm[];
	public isVisibleToClient: boolean;
	public comment: string;
	public id: number = 0;
}
export
class SalesOrderVm
{
	constructor (init?: Partial<SalesOrderVm>)
	{
		Object.assign(this, init);
		
	}
	public po: string;
	public no: string;
	public quantity: number;
	public createDate: Date;
	public accountName: string;
	public dueDate: Date;
	public userName: string;
	public productName: string;
	public status: Enums.SalesOrderStatus;
	public statistic: SnStatusStatisticVm[];
	public isVisibleToClient: boolean;
	public id: number;
}
export
class SalesOrderListVm
{
	constructor (init?: Partial<SalesOrderListVm>)
		{
			Object.assign(this, init);
		}
		public salesOrders: SalesOrderVm[];
		public products: SalesOrdersProductVm[];
		public users: UserVm[];
}
// Sales Order End
export
class FilterFlowDetailVm
{
	constructor (init?: Partial<FilterFlowDetailVm>)
	{
		Object.assign(this, init);
		
	}
	public verdict: boolean;
	public actualValue: string;
	public flowValue: string;
	public key: string;
}
export
class FilterFlowVm
{
	constructor (init?: Partial<FilterFlowVm>)
	{
		Object.assign(this, init);
		
	}
	public details: FilterFlowDetailVm[];
	public flowName: string;
	public flowCode: string;
}
@Injectable()
export
class FlowDebugService extends BaseService
{
	constructor (http: Http)
	{
		super(http);
	}
	public getVm(sn: string) : Promise<FlowDebugVm>
	{
		const uriParams = { sn: sn };
		return this.get<FlowDebugVm>(this.baseUri + `getVm`, uriParams);
	}
	private baseUri: string = 'FlowDebug/';
}
export
class AdminUpdateBm
{
	constructor (init?: Partial<AdminUpdateBm>)
	{
		Object.assign(this, init);
		
	}
	public sn: string;
	public propertyValues: ValueVm[];
	public comment: string;
}
export
class ChangeProductBm
{
	constructor (init?: Partial<ChangeProductBm>)
	{
		Object.assign(this, init);
		
	}
	public sn: string;
	public productId: number;
	public comment: string;
}
export
class IssueRmaBm
{
	constructor (init?: Partial<IssueRmaBm>)
	{
		Object.assign(this, init);
		
	}
	public sn: string;
	public comment: string;
	public rmaNo: string;
	public reason: string;
	public dueDate: Date;
}
export
class AddToShipRequestBm
{
	constructor (init?: Partial<AddToShipRequestBm>)
	{
		Object.assign(this, init);
		
	}
	public sn: string;
	public shipRequestId: number;
	public comment: string;
}
export
class AddToProductionsOrderBm
{
	constructor (init?: Partial<AddToProductionsOrderBm>)
	{
		Object.assign(this, init);
		
	}
	public sn: string;
	public orderId: number;
	public comment: string;
}
export
class AddToSalesOrderBm
{
	constructor (init?: Partial<AddToSalesOrderBm>)
	{
		Object.assign(this, init);
		
	}
	public sn: string;
	public orderId: number;
	public comment: string;
}
export
class AddToPrimaryBm
{
	constructor (init?: Partial<AddToPrimaryBm>)
	{
		Object.assign(this, init);
		
	}
	public sn: string;
	public linkedSnId: number;
	public comment: string;
}
export
class AddToSecondaryBm
{
	constructor (init?: Partial<AddToSecondaryBm>)
	{
		Object.assign(this, init);
		
	}
	public sn: string;
	public linkedSnId: number;
	public comment: string;
}
export
class AddToProductionsOrderVm
{
	constructor (init?: Partial<AddToProductionsOrderVm>)
	{
		Object.assign(this, init);
	}
	public orders: any;
}
export
class AddToSalesOrderVm
{
	constructor (init?: Partial<AddToSalesOrderVm>)
	{
		Object.assign(this, init);
	}
	public orders: any;
}
export
class MoveToProductionBm
{
	constructor (init?: Partial<MoveToProductionBm>)
	{
		Object.assign(this, init);
		
	}
	public sn: string;
}
export
class MoveToProductionVm
{
	constructor (init?: Partial<MoveToProductionVm>)
	{
		Object.assign(this, init);
	}
	public sn: string;
}

export
class AddToShipRequestVm
{
	constructor (init?: Partial<AddToShipRequestVm>)
	{
		Object.assign(this, init);
		
	}
	public shipRequests: any[];
}
export
class AddToPrimaryVm
{
	constructor (init?: Partial<AddToPrimaryVm>)
	{
		Object.assign(this, init);
		
	}
	public addToPrimaryList: any[];
}
export
class AddToSecondaryVm
{
	constructor (init?: Partial<AddToSecondaryVm>)
	{
		Object.assign(this, init);
		
	}
	public addToSecondaryList: any[];
}
export
class CreateBm
{
	constructor (init?: Partial<CreateBm>)
	{
		Object.assign(this, init);
		
	}
	public eventFlowId: number;
	public sn: string;
	public comment: string;
	public jsonForm: JsonForm;
}
export
class FindSnArgs
{
	constructor (init?: Partial<FindSnArgs>)
	{
		Object.assign(this, init);
		
	}
	public sn: string;
}
export
class SnItemVm
{
	constructor (init?: Partial<SnItemVm>)
	{
		Object.assign(this, init);
		
	}
	public values: { [key:number]: string };
	public serialNumber: string;
	public zbSerialNumber: string;
	public po: string;
}
export
class SnItemNewVm
{
	constructor (init?: Partial<SnItemNewVm>)
	{
		Object.assign(this, init);
		
	}
	
	public serialNumber: string;
	public product: string;
	public mxdFile: string;
	public filename: string;
	public corners: string;
	public productionRequest: string;
	public salesOrder :string;
	public rma: string;
	public rmaStatus: string;
	public rmaNumber: string;
	public shipment: string;
	public shipmentDate: string;
	public trackingNumber: string;
	public accountId: string;
	public companyName: string;
    public status: string;
    public warrantyExpirationDate: string;
    public warrantyStatus: string;
	public positionAssigned: string;
	public position: string;
	public linkedSn: string;
	public pcbHardwareVersion: string;
	public pcbFirmwareVersion: string;
	public pcbSpecialNote: string;
	public wucLinkedSns: string;
	public wucHardwareVersion: string;
	public wucFirmwareVersion: string;
	public wucSpecialNote: string;
}

export
class ExternalEditShipRequestVm extends BaseEditShipRequestVm
{
    product: any;
	constructor (init?: Partial<ExternalEditShipRequestVm>)
	{
		super(init);
		Object.assign(this, init);
		
	}
	public accounts: ShipRequestsAccountVm[];
	public cornerTypes: CornerTypeVm[];
	public insertTypes: InsertTypeVm[];
	public connTypes: ConnTypeVm[];
}

export
class ExternalSnRegisterSnContactVm
{
	constructor (init?: Partial<ExternalSnRegisterSnContactVm>)
	{
		Object.assign(this, init);
		
	}
	public name: string;
	public phoneNumber: string;
	public phoneExtension: string;
	public email: string;
	public job: string;
}

export
class ExternalRegisterSnVm
{
	constructor (init?: Partial<ExternalRegisterSnVm>)
	{
		Object.assign(this, init);
		
	}
	public serialNumber: string;
	public company: string;
	public contact: ExternalSnRegisterSnContactVm;
	public address: AddressInfo;
	public purchaseDate: Date;
	public placeOfPurchase: string;
}
export
class BodyParameter<T>
{
	constructor (init?: Partial<BodyParameter<T>>)
	{
		Object.assign(this, init);
		
	}
	public value: T;
}
export
class BaseResult
{
	constructor (init?: Partial<BaseResult>)
	{
		Object.assign(this, init);
		
	}
	public ok: boolean;
}
export
class SaveResult extends BaseResult
{
	constructor (init?: Partial<SaveResult>)
	{
		super(init);
		Object.assign(this, init);
		
	}
	public id: number;
}
export
class ValueVm
{
	constructor (init?: Partial<ValueVm>)
	{
		Object.assign(this, init);
		
	}
	public originValue: any;
	public code: string;
	public value: string;
	public name: string;
	public type: string;
}
export
class AddressVm
{
	constructor (init?: Partial<AddressVm>)
	{
		Object.assign(this, init);
		
	}
	public streetAddress1: string;
	public streetAddress2: string;
	public streetAddress3: string;
	public city: string;
	public country: string;
	public code: string;
	public state: string;
	public zip: string;
	public phone: string;
}
export
class ContactVm
{
	constructor (init?: Partial<ContactVm>)
	{
		Object.assign(this, init);
		
	}
	public name: string;
	public phoneNumber: string;
	public phoneExtension: string;
	public email: string;
	public job: string;
}
export
class EditEntityShipRequestVm
{
	constructor (init?: Partial<EditEntityShipRequestVm>)
	{
		Object.assign(this, init);
		
	}
	public changerName: string;
	public changeDate: Date;
	public shipServiceName: string;
	public accountName: string;
	public accountId: number;
	public deliverBy: Date;
	public feePayment: PaymentInfoVm;
	public transportationPayment: PaymentInfoVm;
	public status: Enums.ShipRequestStatus;
	public statusName: string;
	public shipAddress: AddressVm;
	public contact: ContactVm;
	public declaredValue: number;
	public isRefurbished: boolean;
	public comment: string;
	public reasonForCancel: string;
	public product: ProductVm;
	public productId: number;
	public numberOfPlates: number;
	public shipServiceId: number;
	public emailForNotification: string;
	public companyName: string;
	public id: number;
	public cornerTypeId: number;
	public connTypeId: number;
	public insertTypeId: number;
	public numberOfCarpets: number;
	public numberOfUsbExt: number;
	public numberOfBrackets: number;
	public numberOfVelcrosHooks: number;
	public numberOfVelcrosLoops: number;
	public numberOfGreenTurfs: number;
	public numberOfLogoMats: number;

}
export
class ShipRequestPredefinedDataVm
{
	constructor (init?: Partial<ShipRequestPredefinedDataVm>)
	{
		Object.assign(this, init);
		
	}
	public keyName: string;
	public companyName: string;
	public contact: ContactInfo;
	public shipAddress: AddressInfo;
	public shipServiceId: number;
	public transportationPayment: PaymentInfo;
	public feePayment: PaymentInfo;
	public emailForNotification: string;
}
export
class PaymentInfoVm
{
	constructor (init?: Partial<PaymentInfoVm>)
	{
		Object.assign(this, init);
		
	}
	public accountNo: string;
	public payer: string;
}
export
class AnonymousEditVm
{
	constructor (init?: Partial<AnonymousEditVm>)
	{
		Object.assign(this, init);
		
	}
	public company: string;
	public contact: ContactVm;
	public address: AddressVm;
}
export
class AnonymousRegisterSnVm
{
	constructor (init?: Partial<AnonymousRegisterSnVm>)
	{
		Object.assign(this, init);
		
	}
	public serialNumber: string;
	public company: string;
	public contact: ContactVm;
	public address: AddressVm;
	public purchaseDate: Date;
	public placeOfPurchase: string;
	public apiToken: string;
}
export
class LogItemFilter
{
	constructor (init?: Partial<LogItemFilter>)
	{
		Object.assign(this, init);
		
	}
	public severityAll: boolean;
	public severity: Enums.LogSeverity;
	public timestampFrom: Date;
	public timestampTo: Date;
	public message: string;
	public stackTrace: string;
	public orderByTimestampAsc: boolean;
}

export
class LinkToPcbVm
{
	constructor (init?: Partial<LinkToPcbVm>)
	{
		Object.assign(this, init);
	}
	public linkToPcbList: any[];
}
export
class LinkToPcbBm
{
    linkedSn: string;
	constructor (init?: Partial<LinkToPcbBm>)
	{
		Object.assign(this, init);
	}
	public sn: string;
	public linkedPcbSnId: string;
	public likedSn: string;
	public comment: string;
}

export module Enums {
	export enum LogSeverity { 
		None = 0, 
		Error = 1, 
		Warning = 2, 
		Info = 4, 
	}
	export enum BoolFilterEnum { 
		All = 0, 
		Yes = 1, 
		No = 2, 
	}
	export enum JsonFormControlType { 
		number = 0, 
		text = 1, 
		date = 2, 
	}
	export enum ShipRequestStatus { 
		None = 0, 
		Created = 1, 
		Processing = 2, 
		Done = 3, 
		Canceled = 4, 
	}
	export enum PropertyType { 
		None = 0, 
		String = 1, 
		Date = 2, 
		Object = 3, 
	}
	export enum PropertyLocation { 
		Default = 0, 
		Reference = 2, 
		ReferenceField = 3, 
	}
	export enum RmaOrderStatus { 
		None = 0, 
		Created = 1, 
		Deleted = 2, 
		Canceled = 3,
        Done = 4
	}
	export enum ErrorCode { 
		ConfigRmaNotificationEmail = 0, 
	}
	export enum PermissionType { 
		SoftwareDownload = 1, 
	}
	export enum ProductionRequestStatus { 
		None = 0, 
		Created = 1, 
		Approved = 3, 
		Done = 4, 
	}
	export enum SalesOrderStatus { 
		None = 0, 
		Created = 1, 
		Approved = 3, 
		Done = 4, 
	}
	export class Role {
		static readonly External = 'external'
		static readonly Internal = 'internal'
		static readonly Admin = 'admin'
	}
}
