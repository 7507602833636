﻿import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { EditAccountVm, AddressVm } from '../../models';
import { AccountService } from '../account.service'

@Component({
    selector: 'account-edit',
    templateUrl: './account-edit.component.html',
})
export class AccountEditComponent implements OnInit {
    @ViewChild('form') form: FormGroup;
    model: EditAccountVm;
    serverValidationError: string;
    isBusy = false;
    submitButtonName: string;

    constructor(
        private dataService: AccountService,
        private route: ActivatedRoute,
        private router: Router
    ) { }

    async ngOnInit(): Promise<void> {
        this.model = new EditAccountVm();
        this.model.addressInfo = new AddressVm();
        this.model.id = +this.route.snapshot.params['id'];
        if (this.model.id) {
            this.submitButtonName = "Save";
            this.model = await this.dataService.getEditVm(this.model.id);
        } else {
            this.submitButtonName = "Create";
        }
    }

    async submit(): Promise<void> {
        this.serverValidationError = '';
        if (this.form.invalid) {
            return;
        }

        this.isBusy = true;
        console.log(this.model);
        await this.dataService.save(this.model).catch(e => this.serverValidationError = e);
        this.isBusy = false;
        if (this.serverValidationError) return;

        this.navigateBack();
    }

    navigateBack(): void {
        this.router.navigate(['/accounts'])
    }
}