import { Injectable } from '@angular/core';
import { Http, ResponseContentType } from '@angular/http';

import { BaseService } from '../core/services/base.service';
import { SerialNumberListModel } from './serial-number-list/serial-number-list.model';
import * as models from '../models';

@Injectable()
export class SerialNumberService extends BaseService {
    private baseUri = "sn/";

    constructor(http: Http){ super(http);}

    load(model: SerialNumberListModel): Promise<models.SnListVm>{
        return this.get<models.SnListVm>(this.baseUri + 'load', model);
    }

    getall(model: SerialNumberListModel): Promise<models.SnListNewVm>{
        return this.get<models.SnListNewVm>(this.baseUri + 'getall', model);
    }

    //getByProduct(id: number): Promise<models.SnListNewVm>{
    getByProduct(model: SerialNumberListModel): Promise<models.SnListNewVm>{
        return this.get<models.SnListNewVm>(this.baseUri + 'getByProduct', model);
    }

    public export(model: SerialNumberListModel): Promise<void> {
        return this.download<void>(this.baseUri + `export`, model);
    }
}
