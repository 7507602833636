﻿import { Input, Component, OnInit } from '@angular/core';

import {
    SoftwareVersionVm,
    SoftwareVm,
    SoftwareVersionFilter,
    Enums,
    SoftwareService
} from '../../models';
import { SoftwareVersionService } from '../software-version.service';

@Component({
    selector: 'software-version-list',
    templateUrl: './software-version-list.component.html',
})
export class SoftwareVersionListComponent implements OnInit {
    @Input() softwareId: number;
    isInitializedByRouter = true;

    public softwareVersionList: Array<SoftwareVersionVm>;
    private softwareList: Array<SoftwareVm>;
    public localSettings = {
        isFilterVisible: false,
        buttonLable: "Show Filter"
    };
    private filter: SoftwareVersionFilter = {
        softwareId: 0,
        limit: 0,
        support: Enums.BoolFilterEnum.All
    };
    private boolEnumFilterValues: string[];
    private boolFilterEnum = Enums.BoolFilterEnum;

    constructor(
        private softwareVersionDataService: SoftwareVersionService,
        private softwareDataService: SoftwareService
    ) { }

    async ngOnInit(): Promise<void> {
        if (!isNaN(+this.softwareId)) {
            this.isInitializedByRouter = false;
            this.filter.softwareId = +this.softwareId;
            this.filter.limit = 50;
        }

        this.boolEnumFilterValues = Object.keys(this.boolFilterEnum).filter(key => !isNaN(+key));

        this.reloadList();
        
    }

    async reloadList(): Promise<void> {
        this.softwareVersionList = await this.softwareVersionDataService.getAll(this.filter);
        // this.softwareVersionList
        //     .orderByDescending(x => new Date(x.releaseDate))
        //     .orderBy(x => x.software.name)

        // console.log("BEFORE: ");
        // console.log(this.softwareVersionList);

        // if (this.softwareVersionList.findIndex(x => x.software.code == "SeUtility") >= 0) { this.softwareVersionList.find(x => x.software.code == "SeUtility").software.url = "SensorEdgeUtilitySetup.exe"; }
        // if (this.softwareVersionList.findIndex(x => x.software.code == "golf") >= 0) { this.softwareVersionList.find(x => x.software.code == "golf").software.url = "GolfSetup.exe" };
        // if (this.softwareVersionList.findIndex(x => x.software.code == "gripgolf") >= 0) { this.softwareVersionList.find(x => x.software.code == "gripgolf").software.url = "SensorGripGolfSetup.exe"; }
        // if (this.softwareVersionList.findIndex(x => x.software.code == "gripbaseball") >= 0) { this.softwareVersionList.find(x => x.software.code == "gripbaseball").software.url = "SensorGripBaseballSetup.exe"; }
        // if (this.softwareVersionList.findIndex(x => x.software.code == "batter") >= 0) { this.softwareVersionList.find(x => x.software.code == "batter").software.url = "BattersPlateProSetup.exe"; }
        // if (this.softwareVersionList.findIndex(x => x.software.code == "pitcher") >= 0) { this.softwareVersionList.find(x => x.software.code == "pitcher").software.url = "SensorEdgePitchingAppSetup.exe"; }

        // console.log("AFTER: " );
        // console.log(this.softwareVersionList);
    }

    clickShowFilter() {
        if (this.localSettings.isFilterVisible) {
            this.resetFilter();
            this.localSettings.isFilterVisible = false;
            this.localSettings.buttonLable = "Show Filter";
            this.reloadList();
        }
        else {
            this.localSettings.isFilterVisible = true;
            this.localSettings.buttonLable = "Remove Filter";
        }
    }

    resetFilter() {
        this.filter = {
            softwareId: 0,
            limit: 0,
            support: Enums.BoolFilterEnum.All
        };
    }
}