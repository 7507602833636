﻿import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';

import { RmaOrderService } from '../rma-order.service'
import { Enums } from '../../models';
import { RmaOrderVm } from '../rma-order-detail/rma-order.vm';

@Component({
    selector: 'rma-order-list',
    templateUrl: './rma-order-list.component.html',
})
export class RmaOrderListComponent implements OnInit {
    list: RmaOrderVm[];

    public showOverlay = true;

    public pageSize: number = 20;

    p: number = 1;

    constructor(
        private dataService: RmaOrderService,
        private router: Router
    ) {
    }

    async ngOnInit(): Promise<void> {
        this.showOverlay = true;
        const list = await this.dataService.getListVm();
        this.list = list.sort((a, b) => b.dueDate > a.dueDate ? 1 : -1);
        this.showOverlay = false;
    }

    navigateToDetails(id: number): void {
        this.router.navigate(['/rmaOrder/detail/' + id]);
    }

    getStatusName(value: Enums.RmaOrderStatus): string {
        return Enums.RmaOrderStatus[value];
    }
}