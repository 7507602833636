﻿import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms'
import { Router, ActivatedRoute } from '@angular/router'

import { ProductionRequestNewVm, ProductionRequestsProductVm, CustomerVm, ProductionRequestService } from '../../models'
import { Urls } from '../../routing/urls'

@Component({
    selector: 'production-request-create',
    templateUrl: './production-request-create.component.html',
})
export class ProductionRequestCreateComponent implements OnInit {
    @ViewChild('formElement') formElement: FormGroup;
    model: ProductionRequestNewVm = new ProductionRequestNewVm();
    product: ProductionRequestsProductVm;
    customer: CustomerVm;
    error: string;

    constructor(
        private dataService: ProductionRequestService,
        private router: Router,
        private route: ActivatedRoute
    ) { }

    async ngOnInit(): Promise<void> {
        this.model = await this.dataService.getNewVm();

        this.route.queryParams.subscribe(params => {
            let data = params;
            // console.log(data.d);
            // console.log(this.getDateString(new Date(data.d)));
            // console.log(data.d.length);
            this.model.dueDate = data.d.length > 0 ? new Date(this.getDateString(new Date(data.d))) : new Date(this.model.dueDate);
            this.product = data.p > 0 ? this.model.products.find(o => o.id == data.p) : this.model.products[0];
            this.customer = data.c > 0 ? this.model.customers.find(o => o.id == data.c) : this.model.customers[0];
            this.model.quantity = data.q > 0 ? data.q : 1;
            this.model.po = data.po;
            this.model.comment = data.co;
        });
    }

    async create(): Promise<void> {
        if (this.formElement.invalid) {
            return;
        }
        this.model.productId = this.product.id;
        this.model.customerId = this.customer.id;
        await this.dataService.save(this.model).catch(e => {
            this.error = e;
        });
        if (!this.error) {
            this.navigateToProductionRequests();
        }
    }

    isAnyRequiredError(): boolean {
        return Object
            .keys(this.formElement.controls)
            .some(key => this.formElement.hasError('required', [key]));
    }

    navigateToProductionRequests() {
        this.router.navigateByUrl(Urls.ProductionRequestList)
    }

    private getDateString(date: Date): string {
        var tdate = date == null ? new Date() : date;
        //console.log("tdate: " + date);
        var dd = String(tdate.getUTCDate()).padStart(2, '0');
        //console.log("dd: " + dd);
        var mm = String(tdate.getUTCMonth() + 1).padStart(2, '0'); //January is 0!
        //console.log("mm: " + mm);
        var yyyy = tdate.getUTCFullYear();
        var today = mm + '/' + dd + '/' + yyyy;
        return today;
    }
}