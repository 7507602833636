﻿export class Config {
  static get mxdUrl(): string {
    return 'api/mxdFiles/';
  }

  /* DON'T FORGET TO SWITCH TO LIVE WHEN BUILDING RELEASE */
  static get baseUrl(): string {
    // return 'http://localhost:8002/'
    // return 'https://localhost:44328/'      // DEBUG
     return 'https://cms.sensoredge.com/' // LIVE
    // return 'http://192.168.0.173:8002/'  // do not use unless local testing
  }

  static get apiUrl(): string {
    return this.baseUrl + 'api/';
  }
}