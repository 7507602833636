﻿import {
    UserPropertyGroupVm,
    ValueVm
} from "../../models/view-models";

export class PropertyGroupWithValues extends UserPropertyGroupVm {
    propertyValues: ValueVm[];

    constructor(group: UserPropertyGroupVm, values: ValueVm[]) {
        super(group);
        this.propertyValues = values.filter(x => this.properties.find(y => y.code === x.code));
    }
}