﻿import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { SoftwareVersionModule } from '../software-version/software-version.module';
import { SoftwareService } from '../models';
import { SoftwareOptionsComponent } from './software-options/software-options.component';
import { SoftwareListComponent } from './software-list/software-list.component';
import { SoftwareEditComponent } from './software-edit/software-edit.component';

@NgModule({
    imports: [
        SharedModule,
        SoftwareVersionModule
    ],
    providers: [
        SoftwareService
    ],
    declarations: [
        SoftwareOptionsComponent,
        SoftwareListComponent,
        SoftwareEditComponent
    ],
    exports: [
        SoftwareOptionsComponent,
        SoftwareListComponent,
        SoftwareEditComponent
    ],
    entryComponents: [
        SoftwareListComponent,
        SoftwareEditComponent
    ]
})
export class SoftwareModule { }