import { Injectable } from "@angular/core"
import { BaseService } from "../../core/services/base.service"
import { Http } from "@angular/http"
import { ExternalProfileEditVm, SaveResult, ExternalProfileSaveVm } from "../../models"

@Injectable()
export class ExternalProfileService extends BaseService
{
	constructor (http: Http)
	{
		super(http);
	}
	public getEditVm() : Promise<ExternalProfileEditVm>
	{
		const uriParams = {  };
		return this.get<ExternalProfileEditVm>(this.baseUri + `GetEditVm`, uriParams);
	}
	public save(vm: ExternalProfileSaveVm) : Promise<SaveResult>
	{
		const uriParams = {  };
		return this.post<SaveResult>(this.baseUri + `Save`, vm, uriParams);
	}
	private baseUri: string = 'ext/profile/';
}