import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'

import { SignInVm } from './sign-in-vm'
import { SignInService } from './sign-in.service'
import { save } from '../tools/auth-storage'
import { Identity } from '../models/identity';

const getErrorMessage = (res) => {
  const { status, error } = res
    if(status === 400) {
      if(error.indexOf('[no_data]') === 0) {
        return 'No such user in a system'
      }
    }
    console.log('error', res)
    return 'Please try again later.'
}

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
export class SignInComponent implements OnInit {
  vm = new SignInVm()
  errorMessage: string = ''

  constructor(private service: SignInService,
    private router: Router) { }

  ngOnInit() { }

  go() {
    this.router.navigate([''])
  }

  onSubmit() { 
    const that = this
    that.errorMessage = ''
    this.service
      .signIn(this.vm)
      .subscribe(r => {
        var identity = new Identity({ role: r.role, token: r.token, userName: r.userName })
        save(identity, true)
      }, res => {
        that.errorMessage = getErrorMessage(res)
      })
  }
}
