﻿import { Component, OnInit, ViewChild, Inject } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { Router } from '@angular/router'

import { RmaOrderService } from '../rma-order.service'
import { RmaOrderNewVm } from '../../models'
import { ResponseException } from "../../models/response-exception"

@Component({
    selector: 'rma-order-create',
    templateUrl: './rma-order-create.component.html',
})
export class RmaOrderCreateComponent implements OnInit {
    @ViewChild('formElement') formElement: FormGroup;
    model = new RmaOrderNewVm();
    error: ResponseException;

    constructor(
        private dataService: RmaOrderService,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.model.reason = 'Data quality - Uneven, CoP favoring one foot';
    }

    async create(): Promise<void> {
        if (this.formElement.invalid) {
            return;
        }
        this.error = await this.dataService.save(this.model).catch(e => e);
        if (!(this.error instanceof ResponseException)) {
            this.navigateBack();
        }
    }

    navigateBack(): void {
        this.router.navigate(['/rmaOrders'])
    }
}