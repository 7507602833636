﻿import { SoftwareVm, SoftwarePermissionsVm } from '../../models';
import { RolePermission } from './role-permission';

export class SoftwarePermission {
    software: SoftwareVm;
    rolePermissions: Array<RolePermission>;

    constructor(init?: Partial<SoftwarePermission>) {
        Object.assign(this, init);
    }
}