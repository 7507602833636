import { Component, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { IssueRmaBm, Enums } from '../../models';
import { EventBaseComponent } from '../event-base/event-base.component';
import { EventsService } from '../events.service';
import { ModalMessageComponent } from '../../shared/components/modal-message/modal-message.component';
import { ResponseException } from "../../models/response-exception";

@Component({
    selector: '<event-issued-rma>',
    templateUrl: './event-issued-rma.component.html'
})
export class EventIssuedRmaComponent extends EventBaseComponent {
    @ViewChild('warningModal') warningModal: ModalMessageComponent;
    @ViewChild('formElement') formElement: FormGroup;
    warningMessage: string;
    dueDate: Date;
    rmaNo: string;
    reason: string;
    isSubmitClicked: boolean;

    constructor(public eventsService: EventsService) {
        super(eventsService);
    }

    async issueRma(): Promise<void> {
        this.isSubmitClicked = true;
        if (this.formElement.invalid) {
            return;
        }

        this.model.wait = true;
        const model = this.getSaveModel();
        const response = await this.eventsService.issueRma(model).catch(e => e);
        this.model.wait = false;
        this.processSaveResponse(response);
    }

    private processSaveResponse(response: any): void{
        if (response instanceof ResponseException) {
            if (Enums.ErrorCode[response.code] === Enums.ErrorCode.ConfigRmaNotificationEmail) {
                this.warningMessage = response.text;
                this.warningModal.show(this.backToEvents.bind(this));
            } else {
                this.model.error = response.toString();
            }
        } else {
            this.backToEvents();
        }
    }

    private getSaveModel(): IssueRmaBm {
        const data = new IssueRmaBm();
        data.sn = this.model.sn;
        data.comment = this.model.comment;
        data.dueDate = this.dueDate;
        data.rmaNo = this.rmaNo;
        data.reason = this.reason;
        return data;
    }
}