﻿import { SnDetailsVm } from '../../../models';
import { FindResultVm } from './find-result.vm';

export class ExtEventMainModel extends FindResultVm {
    public sn: string = '';
    public wait: boolean = false;
    public error: string = '';
  
    public constructor() {
        super();
        this.initObjectProperties();
    }

    public update(init?: Partial<ExtEventMainModel>): void {
        Object.assign(this, init);
        this.initObjectProperties();
    }

    private initObjectProperties() {
        this.snForSelect = this.snForSelect || [];
        this.details = new SnDetailsVm(this.details);
    }
}