﻿import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap';
import { ActivatedRoute, Router } from '@angular/router'

import { AuthService } from '../../core/services/auth.service'
import { ModalConfirmComponent } from '../../shared/components/modal-confirm/modal-confirm.component'
import { ShipRequestService } from '../ship-request.service'
import { Urls } from '../../routing/urls'

import {
  ShipRequestPredefinedData,
  ShipRequestPredefinedDataVm,
  EditEntityShipRequestVm,
  SaveShipRequestPredefinedDataVm,
  ShipServiceVm,
  KeyValuePair,
  ShipRequestsAccountVm,
  Shipment,
  Country,
  ContactVm,
  AddressVm,
  PaymentInfoVm,
  ListItem,
  SaveShipRequestVm,
  Enums,
  EditShipRequestVm,
  ShipmentRequestsProductVm,
  ProductVm,
  CornerTypeVm,
  InsertTypeVm,
  ConnTypeVm
} from '../../models'
import { Http } from '@angular/http';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'ship-request-edit',
  templateUrl: './ship-request-edit.component.html',
})
export class ShipRequestEditComponent implements OnInit {
  @ViewChild('deleteDialogElement') deleteDialogElement: ModalConfirmComponent;
  @ViewChild('saveDialogElement') saveDialogElement: ModalDirective;
  @ViewChild('cancelDialogElement') cancelDialogElement: ModalDirective;
  @ViewChild('formElement') formElement: FormGroup;

  isBusy: boolean;
  isReadOnly: boolean;
  predefinedDataItemToDelete: ShipRequestPredefinedData;
  shipRequestPredefinedDataItems: ShipRequestPredefinedDataVm[];
  rmaOrderId: number;
  entity: EditEntityShipRequestVm;
  statuses: KeyValuePair<number, string>[];
  billTransportationToListItems: ListItem[];
  billDutiesToListItems: ListItem[];
  accounts: ShipRequestsAccountVm[];
  shipments: Shipment[];
  countries: Country[]
  products: ShipmentRequestsProductVm[];
  keyName: string;
  errorMessage: string;
  isCreate: boolean;
  _shipServices: ShipServiceVm[];
  currentCarrier: string;
  carrierServices: KeyValuePair<number, string>[];
  serverError: string = '';
  product: ProductVm;
  corners: CornerTypeVm[];
  connections: ConnTypeVm[];
  inserts: InsertTypeVm[];
  allInserts: InsertTypeVm[];

  /*
  32	DynamixDuo-01
  33	BAT-01
  34	CLUB-01
  35	CRIK-01
  49	CLUB-02
  50	BAT-02
  52	DynamiXDuo-02
  55	Putter-01
  58	DynamiXDuo-03

  39	Other Request
  46	Fabric
  40	OldDataPCB
  41	FullDataPCB
  42	WirelessDataPCB
  43	HalfDataPCB
  44	MicroDataPCB
  51	DynamiXDataPCB
  56	MiniDataPCB
  72	FullWirelessDataPCB

  54	TattooPen-01

  45	DUAL LG HALF
  47	DynamiXDuo-01-Half
  48	Motion Catalyst HALF
  53	DynamiXDuo-02-Half
  57	DynamiXDuo-03-Half
  69	DUAL SM HALF

  59	SL (WUC)
  71  SL-02 (WUC mini)

  36	SM-01
  65	SMMLB-01
  64	SMP-01

  63	BB-01
  70	CTS-01
  2	FSHD-2020-0
  23	SD-99-0
  25	FS-CUSTOM
  6	FSSD-2020-0
  15	HSHD-2020-0
  16	SD-99-W
  18	HD-8235-0
  19	HD-8235-W
  20	HD-1818-0
  21	HD-2020-W
  3	GR-SURFACE-01
  22	KINQ-101
  26	MC-01
  30	BP-PMP-01
  17	CS-CUSTOM
  */

  listOne = [32,33,34,35,49,50,52,55,58];
  listTwo = [39,46,40,41,42,43,44,51,56,72];
  
   constructor(
      private authService: AuthService,
      private service: ShipRequestService,
      private route: ActivatedRoute,
      private router: Router,
      private http: Http
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.corners = [{id: 0, name: ''}];
    this.inserts = [{id: 0, name: '', accountId: null}];
    this.connections = [{id: 0, name: ''}]
    this.products = [{id: 0, modelNo: ''}];
    this.shipServices = [{carrier: '', isDomestic: null, services: []}];
    this.billDutiesToListItems = [{id: 0, name: '', isDeleted: false, value: '', isDefault: true, listType: ''}];
    this.billTransportationToListItems = [{id: 0, name: '', isDeleted: false, value: '', isDefault: true, listType: ''}];
    this.LoadEntity();
  }

  async LoadEntity(): Promise<void> {
      const rmaOrderId = +this.route.snapshot.queryParams['rmaOrderId'] || null;
      const shipmentId = +this.route.snapshot.queryParams['shipmentId'] || null;
      
      let res: EditShipRequestVm;
      if (shipmentId) {
        res = await this.service.getEditVmByShipmentId(shipmentId);
      }
      else {
        const id = +this.route.snapshot.params['id'] || 0
        res = await this.service.getEditVm(id, rmaOrderId)
      }

      this.rmaOrderId = res.rmaOrderId;
      this.isReadOnly = res.isReadOnly;
      this.shipServices =  this.shipServices.concat(res.shipServices);
      this.statuses = res.statuses;
      this.billTransportationToListItems = this.billTransportationToListItems.concat(res.billTransportationToListItems);
      this.billDutiesToListItems = this.billDutiesToListItems.concat(res.billDutiesToListItems);
      this.accounts = res.accounts;
      this.shipments = res.shipments;
      this.countries = res.countries;
      this.product = res.product;
      this.products = this.products.concat(res.products);
      this.entity = this.createEntity(res.entity);
      this.isCreate = this.entity.status === Enums.ShipRequestStatus.Created;
      this.corners = res.cornerTypes;
      this.inserts = res.entity.accountId == 2 ? res.insertTypes : res.insertTypes.filter(x => x.accountId === null || x.accountId === res.entity.accountId);
      this.connections = res.connTypes;
      this.allInserts = res.insertTypes;

      if (res.entity.shipServiceId !== null) {
          this.setCarrierAndShipServiceType(res.entity.shipServiceId);
      }
      if (!this.entity.id) {
          this.entity.transportationPayment.payer = this.billTransportationToListItems.find(x => x.isDefault).value;
          this.entity.feePayment.payer = this.billDutiesToListItems.find(x => x.isDefault).value;
          await this.refreshPredefinedDataItems();
      }

      this.entity.statusName = this.statuses.find(status => status.key === this.entity.status).value;
  }

  async setCarrierAndShipServiceType(shipServiceId: number): Promise<void> {
      if (shipServiceId === null) {
          this.currentCarrier = null;
          this.carrierServices = [];
          return;
      }

      this.shipServices.some(carrier => {
          return carrier.services.some(carrierService => {
              if (carrierService.key === shipServiceId) {
                  this.currentCarrier = carrier.carrier;
                  this.carrierServices = carrier.services;
                  this.entity.shipServiceId = carrierService.key;
                  return true;
              }
              return false;
          });
      });
  }

  get shipServices(): ShipServiceVm[] {
    if (!this.entity || !this.entity.shipAddress || this.entity.shipAddress.country === "" || this.entity.shipAddress.country === undefined) {
      return [];
    }

    var countryObj = this.countries.filter(country => country.name+","+country.code === this.entity.shipAddress.country)[0];
    if (!countryObj) {
      return [];
    }
    
    return this._shipServices.filter(x => x.isDomestic === countryObj.isHome);
  }

  set shipServices(list: ShipServiceVm[]) {
    this._shipServices = list.slice(0);
  }

  showDeletePredefinedDialog(predefinedDataItem: ShipRequestPredefinedData): void {
    this.predefinedDataItemToDelete = predefinedDataItem
    const message = `Delete Predefined Data with Name '${this.predefinedDataItemToDelete.keyName}`
    this.deleteDialogElement.show(message)
  }

  showDeleteRequestDialog(): void {
    const message = `Are you sure you want to delete Shipment Request #'${this.entity.id}`
    this.deleteDialogElement.show(message)
  }

  async deletePrdefinedData(): Promise<void> {
      this.isBusy = true;
      await this.service.deletePredefinedData(this.predefinedDataItemToDelete.keyName)
      await this.refreshPredefinedDataItems();
      this.predefinedDataItemToDelete = null
      this.isBusy = false;
  }

  async delete(): Promise<void> {
    this.isBusy = true;
    await this.service.delete(this.entity.id)
    this.router.navigateByUrl(Urls.ShipRequestList)
    this.isBusy = false;
  }

  async copyRequest(): Promise<void> {
    this.isBusy = true;

    if (this.isAnyError()) {
      return;
    }
    this.serverError = '';
    this.isBusy = true;

    const saveVm = new SaveShipRequestVm(this.entity);
    saveVm.rmaOrderId = this.rmaOrderId;
    saveVm.isNew = true;
    await this.service.save(saveVm).catch(e => this.serverError = e);

    if (!this.serverError)
        this.navigateToList();

    this.isBusy = false;
  }

  async confirmDelete(): Promise<void> {
    if (this.predefinedDataItemToDelete) {
      await this.deletePrdefinedData()
    } else {
      await this.delete()
    }
  }

  async setStatusAccept(): Promise<void> {
      this.isBusy = true;
      await this.service.setStatusAccept(this.entity.id);
      this.navigateToList();
  }

  async setStatusDone(): Promise<void> {
      this.isBusy = true;
      await this.service.setStatusDone(this.entity.id);
      this.navigateToList();
  }

  async setStatusProcessing(): Promise<void> {
      this.isBusy = true;
      await this.service.setStatusProcessing(this.entity.id);
      this.navigateToList();
  }

  async savePredefinedData(): Promise<void> {
      this.isBusy = true;

      const model = new SaveShipRequestPredefinedDataVm(this.entity);
      model.keyName = this.keyName;

      await this.service
          .savePredefinedData(model)
          .catch(e => this.errorMessage = e);

      await this.refreshPredefinedDataItems();
      this.keyName = '';
      this.closeSaveDialog();
      this.isBusy = false;
  }

  showSaveDialog(): void {
      this.saveDialogElement.show();
  }

  closeSaveDialog(): void {
      this.saveDialogElement.hide();
  }

  async refreshPredefinedDataItems(): Promise<void> {
      this.shipRequestPredefinedDataItems = await this.service.getPredefinedDataItems();
  }

  fillWithPredefinedData(predefinedDataItem: ShipRequestPredefinedData): void {
    this.entity.companyName = predefinedDataItem.companyName;

    this.entity.contact = this.entity.contact || new ContactVm();
    this.entity.contact.name = predefinedDataItem.contact.name;
    this.entity.contact.phoneNumber = predefinedDataItem.contact.phoneNumber;
    this.entity.contact.phoneExtension = predefinedDataItem.contact.phoneExtension;

    this.entity.emailForNotification = predefinedDataItem.emailForNotification;

    this.entity.shipAddress = this.entity.shipAddress || new AddressVm();
    this.entity.shipAddress.streetAddress1 = predefinedDataItem.shipAddress.streetAddress1;
    this.entity.shipAddress.streetAddress2 = predefinedDataItem.shipAddress.streetAddress2;
    this.entity.shipAddress.streetAddress3 = predefinedDataItem.shipAddress.streetAddress3;
    this.entity.shipAddress.city = predefinedDataItem.shipAddress.city;
    this.entity.shipAddress.country = predefinedDataItem.shipAddress.country;
    this.entity.shipAddress.state = predefinedDataItem.shipAddress.state;
    this.entity.shipAddress.zip = predefinedDataItem.shipAddress.zip;

    this.setCarrierAndShipServiceType(predefinedDataItem.shipServiceId);

    this.entity.transportationPayment = this.entity.transportationPayment || new PaymentInfoVm();
    this.entity.transportationPayment.payer = predefinedDataItem.transportationPayment.payer;
    this.entity.transportationPayment.accountNo = predefinedDataItem.transportationPayment.accountNo;

    this.entity.feePayment = this.entity.feePayment || new PaymentInfoVm();
    this.entity.feePayment.payer = predefinedDataItem.feePayment.payer;
    this.entity.feePayment.accountNo = predefinedDataItem.feePayment.accountNo;
  }

  async save(): Promise<void> {
    console.log(this.entity.connTypeId);
      if (this.isAnyError()) {
          return;
      }
      this.serverError = '';
      this.isBusy = true;

      const saveVm = new SaveShipRequestVm(this.entity);
      saveVm.rmaOrderId = this.rmaOrderId;
      saveVm.isNew = this.entity.id < 1;
      await this.service.save(saveVm).catch(e => this.serverError = e);

      if (!this.serverError)
          this.navigateToList();
  }

  isAnyError(): boolean {
      return this.formElement.invalid || this.isAnyRequiredError();
  }

  isAnyRequiredError(): boolean {
      if (!this.entity.accountId)
          return true;

      return Object
          .keys(this.formElement.controls)
          .some(key => this.formElement.hasError('required', [key]));
  }

  showCancelDialog(): void {
      this.cancelDialogElement.show();
  }

  async cancel(reason: string): Promise<void> {
      if (!reason) return;
      await this.service.cancel(this.entity.id, { value: reason });
      this.navigateToList();
  }

  shipServiceChanged(): void {
      const shipService = this.shipServices.find(x => x.carrier === this.currentCarrier);
      if (shipService !== undefined) {
          this.carrierServices = shipService.services;
          this.entity.shipServiceId = this.carrierServices[0].key;
          return;
      } else {
          this.entity.shipServiceId = 0;
          this.carrierServices = [];
      }
  }

  accountChanged(): void {
    console.log("Account ID: "+this.entity.accountId);
    const account = this.accounts.find(x => x.id == this.entity.accountId);
    console.log(account.name);
    if (account.id === 2) {
      this.inserts = this.allInserts;
    }
    else {
      this.inserts = this.allInserts.filter(x => x.accountId === null || x.accountId === account.id );
    }
  }

  productChanged(): void {
      const product = this.products.find(x => x.id == this.entity.productId) as ProductVm;
      console.log(product);
      if (product !== null || product !== undefined) {
        console.log("Product ID: " + product.id + " | Name: " + product.name + " | Group: " + product.group);
      }
  }

  isUserAdmin(): boolean {
    return this.authService.isAdmin;
  }

  canUserDelete(): boolean {
    return this.entity
      && this.entity.id
      && this.authService.isAdmin
  }

  canUserCancel(): boolean {
    return this.entity
      && this.entity.id
      && (this.isCreate || this.entity.status == Enums.ShipRequestStatus.Processing)
      && this.authService.isAdmin
  }

  canUserSwitchToEditMode(): boolean {
      return this.entity && this.entity.id && this.authService.isAdmin && this.isReadOnly;
  }

  isMoreVisible(): boolean {
      return this.canUserCancel() || this.canUserSwitchToEditMode();
  }

  canUserSetStatusDone(): boolean {
      return this.entity
          && this.entity.id
          && this.authService.isAdmin
          && this.entity.status == Enums.ShipRequestStatus.Processing;
  }

  canUserUnDone(): boolean {
      return this.entity
          && this.entity.id
          && this.authService.isAdmin
          && this.entity.status == Enums.ShipRequestStatus.Done
          && !this.isReadOnly;
  }

  navigateToList(): void {
      this.router.navigate(['ship-request'])
  }

  private createEntity(init: Partial<EditEntityShipRequestVm>): EditEntityShipRequestVm {
      const entity = new EditEntityShipRequestVm(init);
      entity.contact = entity.contact || new ContactVm();
      entity.shipAddress = entity.shipAddress || new AddressVm();
      entity.transportationPayment = entity.transportationPayment || new PaymentInfoVm();
      entity.feePayment = entity.feePayment || new PaymentInfoVm()
      return entity;
  }

  private findCityStateFromZipCountry = function() {
    var min3 : any[] = ['CA','FO','GB','GG','IS','IM','JE']; // 3
    var min4 : any[] = ['AR','AU','AT','BD','BE','BG','DK','GL','NL','HU','LI','MK','NZ','NO','PH','SI','ZA','SJ','CH']; //4
    var min6 : any[] = ['CZ','IN','LU','PL','RU','SK',]; //6
    var min7 : any[] = ['MD']; //7
    var min8 : any[] = ['JP','PT']; //8
    var min9 : any[] = ['BR']; //9
    var min5 : any[] = min3.concat(min4,min6,min7,min8,min9);
    var city: string, state: string, country: any, zip: string | any[], code: string | any[];
    var data: any;
    var country_code: string[] |any[];
    city = '';
    state = '';
    zip = this.entity.shipAddress.zip;
    country_code = this.entity.shipAddress.country.split(',');
    country = country_code[0];
    code = country_code[1];

    if (!(code === null)) {
      var min : number;
  
      if (min3.includes(code)) { min = 3 }
      if (min4.includes(code)) { min = 4 }
      if (min6.includes(code)) { min = 6 }
      if (min7.includes(code)) { min = 7 }
      if (min8.includes(code)) { min = 8 }
      if (min9.includes(code)) { min = 9 }
      if (!min5.includes(code)) { min = 5 }
  
      if (zip.length >= min) {
        this.http.get('https://api.zippopotam.us/' + code + '/' + zip).toPromise().then(response => {
          if (response.status === 200) {
            data =  response.json();
            var places = data["places"][0];
            city = places["place name"];
            state = code === "US" ? places["state abbreviation"] : places["state"];

            this.entity.shipAddress.city = city;
            this.entity.shipAddress.state = state;
            }
            else {
              throw new Error(`Got back ${response.status}`);
            }
          }).catch(err => {
        });
      }
        else {
          this.entity.shipAddress.city = city;
          this.entity.shipAddress.state = state;
        }
      }
      else {
        this.entity.shipAddress.city = city;
        this.entity.shipAddress.state = state;
    }
  };

  private clear = function() {
    this.entity.shipAddress.zip = '';
    this.findCityStateFromZipCountry();
  }
}