﻿import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import { AuthService } from '../../core/services/auth.service';

import { ProductionRequestService, ProductionRequestVm, Enums, ProductionRequestsProductVm, ProductionRequestListVm, UserVm } from '../../models';
import { ProductionRequestListFilter } from './production-request-list-filter';
import { Urls } from '../../routing/urls';

@Component({
    selector: 'production-request-list',
    templateUrl: './production-request-list.component.html',
})
export class ProductionRequestListComponent implements OnInit {
  filter = new ProductionRequestListFilter();
  productionRequestList: ProductionRequestListVm;
  productionRequests: ProductionRequestVm[];
  products: ProductionRequestsProductVm[];
  users: UserVm[];

  public showOverlay = true;

  public pageSize: number = 20;

  p: number = 1;

  constructor(
      private service: ProductionRequestService,
      private router: Router,
      private authService: AuthService
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.filter.createDateFrom = new Date(new Date().getFullYear(), 0, 1);
    this.filter.createDateTo = new Date();
      this.applyFilter();
  }

  navigateToDetails(id: number): void {
    this.router.navigateByUrl(Urls.GetProductionRequestDetails(id))
  }

  async applyFilter(): Promise<void> {
    this.showOverlay = true;

      this.productionRequestList = await this.service.getListVm(this.filter.model);
      this.products = this.productionRequestList.products;
      this.productionRequests = this.productionRequestList.requests;
      this.users = this.productionRequestList.users;
      this.productionRequests.forEach(x => {
           (<any>x).isHighlighted = (x.status == Enums.ProductionRequestStatus.Created);
       })

    this.p = 1;
    this.showOverlay = false;
  }

  async resetFilter(): Promise<void> {
    this.filter = new ProductionRequestListFilter();
    this.filter.createDateFrom = new Date(new Date().getFullYear(), 0, 1);
    this.filter.createDateTo = new Date();
    
    this.showOverlay = true;

    this.productionRequestList = await this.service.getListVm(this.filter.model);
      this.products = this.productionRequestList.products;
      this.productionRequests = this.productionRequestList.requests;
      this.users = this.productionRequestList.users;
      this.productionRequests.forEach(x => {
           (<any>x).isHighlighted = (x.status == Enums.ProductionRequestStatus.Created);
       })

    this.p = 1;
    this.showOverlay = false;
  }

  getStatusName(value: Enums.ProductionRequestStatus): string {
      return Enums.ProductionRequestStatus[value];
  }

  private async setStatus(id: number, status: Enums.ProductionRequestStatus): Promise<void> {
    await this.service.setStatus(id, status);
    this.applyFilter();
  }

  canApprove(status: Enums.ProductionRequestStatus): boolean {
    return this.authService.isAdmin && status == Enums.ProductionRequestStatus.Created;
  }

  canMarkAsDone(status: Enums.ProductionRequestStatus): boolean {
    return this.authService.isAdmin && status == Enums.ProductionRequestStatus.Approved;
  }

  approve(id: number): void {
      this.setStatus(id, Enums.ProductionRequestStatus.Approved);
  }

  markAsDone(id: number): void {
      this.setStatus(id, Enums.ProductionRequestStatus.Done);
  }

}