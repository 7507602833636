import { Component } from '@angular/core';

import { ValueVm, AdminUpdateVm, AdminUpdateBm, } from '../../models';
import { EventBaseComponent } from '../event-base/event-base.component';
import { EventsService } from '../events.service';

@Component({
    selector: 'event-admin-update',
    templateUrl: './event-admin-update.component.html'
})
export class EventAdminUpdateComponent extends EventBaseComponent {
    propertyValues: ValueVm[];
    vm: AdminUpdateVm;

    constructor(public eventsService: EventsService) {
        super(eventsService);
    }

    async ngOnInit(): Promise<void> {
        this.vm = await this.eventsService.getAdminUpdateVm();
        this.propertyValues = this.vm.propertyValues
            .map(x => {
                const propertyValue = this.model.details.values.find(y => y.code == x.code);
                const result = new ValueVm(propertyValue || x);
                if (this.isDateProperty(x))
                    result.value = result.originValue;

                return result;
            })
            .sort((a, b) => a.name > b.name ? 1 : -1);
    }

    async update(): Promise<void> {
        const model = this.getSaveModel();
        if (model.propertyValues.length) {
            this.model.wait = true;
            await this.eventsService.adminUpdate(model);
            this.model.wait = false;
        }

        this.backToEvents();
    }

    isStringProperty(propertyValue: ValueVm): boolean {
        return propertyValue.type == 'String';
    }

    isDateProperty(propertyValue: ValueVm): boolean {
        return propertyValue.type == 'Date';
    }

    getSaveModel(): AdminUpdateBm {
        const propertyValues = this.propertyValues.filter(x => {
            const value = this.model.details.values.find(v => v.code == x.code);
            if (!value) return x.value;
            if (this.isDateProperty(x)) {
                const d1 = new Date(x.value);
                const d2 = new Date(value.originValue)
                return d1.getTime() !== d2.getTime();
            } 

            return value.value !== x.value;
        });
        return new AdminUpdateBm({
            sn: this.model.sn,
            propertyValues: propertyValues,
            comment: this.model.comment
        });
    }
}