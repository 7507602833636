import { Component, OnInit } from '@angular/core'
import { Router, ActivatedRoute, Params } from '@angular/router';
import { DashboardService, DashboardRecentEventVm, DashboardShipRequestVm,  DashboardProductionRequestVm, DashboardSalesOrderVm,  Enums } from '../../models';

@Component({
    selector: 'dashboard-internal',
    templateUrl: './component.html',
})
export class DashboardInternalComponent implements OnInit {
  shipRequests: DashboardShipRequestVm[];
  events: DashboardRecentEventVm[];
  productionRequests: DashboardProductionRequestVm[];
  salesOrders: DashboardSalesOrderVm[];

  public showOverlay = true;
  public page: number;
  public total: number;
  public totalPages: number;
  //public page2: number;
  public pageSize: number = 10;
  public pageSize2: number = 5;
  public pageSize3: number = 5;

  p: number = 1;
  p2: number = 1;
  p3: number = 1;

  constructor(
    private dataService: DashboardService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  async ngOnInit(): Promise<void> {
    this.showOverlay = true;
    const listVm = await this.dataService.getListVm();
    this.showOverlay = false;
    this.shipRequests = listVm.shipRequests;
    this.events = listVm.events;
    this.productionRequests = listVm.productionRequests;
    if (this.productionRequests !== undefined && this.productionRequests.length > 0) {
     this.productionRequests.forEach(x => {
        (<any>x).isHighlighted = (x.status == Enums.ProductionRequestStatus.Created);
     })
    }
    this.salesOrders = listVm.salesOrders;
    if (this.salesOrders !== undefined && this.salesOrders.length > 0) {
     this.salesOrders.forEach(x => {
        (<any>x).isHighlighted = (x.status == Enums.SalesOrderStatus.Created);
     })
    }
    this.pageSize2 = this.salesOrders !== null ? this.salesOrders.length - 1 : 1;
    this.pageSize3 = this.productionRequests !== null ? this.productionRequests.length - 1 : 1;
  }

  openShipRequestDetails(id: number): void {
    this.router.navigateByUrl('ship-request/edit/' + id)
  }

  navigateToProductionRequestDetails(id: number): void {
    this.router.navigateByUrl('production-request/detail/' + id)
  }

  navigateToSalesOrderDetails(id: number): void {
    this.router.navigateByUrl('sales-order/detail/' + id)
  }

  navigateToSerialNumberDetails(id: number): void {
    this.router.navigateByUrl('sn/detail/' + id)
  }

  navigateToRegistrationDetails(id: number): void {
    this.router.navigateByUrl('sn-registration/edit/' + id)
  }
}