﻿import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { ModalDirective } from 'ngx-bootstrap';
import { AuthService } from '../../core/services/auth.service';
import { UserPropertyGroupType } from '../../models/user-property-group-type'

import { ProductionRequestNewVm, Enums, ProductionRequestService, UserPropertyGroupService, SnDetailsVm } from '../../models/view-models';
import { DetailExt } from "./detail-ext";
import { UserPropertyGroupModel } from "../../models/user-property-group.model";

@Component({
    selector: 'production-request-detail',
    templateUrl: './production-request-detail.component.html',
})
export class ProductionRequestDetailComponent implements OnInit {
    @ViewChild('modalElement') modalElement: ModalDirective;

    properties = [{ name: '', isSelected: true }];
    model = new DetailExt();
    modelNew: ProductionRequestNewVm = new ProductionRequestNewVm();
    isBusy: boolean;

    public showOverlay = true;

    constructor(
        private service: ProductionRequestService,
        private userPropertyGroupService: UserPropertyGroupService,
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService
    ) { }

    async ngOnInit(): Promise<void> {
        this.model.id = +this.route.snapshot.params['id'];
        if (this.model.id) {
            this.load(this.model.id);
        }
        this.modelNew = await this.service.getNewVm();
    }

    async load(id: number): Promise<void> {
        this.showOverlay = true;
        
        const result = await Promise.all([
            this.service.getDetailVm(id),
            this.userPropertyGroupService.getList(UserPropertyGroupType.Details)
        ]);
        const propertyGroups = UserPropertyGroupModel.createVisibleGroups(result[1]);

        this.model = new DetailExt(result[0], propertyGroups);
        this.model.sns.sort((a, b) => b.sn > a.sn ? 1 : -1);

        if (!this.isExportEnabled) {
            this.showOverlay = false;
            return;
        }

        this.properties = this.model.sns
            .map(x => x.values)
            .reduce((allProperties, current) => allProperties.concat(current))
            .filter((x, i, allProperties) => allProperties.findIndex(y => y.name == x.name) === i)
            .map(x => x.name)
            .sort((a, b) => a > b ? 1 : -1)
            .map(x => { return { name: x, isSelected: true }; });

        this.showOverlay = false;
    }

    get isExportEnabled(): boolean {
        if (this.model.sns && this.model.sns !== undefined && typeof(this.model.sns) !== 'undefined') {
            return this.model.sns.length > 0;
        }
        else {
            return false;
        }
    }

    toggleIsSelected(property: { name: string, isSelected: boolean }): void {
        property.isSelected = !property.isSelected;
    }

    async export(): Promise<void> {
        const propertiesToExprot = this.properties.filter(x => x.isSelected).map(x => x.name);
        await this.service.export(this.model.id, propertiesToExprot);
        this.modalElement.hide();
    }

    approve(): void {
        this.setStatus(Enums.ProductionRequestStatus.Approved);
    }

    markAsDone(): void {
        this.setStatus(Enums.ProductionRequestStatus.Done);
    }

    navigateToSn(sn: SnDetailsVm): void {
        this.router.navigate(['/sn/detail/' + sn.sn]);
    }

    async deleteProductionRequest(): Promise<void> {
        await this.service.delete(this.model.id);
        this.navigateBack();
    }

    async copyProductionRequest(): Promise<void> {
        this.modelNew.comment = this.model.comment;
        this.modelNew.customerId = this.modelNew.customers.find(o => o.companyName == this.model.account).id;
        this.modelNew.dueDate = this.model.dueDate;
        this.modelNew.productId = this.modelNew.products.find(o => o.modelNo == this.model.product).id;
        this.modelNew.quantity = this.model.quantity;
        this.modelNew.po = this.model.po;
              
        this.switchToEdit(this.modelNew);
    }

    switchToEdit(model: ProductionRequestNewVm) : void {

        // console.log(model.quantity);
        // console.log(model.dueDate);
        // console.log(model.productId);
        // console.log(model.customerId);
        // console.log(model.po);
        // console.log(model.comment);

        this.router.navigate(['/productionRequest/create'], 
        {queryParams: {q: model.quantity, d: model.dueDate , p: model.productId, c: model.customerId, po: model.po, co: model.comment}});
    }


    navigateBack(): void {
        window.history.back();
    }

    canDelete(): boolean {
        return this.authService.isAdmin;
    }

    canApprove(): boolean {
        return this.authService.isAdmin && this.model.status == Enums.ProductionRequestStatus.Created;
    }

    canMarkAsDone(): boolean {
        return this.authService.isAdmin && this.model.status == Enums.ProductionRequestStatus.Approved;
    }

    getStatusName(value: Enums.ProductionRequestStatus): string {
        return Enums.ProductionRequestStatus[value];
    }

    async setVisibleToClient(): Promise<void> {
        this.isBusy = true;
        await this.service.setVisibleToClient(this.model.id, this.model.isVisibleToClient);
        this.isBusy = false;
    }

    private async setStatus(status: Enums.ProductionRequestStatus): Promise<void> {
        await this.service.setStatus(this.model.id, status);
        this.model.status = status;
    }
}