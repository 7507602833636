﻿import { NgModule } from '@angular/core';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown'

import { SharedModule } from '../shared/shared.module';
import { RmaOrderListComponent } from './rma-order-list/rma-order-list.component';
import { RmaOrderDetailComponent } from './rma-order-detail/rma-order-detail.component';
import { RmaOrderCreateComponent } from './rma-order-create/rma-order-create.component';
import { RmaOrderService } from './rma-order.service'

import {NgxPaginationModule} from 'ngx-pagination';

@NgModule({
    imports: [
        SharedModule,
        BsDropdownModule.forRoot(),
        NgxPaginationModule
    ],
    declarations: [
        RmaOrderListComponent,
        RmaOrderDetailComponent,
        RmaOrderCreateComponent,
    ],
    providers: [
        RmaOrderService
    ],
    exports: [
        RmaOrderListComponent,
        RmaOrderDetailComponent,
        RmaOrderCreateComponent,
    ],
    entryComponents: [
        RmaOrderListComponent,
        RmaOrderDetailComponent,
        RmaOrderCreateComponent,
    ]
})
export class RmaOrderModule { }