﻿import {
    SnDetailsVm,
    ValueVm
} from "../../models/view-models";
import { PropertyGroupWithValues } from "./property-group-with-values";
import { UserPropertyGroupModel } from "../../models/user-property-group.model";

export class SnDetailExt extends SnDetailsVm {
    propertyGroups: PropertyGroupWithValues[] | undefined;

    constructor(
        vm: SnDetailsVm,
        propertyGroups: UserPropertyGroupModel[] | undefined = []
    ) {
        super(vm);
        this.propertyGroups = propertyGroups
            .map(x => new PropertyGroupWithValues(x, this.values))
            .filter(x => x.propertyValues.length);
    }
}