﻿import { Component, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';

@Component({
    selector: 'modal-prompt',
    templateUrl: './modal-prompt.component.html'
})
export class ModalPromptComponent {
    @ViewChild('modal') modal: ModalDirective;
    @Input() public title: string;
    @Input() public confirmButtonName: string;
    @Input() public closeButtonName: string;
    @Input() public classes: string;
    @Input() public message: string;

    @Output() private confirm = new EventEmitter<string>();

    userText: string;

    show(message?: string): void {
        if (message) this.message = message;
        this.modal.show();
    }

    public doConfirm(): void {
        this.modal.hide();
        this.confirm.emit(this.userText);
    }
}