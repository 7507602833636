﻿import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'

import { AuthService } from '../../core/services/auth.service'

@Component({
  selector: 'dashboard-main',
  templateUrl: './dashboard-main.component.html',
})
export class DashboardMainComponent implements OnInit {
  isExternal: boolean
  isInternal: boolean

  public showOverlay = true;

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit() {
    this.isExternal = this.authService.isExternal
    this.isInternal = this.authService.isInternal
    if (!this.isExternal && !this.isInternal) {
      this.showOverlay = true;
      this.router.navigateByUrl('sign-in')
      this.showOverlay = false;
    }    
  }
}