﻿import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Enums } from '../../models';
import { RmaOrderService } from '../rma-order.service'
import { RmaOrderDetailVm } from './rma-order-detail.vm';
import { RmaOrderVm } from './rma-order.vm';

@Component({
    selector: 'rma-order-detail',
    templateUrl: './rma-order-detail.component.html',
})
export class RmaOrderDetailComponent implements OnInit {
    model = new RmaOrderDetailVm();

    public showOverlay = true;

    constructor(
        private dataService: RmaOrderService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
    ) { }

    async ngOnInit(): Promise<void> {
        this.model.rmaOrderDetail = new RmaOrderVm();
        this.model.rmaOrderDetail.id = +this.activatedRoute.snapshot.params['id'];
        if (this.model.rmaOrderDetail.id) {
            this.load(this.model.rmaOrderDetail.id);
        }
    }

    async load(id: number): Promise<void> {
        this.showOverlay = true;
        this.model = await this.dataService.getDetailVm(id);
        this.showOverlay = false;
    }

    navigateToSn(id: number): void {
        this.router.navigate(['/sn/'+id]);
    }

    async deleteRmaOrder(): Promise<void> {
        let confirmation = confirm("Are you sure you want to delete this RMA?")
        if (confirmation) {
        await this.dataService.delete(this.model.rmaOrderDetail.id);
        this.navigateBack();
        }
    }

    async markAsDone(): Promise<void> {
        await this.dataService.markAsDone(this.model.rmaOrderDetail.id);
        this.navigateBack();
    }

    navigateBack(): void {
        this.router.navigate(['/rmaOrders']);
    }

    getStatusName(value: Enums.RmaOrderStatus): string {
        return Enums.RmaOrderStatus[value];
    }

    isCreateShipRequestVisible(): boolean {
        return this.model.snDetail && this.model.snDetail.sn && this.model.rmaOrderDetail.status == Enums.RmaOrderStatus.Created;
    }

    navigateToCreateShipRequest(): void {
        this.router.navigate(['/ship-request/create']);
    }
}