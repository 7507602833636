﻿import { Component, OnInit, ViewChild } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { Router, ActivatedRoute } from '@angular/router'

import { SnRegistrationService } from '../sn-registration.service'
import { Urls } from 'src/app/routing/urls';
import { SnRegistrationsViewVm } from '../sn-registration-view.vm';

@Component({
    selector: 'sn-registration-edit',
    templateUrl: './sn-registration-edit.component.html',
})
export class SnRegistrationEditComponent implements OnInit {
    @ViewChild('formElement') formElement: FormGroup;
    model: any;

    serverValidationError: string

    constructor(
        private service: SnRegistrationService,
        private route: ActivatedRoute,
        private router: Router
    ) { }

    async ngOnInit(): Promise<void> {
        const id = +this.route.snapshot.params['id'] || 0
        this.model = await this.service.getVm(id)
    }

    navigateBack(): void {
        this.router.navigateByUrl(Urls.SnRegistrationList)
    }
}