﻿import { Component, OnInit, Input } from '@angular/core';

import { SoftwareVm, SoftwareService } from '../../models';

@Component({
    selector: '[software-options]',
    templateUrl: './software-options.component.html',
})
export class SoftwareOptionsComponent implements OnInit {
    @Input('ngModel') selectedId: number;

    public softwares: Array<SoftwareVm>;

    constructor(private softwareDataService: SoftwareService) { }

    ngOnInit(): void {
        this.softwareDataService
            .getListVm()
            .then(softwares => this.softwares = softwares);
    }
}
