import { Component } from '@angular/core';

import * as models from '../../models';
import { EventBaseComponent } from '../event-base/event-base.component';
import { EventsService } from '../events.service';

@Component({
    selector: '<event-change-product>',
    templateUrl: './event-change-product.component.html'
})
export class EventChangeProductComponent extends EventBaseComponent {
    products: models.KeyValuePair<number, string>[];
    product: models.KeyValuePair<number, string>;

    constructor(public eventsService: EventsService) {
        super(eventsService);
    }

    ngOnInit(): void {
        this.model.wait = true;

        this.eventsService
            .getChangeProductVm()
            .then(res => {
                this.products = res;
                this.model.wait = false;
            })
            .catch(error => {
                this.model.wait = false;
                this.model.error = error;
            });
    }

    public isValid(): boolean {
        return !this.model.wait && !!this.product;
    }

    public changeProduct(): void {
        this.model.wait = true;

        const data = new models.ChangeProductBm();
        data.comment = this.model.comment;
        data.productId = this.product.key;
        data.sn = this.model.sn;

        this.eventsService
            .changeProduct(data)
            .then(() => {
                this.backToEvents();
            })
            .catch(error => {
                this.model.wait = false;
                this.model.error = error;
            });
    }
}