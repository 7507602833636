import { Component } from '@angular/core';

import * as models from '../../models';
import { EventBaseComponent } from '../event-base/event-base.component';
import { EventsService } from '../events.service';

@Component({
    selector: '<event-add-to-sales-order>',
    templateUrl: './event-add-to-sales-order.component.html'
})
export class EventAddToSalesOrderComponent extends EventBaseComponent {
    public custom = { orders: [{}], orderId: 0, error: '', comment: '' };

    constructor(public eventsService: EventsService) {
        super(eventsService);
    }

    ngOnInit(): void {
        this.model.wait = true;

        this.eventsService
            .getAddToSalesOrderVm(this.model.sn)
            .then(res => {
                this.custom.orders = res.orders;
                if (!res.orders.length) {
                    this.custom.error = 'There is no sales order for selected Serial Number.';
                }
                this.model.wait = false;
            })
            .catch(error => {
                this.model.wait = false;
                this.model.error = error;
            });
    }

    public addToSalesOrder(): void {
        this.model.wait = true;

        const data = new models.AddToSalesOrderBm();
        data.comment = this.custom.comment;
        data.orderId = this.custom.orderId;
        data.sn = this.model.sn;

        this.eventsService
            .addToSalesOrder(data)
            .then(() => {
                this.backToEvents();
            })
            .catch(error => {
                this.model.wait = false;
                this.model.error = error;
            });
    }
}