import { Component, OnInit } from '@angular/core';

import * as models from '../../models';
import { LogRecordService } from './log-record.service'

@Component({
    selector: 'settings-log-record',
    templateUrl: './log-record.component.html',
    providers: [LogRecordService]
})
export class LogRecordComponent implements OnInit{
  private logSeverityEnum = models.Enums.LogSeverity;
  private filter: models.LogItemFilter;
  public records: Array<models.LogRecordVm>;
  public localsetting = {
      filtervisible: false,
      showfilterbuttonlable: "Show Filter"
  };
  private selectedSeverityKey: string;
  private severityKeysForFilter: string[];
  private SEVERITY_FILTER_ALL_KEY:string = "0";

  constructor(private logRecordService: LogRecordService) {}

  ngOnInit(): void {
      this.severityKeysForFilter = Object.keys(this.logSeverityEnum).filter(Number);
      this.makeEmptyFilter();
      this.reloadList();
  }

  reloadList():void{
    this.logRecordService
    .getLogRecords(this.filter)
        .then(records => { this.records = records; }, error => console.log(error))
  }

  sortByTime(askDesc: string) {
      if (askDesc === "asc")
          this.filter.orderByTimestampAsc = true;
      else
          this.filter.orderByTimestampAsc = false;
      this.reloadList();
  }    

  clear(element: string) {
      if (element === "Message") {
          this.filter.message = "";
      }
      if (element === "StackTrace") {
          this.filter.stackTrace = "";
      }
      if (element === "timestampfrom") {
          this.filter.timestampFrom = null;
      }
      if (element === "timestampto") {
          this.filter.timestampTo = null;
      }
      this.reloadList();
  }   

  changeSeverity() {
      if (this.selectedSeverityKey === this.SEVERITY_FILTER_ALL_KEY) {
          this.filter.severityAll = true;
      }
      else {
          this.filter.severityAll = false;
          this.filter.severity = this.logSeverityEnum[this.selectedSeverityKey];
      }
      this.reloadList();
  }    

  makeEmptyFilter() {
      this.filter = {
          severityAll: true
      } as models.LogItemFilter
  }

  clickShowFilter() {
      if (this.localsetting.filtervisible) {
          this.makeEmptyFilter();
          this.localsetting.filtervisible = false;
          this.localsetting.showfilterbuttonlable = "Show Filter";
          this.reloadList();
      }
      else {
          this.localsetting.filtervisible = true;
          this.localsetting.showfilterbuttonlable = "Remove Filter";
      }
  }
}