import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'

import * as models from '../../../models';
import { ExtEventsService } from '../events.service';
import { ExtEventMainModel } from './event-main.model';
import { UserPropertyGroupModel } from "../../../models/user-property-group.model"

const _model = new ExtEventMainModel()

@Component({
    selector: '<event-main>',
    templateUrl: './event-main.component.html',
    styleUrls: ['./event-main.component.scss']
})
export class ExtEventMainComponent implements OnInit {
    private propertyGroups: UserPropertyGroupModel[];
    model: ExtEventMainModel;
    notEmptyGroups: UserPropertyGroupModel[];
    jsonForm: models.JsonForm;
    private finsSnRx = new Subject<string>();

    public showOverlay = true;

    constructor(
        private eventsService: ExtEventsService,
        private titleService: Title,
        private route: ActivatedRoute,
    ) {
        this.model  = _model
    }

    askToFindSn() {
      const sn = this.model.sn
      this.finsSnRx.next(sn);
    }

    ngOnInit(): void {
        const routeSn = this.route.snapshot.params['id'] || ''
        this.model.sn = routeSn || this.model.sn
        const that = this

        this.finsSnRx.pipe(
          debounceTime(400),
          distinctUntilChanged()
        ).subscribe((sn) => {
          that.findSn()
        })
        this.findSn()
    }

    selectSn(sn: { sn: string }): void {
        this.model.sn = sn.sn;
        this.findSn();
    }

    async findSn(): Promise<void> {
        this.showOverlay = true;

        const data = new models.FindSnArgs({ sn: this.model.sn })
        const result = await this.eventsService.findSn(data)
        this.model.update(result);
        this.titleService.setTitle('SensorTrack' + (this.model.sn ? `: ${this.model.sn}` : ''));
        this.propertyGroups = UserPropertyGroupModel.createVisibleGroups(result.propertyGroups);
        this.notEmptyGroups = this.propertyGroups.filter(x => this.isAnyPropertyInGroup(x));

        this.showOverlay = false;
    }

    backToEvents(): void {
        this.jsonForm = null;
        this.findSn();
    }

    getPropertiesByGroup(group: UserPropertyGroupModel): models.ValueVm[] {
        return this.model.details.values.filter(x => group.properties.find(y => y.code === x.code));
    }

    private isAnyPropertyInGroup(group: UserPropertyGroupModel): boolean {
        if (!this.model.details.values) return false;
        return this.model.details.values.some(x => group.properties.some(y => y.code === x.code));
    }
}