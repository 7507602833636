﻿import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { ExternalProfileSaveVm } from '../../models';

import { Util } from '../../tools/util'
import { ExternalProfileService } from './service';

@Component({
    selector: 'ext-profile-edit',
    templateUrl: './component.html',
})
export class ExtProfileEditComponent implements OnInit {
    @ViewChild('formElement') formElement: FormGroup;

    public entity: ExternalProfileSaveVm;

    constructor(
        private service: ExternalProfileService
    ) { }

    async ngOnInit(): Promise<void> {
        this.entity = await this.service.getEditVm();
    }

    async onSubmit(): Promise<void> {
        if (this.formElement.invalid) {
            return;
        }
        await this.service.save(this.entity);
        this.navigateBack();
    }

    generateNewApiToken() {
        this.entity.apiToken = Util.generateUuid().replace("-", "");
    }

    navigateBack(): void {
        window.history.back();
    }
}