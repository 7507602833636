import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common'
import { FormsModule }   from '@angular/forms'

import { ErrorServerComponent } from './server.component'
import { ErrorMxdLinkExpiredComponent } from './mxd-link-expired.component'
import { ErrorFileNotFoundComponent } from './file-not-found.component'

@NgModule({
  imports: [
    CommonModule,
    FormsModule
  ],
  declarations: [
    ErrorServerComponent,
    ErrorMxdLinkExpiredComponent,
    ErrorFileNotFoundComponent
  ],
  exports: [
    ErrorServerComponent,
    ErrorMxdLinkExpiredComponent,
    ErrorFileNotFoundComponent
  ],
  entryComponents: [
    ErrorServerComponent,
    ErrorMxdLinkExpiredComponent,
    ErrorFileNotFoundComponent
  ]
})
export class ErrorModule { }