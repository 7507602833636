﻿import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { UserListComponent } from './user-list/user-list.component';
import { UserEditComponent } from './user-edit/user-edit.component';
import { UserService } from './user.service';

@NgModule({
    imports: [
        SharedModule
    ],
    providers: [
        UserService
    ],
    declarations: [
        UserListComponent,
        UserEditComponent
    ],
    exports: [
        UserListComponent,
        UserEditComponent
    ],
    entryComponents: [
        UserListComponent,
        UserEditComponent
    ]
})
export class UserModule { }