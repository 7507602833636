import { Injectable } from "@angular/core"
import { Http } from "@angular/http"
import { BaseService } from "../../core/services/base.service"
import { ExternalRegisterSnVm } from "../../models";
import { SnRegistrationResult } from "./SnRegistrationResult";

@Injectable()
export class ExtSnRegistrationService extends BaseService
{
	constructor (http: Http)
	{
		super(http);
	}
	public register(vm: ExternalRegisterSnVm) : Promise<SnRegistrationResult>
	{
		return this.post<SnRegistrationResult>(this.baseUri + `register`, vm);
	}
	private baseUri: string = 'ext/sn-registration/';
}