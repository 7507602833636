import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common'
import { FormsModule }   from '@angular/forms'

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { SharedModule } from '../shared/shared.module';

import { ExtSnRegistrationComponent } from './sn-registration/component';
import { ExtSnRegistrationService } from './sn-registration/service';
import { ExtProfileEditComponent } from './profile-edit/component';
import { ExternalProfileService } from './profile-edit/service';
import { ExtEventMainComponent } from './events/event-main/event-main.component';
import { ExtEventsService } from './events/events.service';
import { NgxPaginationModule } from 'ngx-pagination';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    BsDropdownModule.forRoot(),
    SharedModule,
    NgxPaginationModule
  ],
  declarations: [
    ExtSnRegistrationComponent,
    ExtProfileEditComponent,
    ExtEventMainComponent
  ],
  providers: [
    ExtSnRegistrationService,
    ExternalProfileService,
    ExtEventsService
  ],
  exports: [
    ExtSnRegistrationComponent,
    ExtProfileEditComponent,
    ExtEventMainComponent
  ],
  entryComponents: [
    ExtSnRegistrationComponent,
    ExtProfileEditComponent,
    ExtEventMainComponent
  ]
})
export class ExtModule { }