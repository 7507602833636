import { Injectable } from "@angular/core"
import { Http } from "@angular/http"
import { BaseService } from "../core/services/base.service";
import { UserGroupVm, EditUserGroupVm, UserExVm, SaveResult, UserGroupUserVm } from "../models";

@Injectable()
export class UserGroupService extends BaseService
{
	constructor (http: Http)
	{
		super(http);
	}
	public getListVm() : Promise<UserGroupVm[]>
	{
		const uriParams = {  };
		return this.get<UserGroupVm[]>(this.baseUri + `GetListVm`, uriParams);
	}
	public getEditVm(id: number) : Promise<EditUserGroupVm>
	{
		const uriParams = {  };
		return this.get<EditUserGroupVm>(this.baseUri + `GetEditVm/${id}`, uriParams);
	}
	public getUsersVm() : Promise<UserExVm[]>
	{
		const uriParams = {  };
		return this.get<UserExVm[]>(this.baseUri + `GetUsersVm`, uriParams);
	}
	public save(model: EditUserGroupVm) : Promise<SaveResult>
	{
		const uriParams = {  };
		return this.post<SaveResult>(this.baseUri + `Save`, model, uriParams);
	}
	public delete(id: number) : Promise<SaveResult>
	{
		const uriParams = {  };
		return this.post<SaveResult>(this.baseUri + `Delete/${id}`, null, uriParams);
	}
	public includeUser(model: UserGroupUserVm) : Promise<SaveResult>
	{
		const uriParams = {  };
		return this.post<SaveResult>(this.baseUri + `IncludeUser`, model, uriParams);
	}
	public excludeUser(model: UserGroupUserVm) : Promise<SaveResult>
	{
		const uriParams = {  };
		return this.post<SaveResult>(this.baseUri + `ExcludeUser`, model, uriParams);
	}
	private baseUri: string = 'userGroup/';
}