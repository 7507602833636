﻿import { Component, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { SaveProfileVm, ProfileService } from '../../models';

@Component({
    selector: 'profile-edit',
    templateUrl: './profile-edit.component.html',
})
export class ProfileEditComponent {
    @ViewChild('formElement') formElement: FormGroup;

    public model = new SaveProfileVm();

    constructor(
        private dataService: ProfileService
    ) { }

    async onSubmit(): Promise<void> {
        if (this.formElement.invalid) {
            return;
        }
        await this.dataService.save(this.model);
        this.navigateBack();
    }

    navigateBack(): void {
        window.history.back();
    }
}