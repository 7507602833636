import { Component } from '@angular/core';

import * as models from '../../models';
import { EventBaseComponent } from '../event-base/event-base.component';
import { EventsService } from '../events.service';

@Component({
    selector: '<event-add-to-shipment-request>',
    templateUrl: './event-add-to-shipment-request.component.html'
})
export class EventAddToShipmentRequestComponent extends EventBaseComponent {
    public shipRequests: any[];
    public selectedShipRequest: any;

    constructor(public eventsService: EventsService) {
        super(eventsService);
    }

    ngOnInit(): void {
        this.model.wait = true;

        this.eventsService
            .getAddToShipRequestVm(this.model.sn)
            .then(res => {
                
                console.log("Response object:", res);
                
                if (!res.shipRequests === undefined) {
                    if (!res.shipRequests.length) {
                        this.model.error = "There is no shipment requests for selected Serial Number.";
                    }
                }

                this.shipRequests = res.shipRequests;
                this.model.wait = false;
            })
            .catch(error => {
                this.model.wait = false;
                this.model.error = error;
            });
    }

    public canAddToShipRequest(): boolean {
        return !this.model.wait && !this.model.error && !!this.selectedShipRequest;
    }

    public selectShipRequest(shipRequestToSelect: any): void {
        this.selectedShipRequest = shipRequestToSelect;
    }

    public addToShipRequest(): void {
        const data = new models.AddToShipRequestBm();
        data.comment = this.model.comment;
        data.shipRequestId = this.selectedShipRequest.id;
        data.sn = this.model.sn;

        this.model.wait = true;

        this.eventsService
            .addToShipRequest(data)
            .then(() => {
                this.backToEvents();
            })
            .catch(error => {
                this.model.wait = false;
                this.model.error = error;
            });
    }
}
