import { Http, Headers, RequestOptionsArgs, Response, URLSearchParams, RequestOptions, ResponseContentType } from '@angular/http';
import { FileResult } from '../../models/file-result';
import { ResponseException } from "../../models/response-exception";
import { getToken, remove } from '../../tools/auth-storage'
import { Config } from '../config'

// var fileSaver = require('file-saver');

export class BaseService {
  constructor(private http: Http) { }

  protected get<T>(url: string, uriParameters?: {}): Promise<T> {
    const options = this.createOptions(uriParameters);
    
    url = this.normalizeUrl(url)
    return this.http.get(url, options)
      .toPromise()
      .then(response => this.castResponse<T>(response, options))
      .catch(this.handleError.bind(this));
  }

  public post<T>(url: string, body: any, uriParameters?: {}): Promise<T> {
      const options = this.createOptions(uriParameters);

      return this.http.post(this.normalizeUrl(url), body, options)
          .toPromise()
          .then(response => this.castResponse<T>(response, options))
          .catch(this.handleError.bind(this));
  }

  public upload<T>(url: string, body: any, uriParameters?: {}): Promise<T> {
      const options = this.createOptions(uriParameters, { responseType: ResponseContentType.Blob });

      return this.http.post(this.normalizeUrl(url), body, options)
          .toPromise()
          .then(response => this.castResponse<T>(response, options))
          .catch(this.handleError.bind(this));
  }

  public download<T>(url: string, uriParameters?: {}): Promise<T> {
    const options = this.createOptions(uriParameters, { responseType: ResponseContentType.Blob });

    url = this.normalizeUrl(url)
    return this.http.get(url, options)
        .toPromise()
        .then(response => this.castResponse<T>(response, options))
        .catch(this.handleError.bind(this));
  }

  public sendDeleteReqeuest<T>(url: string, uriParameters?: {}, options?: Partial<RequestOptionsArgs>): Promise<T> {
      options = this.createOptions(uriParameters, options);

      return this.http.delete(this.normalizeUrl(url), options)
          .toPromise()
          .then(response => this.castResponse<T>(response, options))
          .catch(this.handleError.bind(this));
  }

  private createOptions<T>(uriParameters?: {}, options?: Partial<RequestOptionsArgs>): RequestOptionsArgs {
    const result = new RequestOptions(options);
    result.search = this.getSearchParameters(uriParameters);
    result.headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8', 'Data-Type': 'json' })
    
    const token = getToken()
    if (token) {
      result.headers.append('Authorization', `Bearer ${token}`)
    }
    return result;
  }

  private castResponse<T>(response: Response, options: RequestOptionsArgs): T {
      if (options && options.responseType === ResponseContentType.Blob) {
          const fileName = decodeURIComponent(response.headers.get('fileName'));
          const fileResult = new FileResult(fileName, response.blob());
          this.saveAsFile(fileResult);
          return;
      }
      if (options && options.responseType === ResponseContentType.Text) {
          throw 'Unexpected text response'
          //return response.text();
      }
      return this.parseJson(response);
  }

  private saveAsFile(fileResult: FileResult): void {
      // fileSaver.saveAs(fileResult.data, fileResult.fileName);
  }

  private handleError(error: Response | any) {
      if(error.status === 401) {
          remove()
      }
      const exception = new ResponseException(error);
      return Promise.reject(exception);
  }

  private getSearchParameters(uriParameters: {}): URLSearchParams {
      const searchParams = new URLSearchParams();
      if (!uriParameters || typeof (uriParameters) === 'function') {
          return searchParams;
      }

      const toString = {}.toString;
      if (toString.call(uriParameters) === '[object Object]') {
          for (let key in uriParameters) {
              if ((uriParameters as Object).hasOwnProperty(key)) {
                  searchParams.set(key, uriParameters[key]);
              }
          }
      } else if (toString.call(uriParameters) === '[object Array]') {
          (uriParameters as any[]).forEach(item => {
              searchParams.setAll(this.getSearchParameters(item));
          });
      }
      return searchParams;
  }

  private parseJson(response: Response): any {
      let result = "";
      if (typeof response.text === "function") {
          result = response.text();
      } else {
          result = response.toString();
      }
      if (result.indexOf('{') === 0 || result.indexOf('[') === 0) {
          return JSON.parse(result);
      }
      return result;
  }

  private normalizeUrl(url: string): string {
      if (url.indexOf('/') === 0) return url.substr(1, url.length - 1);
      return Config.apiUrl + url
  }
}