import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs';

import { SignInVm } from './sign-in-vm';
import { SignInResult } from './sign-in-result';
import { Config } from '../core/config'

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
}

@Injectable()
export class SignInService {
  constructor(private http: HttpClient) { }

  signIn (vm: SignInVm): Observable<SignInResult> {
    const url = Config.apiUrl + `auth/Authenticate`;
    return this.http.post<SignInResult>(url, vm, httpOptions)
  }
}