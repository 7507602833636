﻿import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';

import { ProductVm } from '../../models';
import { ProductService } from '../product-service';

@Component({
    selector: 'product-list',
    templateUrl: './product-list.component.html',
})
export class ProductListComponent implements OnInit {
    list: ProductVm[];

    constructor(
        private dataService: ProductService,
        private router: Router
    ) {
    }

    async ngOnInit(): Promise<void> {
        this.list = await this.dataService.load();
    }

    navigateToEdit(id: number): void {
        this.router.navigate(['/product/edit/' + id])
    }

}