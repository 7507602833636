﻿import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router'
import { CommonModule, Location, LocationStrategy, HashLocationStrategy } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpModule } from "@angular/http";
import { CustomFormsModule } from 'ng2-validation'

import { ModalModule } from 'ngx-bootstrap';

import { PropertyValueComponent } from './components/property-value/property-value.component';
import { ExtPropertyValueComponent } from './components/property-value/ext-property-value.component';
import { DateValueAccessorDirective } from './directives/date-value-accessor.directive';
import { DebounceDirective } from './directives/debounce.directive';
import { AppRouterLinkDirective } from './directives/app-router-link.directive';
import { ClickStopPropagationDirective } from './directives/click-stop-propagation.directive';
import { ModalMessageComponent } from './components/modal-message/modal-message.component';
import { ModalConfirmComponent } from './components/modal-confirm/modal-confirm.component';
import { ModalPromptComponent } from './components/modal-prompt/modal-prompt.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        HttpModule,
        CustomFormsModule,
        RouterModule,
        ModalModule.forRoot(),
    ],
    declarations: [
        PropertyValueComponent,
        ExtPropertyValueComponent,
        DateValueAccessorDirective,
        DebounceDirective,
        AppRouterLinkDirective,
        ClickStopPropagationDirective,
        ModalMessageComponent,
        ModalConfirmComponent,
        ModalPromptComponent,
    ],
    providers: [
        Location,
        { provide: LocationStrategy, useClass: HashLocationStrategy }
    ],
    exports: [
        CommonModule,
        FormsModule,
        HttpModule,
        CustomFormsModule,
        RouterModule,

        ModalModule,

        PropertyValueComponent,
        ExtPropertyValueComponent,
        DateValueAccessorDirective,
        DebounceDirective,
        AppRouterLinkDirective,
        ClickStopPropagationDirective,
        ModalMessageComponent,
        ModalConfirmComponent,
        ModalPromptComponent,
    ],
    entryComponents: [
        PropertyValueComponent,
        ExtPropertyValueComponent,
        ModalMessageComponent,
        ModalConfirmComponent,
        ModalPromptComponent,
    ]
})
export class SharedModule { }
