﻿import { Component, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';

@Component({
    selector: 'modal-confirm',
    templateUrl: './modal-confirm.component.html'
})
export class ModalConfirmComponent {
    @ViewChild('modal') modal: ModalDirective;
    @Input() public title: string;
    @Input() public confirmButtonName: string;
    @Input() public classes: string;

    @Input() public message: string;
    @Output() private confirm = new EventEmitter();

    show(message?: string): void {
        if (message) this.message = message;
        this.modal.show();
    }

    public doConfirm(): void {
        this.modal.hide();
        this.confirm.emit();
    }
}