import { EventFlowVm, SnVm, SnDetailsVm } from "../../models";
import { PropertyGroupVm } from "./property-group.vm";

export class FindResultVm
{
	constructor (init?: Partial<FindResultVm>)
	{
		Object.assign(this, init);
		
	}
	public canUndo: boolean;
	public flows: EventFlowVm[];
	public auxiliaryFlows: EventFlowVm[];
	public snForSelect: SnVm[];
	public propertyGroups: PropertyGroupVm[];
	public details: SnDetailsVm;
}