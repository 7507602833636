﻿import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap';
import { ActivatedRoute, Router } from '@angular/router'

import {
    EditEntityShipRequestVm,
    ShipServiceVm,
    KeyValuePair,
    Shipment,
    Country,
    ContactVm,
    AddressVm,
    PaymentInfoVm,
    ListItem,
    SaveShipRequestVm,
    Enums,
    ShipmentRequestsProductVm,
    ProductVm,
    ExternalEditShipRequestVm,
    CornerTypeVm,
    InsertTypeVm
} from '../../../models'

import { Http } from '@angular/http';
import { HttpResponse } from '@angular/common/http';

import { ModalConfirmComponent } from '../../../shared/components/modal-confirm/modal-confirm.component'
import { ExtShipRequestService } from '../ext-ship-request.service';

@Component({
    selector: 'ship-request-edit',
    templateUrl: './component.html',
})
export class ExtShipRequestEditComponent implements OnInit {
    @ViewChild('deleteDialogElement') deleteDialogElement: ModalConfirmComponent;
    @ViewChild('saveDialogElement') saveDialogElement: ModalDirective;
    @ViewChild('cancelDialogElement') cancelDialogElement: ModalDirective;
    @ViewChild('formElement') formElement: FormGroup;

    isBusy: boolean;
    isReadOnly: boolean;
    rmaOrderId: number;
    entity: EditEntityShipRequestVm;
    statuses: KeyValuePair<number, string>[];
    billTransportationToListItems: ListItem[];
    billDutiesToListItems: ListItem[];
    shipments: Shipment[];
    countries: Country[]
    keyName: string;
    errorMessage: string;
    isCreate: boolean;
    _shipServices: ShipServiceVm[];
    currentCarrier: string;
    carrierServices: KeyValuePair<number, string>[];
    serverError: string = '';
    products: ShipmentRequestsProductVm[];
    product: ProductVm;
    corners: CornerTypeVm[];
    inserts: InsertTypeVm[];

    public showOverlay = false;

    constructor(
        private service: ExtShipRequestService,
        private route: ActivatedRoute,
        private router: Router,
        private http: Http
    ) {
    }

    async ngOnInit(): Promise<void> {
        this.corners = [{id: 0, name: ''}];
        this.inserts = [{id: 0, name: '', accountId: null}];
        this.products = [{id: 0, modelNo: ''}];
        this.shipServices = [{carrier: '', isDomestic: null, services: []}];
        this.billDutiesToListItems = [{id: 0, name: '', isDeleted: false, value: '', isDefault: true, listType: ''}];
        this.billTransportationToListItems = [{id: 0, name: '', isDeleted: false, value: '', isDefault: true, listType: ''}];
        this.LoadEntity();
    }

    async LoadEntity(): Promise<void> {
        this.showOverlay = true;

        const id = +this.route.snapshot.params['id'] || 0;
        const shipmentId = +this.route.snapshot.queryParams['shipmentId'] || null;
        
        let res: ExternalEditShipRequestVm
        if (shipmentId) {
            res = await this.service.getEditVmByShipmentId(shipmentId);
          }
          else {
            res = await this.service.getEditVm(id);
          }

        this.isReadOnly = res.isReadOnly;
        this.shipServices =  this.shipServices.concat(res.shipServices);
        this.statuses = res.statuses;
        this.billTransportationToListItems = this.billTransportationToListItems.concat(res.billTransportationToListItems);
        this.billDutiesToListItems = this.billDutiesToListItems.concat(res.billDutiesToListItems);
        this.shipments = res.shipments;
        this.countries = res.countries;
        this.product = res.product;
        this.products = this.products.concat(res.products);
        this.entity = this.createEntity(res.entity);
        this.isCreate = this.entity.status === Enums.ShipRequestStatus.Created;
        this.corners = res.cornerTypes;
        this.inserts = res.insertTypes;

        if (res.entity.shipServiceId !== null) {
            this.setCarrierAndShipServiceType(res.entity.shipServiceId);
        }
        if (!this.entity.id) {
            this.entity.transportationPayment.payer = this.billTransportationToListItems.find(x => x.isDefault).value;
            this.entity.feePayment.payer = this.billDutiesToListItems.find(x => x.isDefault).value;
        }
        this.entity.statusName = this.statuses.find(status => status.key == this.entity.status).value;

        this.showOverlay = false;
    }

    async setCarrierAndShipServiceType(shipServiceId: number): Promise<void> {
        if (shipServiceId === null) {
            this.currentCarrier = null;
            this.carrierServices = [];
            return;
        }

        this.shipServices.some(carrier => {
            return carrier.services.some(carrierService => {
                if (carrierService.key === shipServiceId) {
                    this.currentCarrier = carrier.carrier;
                    this.carrierServices = carrier.services;
                    this.entity.shipServiceId = carrierService.key;
                    return true;
                }
                return false;
            });
        });
    }

    get shipServices(): ShipServiceVm[] {
        if (!this.entity || !this.entity.shipAddress || this.entity.shipAddress.country === "" || this.entity.shipAddress.country === undefined) {
            return [];
        }

        var country = this.countries.filter(country => country.name+","+country.code === this.entity.shipAddress.country)[0];
        if (!country) {
            return [];
        }
        return this._shipServices.filter(x => x.isDomestic == country.isHome);
    }

    set shipServices(list: ShipServiceVm[]) {
        this._shipServices = list.slice(0);
    }

    showSaveDialog(): void {
        this.saveDialogElement.show();
    }

    closeSaveDialog(): void {
        this.saveDialogElement.hide();
    }

    async save(): Promise<void> {
        //console.log(this.product.id);
        console.log(this.entity.numberOfVelcrosHooks + " | " + this.entity.numberOfVelcrosLoops);
        if (this.isAnyError()) {
            return;
        }
        this.serverError = '';
        this.isBusy = true;

        const saveVm = new SaveShipRequestVm(this.entity);
        saveVm.rmaOrderId = this.rmaOrderId;
        await this.service.save(saveVm).catch(e => this.serverError = e);

        if (!this.serverError)
            this.navigateToList();
    }

    isAnyError(): boolean {
        return this.formElement.invalid || this.isAnyRequiredError();
    }

    isAnyRequiredError(): boolean {
        return Object
            .keys(this.formElement.controls)
            .some(key => this.formElement.hasError('required', [key]));
    }

    showCancelDialog(): void {
        this.cancelDialogElement.show();
    }

    async cancel(reason: string): Promise<void> {
        if (!reason) return;
        await this.service.cancel(this.entity.id, reason);
        this.navigateToList();
    }

    productChanged(): void {
        const product = this.products.find(x => x.id == this.entity.productid);
        //console.log("Product ID / Name: " + product.id + " / " + product.modelNo);
      }

    shipServiceChanged(): void {
        const shipService = this.shipServices.find(x => x.carrier == this.currentCarrier);
        if (shipService !== undefined) {
            this.carrierServices = shipService.services;
            this.entity.shipServiceId = this.carrierServices[0].key;
            return;
        } else {
            this.entity.shipServiceId = 0;
            this.carrierServices = [];
        }
    }

    canUserCancel(): boolean {
        return this.entity
            && this.entity.id
            && (this.isCreate || this.entity.status == Enums.ShipRequestStatus.Created)
    }

    isMoreVisible(): boolean {
        return this.canUserCancel()
    }

    navigateToList(): void {
        this.router.navigate(['ext/ship-request'])
    }

    private createEntity(init: Partial<EditEntityShipRequestVm>): EditEntityShipRequestVm {
        const entity = new EditEntityShipRequestVm(init);
        entity.contact = entity.contact || new ContactVm();
        entity.shipAddress = entity.shipAddress || new AddressVm();
        entity.transportationPayment = entity.transportationPayment || new PaymentInfoVm();
        entity.feePayment = entity.feePayment || new PaymentInfoVm()
        return entity;
    }

    private findCityStateFromZipCountry = function() {
        var min3 : any[] = ['CA','FO','GB','GG','IS','IM','JE']; // 3
        var min4 : any[] = ['AR','AU','AT','BD','BE','BG','DK','GL','NL','HU','LI','MK','NZ','NO','PH','SI','ZA','SJ','CH']; //4
        var min6 : any[] = ['CZ','IN','LU','PL','RU','SK',]; //6
        var min7 : any[] = ['MD']; //7
        var min8 : any[] = ['JP','PT']; //8
        var min9 : any[] = ['BR']; //9
        var min5 : any[] = min3.concat(min4,min6,min7,min8,min9);
        var city: string, state: string, country: any, zip: string | any[], code: string | any[];
        var data: any;
        city = '';
        state = '';
        zip = this.entity.shipAddress.zip;
        var country_code = this.entity.shipAddress.country.split(',');
        country = country_code[0];
        code = country_code[1];
    
        if (!(code === null)) {
          var min : number;

          if (min3.includes(code)) { min = 3 }
          if (min4.includes(code)) { min = 4 }
          if (min6.includes(code)) { min = 6 }
          if (min7.includes(code)) { min = 7 }
          if (min8.includes(code)) { min = 8 }
          if (min9.includes(code)) { min = 9 }
          if (!min5.includes(code)) { min = 5 }

          if (zip.length >= min) {
            this.http.get('https://api.zippopotam.us/' + code + '/' + zip).toPromise().then(response => {
              if (response.status === 200) {
                data =  response.json();
                var places = data["places"][0];
                city = places["place name"];
                state = code === "US" ? places["state abbreviation"] : places["state"];
    
                this.entity.shipAddress.city = city;
                this.entity.shipAddress.state = state;
    
                }
                else {
                  throw new Error(`Got back ${response.status}`);
                }
              }).catch(err => {
            });
          }
            else {
              this.entity.shipAddress.city = city;
              this.entity.shipAddress.state = state;
            }
          }
          else {
            this.entity.shipAddress.city = city;
            this.entity.shipAddress.state = state;
        }
      };
    
      private clear = function() {
        this.entity.shipAddress.zip = '';
        this.findCityStateFromZipCountry();
      }
    
}