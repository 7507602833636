﻿import { NgModule } from '@angular/core';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown'

import { SharedModule } from '../../shared/shared.module'
import { ExtProductionRequestListComponent } from './list/component'
import { ExtProductionRequestDetailComponent } from './detail/production-request-detail.component'
import { ExternalProductionRequestService } from './service';

@NgModule({
    imports: [
        SharedModule,
        BsDropdownModule.forRoot()
    ],
    declarations: [
        ExtProductionRequestListComponent,
        ExtProductionRequestDetailComponent,
    ],
    providers: [
        ExternalProductionRequestService
    ],
    exports: [
        ExtProductionRequestListComponent,
        ExtProductionRequestDetailComponent,
    ],
    entryComponents: [
        ExtProductionRequestListComponent,
        ExtProductionRequestDetailComponent,
    ]
})
export class ExtProductionRequestModule { }