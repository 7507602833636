﻿import { Component, Input } from '@angular/core';

import { EditEntityShipRequestVm } from '../../../models'

@Component({
    selector: 'ship-request-detail',
    templateUrl: './ship-request-detail.component.html',
})
export class ShipRequestDetailComponent {
    @Input() entity: EditEntityShipRequestVm;

    public showOverlay = false;
}