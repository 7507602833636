import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { SoftwarePermissionsComponent } from './software-permissions/software-permissions.component';
import { PermissionService } from '../models';

@NgModule({
    imports: [SharedModule],
    declarations: [
        SoftwarePermissionsComponent
    ],
    providers: [
        PermissionService
    ],
    exports: [
        SoftwarePermissionsComponent
    ],
    entryComponents: [
        SoftwarePermissionsComponent
    ]
})
export class PermissionModule { }
