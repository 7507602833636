﻿import { Component, OnInit, Inject } from '@angular/core'
import { Router } from '@angular/router'

import { UserGroupVm } from '../../models'
import { UserGroupService } from '../user-group.service'

@Component({
    selector: 'user-group-list',
    templateUrl: './user-group-list.component.html',
})
export class UserGroupListComponent implements OnInit {
    list: UserGroupVm[];

    constructor(
        private dataService: UserGroupService,
        private router: Router
    ) {
    }

    async ngOnInit(): Promise<void> {
        this.list = await this.dataService.getListVm();
    }

    navigateToEdit(id: number): void {
        this.router.navigate(['/userGroup/edit/' + id])
    }
}