import { Identity } from "../models/identity";

const STORAGE_CODE = 'authtoken'
let cachedUser: Identity

export const getUser = () : Identity => {
  if (!cachedUser) {
    const json = window.sessionStorage.getItem(STORAGE_CODE) || window.localStorage.getItem(STORAGE_CODE)
    cachedUser = JSON.parse(json)
  }
  return cachedUser
}

export const getToken = () => {
  const user = getUser()
  return user ? user.token : null
}

export const exists = () => !!getUser()

export const remove = () => {
  cachedUser = null
  window.sessionStorage.removeItem(STORAGE_CODE)
  window.localStorage.removeItem(STORAGE_CODE)
}

export const save = (user: Identity, remember) => {
  if (user) {
    cachedUser = user
    const json = JSON.stringify(user)
    window.sessionStorage.setItem(STORAGE_CODE, json)
    if (remember) {
      window.localStorage.setItem(STORAGE_CODE, json)
    } else {
      window.localStorage.removeItem(STORAGE_CODE)
    }
  } else {
    remove()
  }
}

