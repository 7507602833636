import { Component } from '@angular/core';

import * as models from '../../models';
import { EventBaseComponent } from '../event-base/event-base.component';
import { EventsService } from '../events.service';

@Component({
    selector: '<event-add-to-production-request>',
    templateUrl: './event-add-to-production-request.component.html'
})
export class EventAddToProductionRequestComponent extends EventBaseComponent {
    public custom = { orders: [{}], orderId: 0, error: '', comment: '' };

    constructor(public eventsService: EventsService) {
        super(eventsService);
    }

    ngOnInit(): void {
        this.model.wait = true;

        this.eventsService
            .getAddToProductionRequestVm(this.model.sn)
            .then(res => {
                this.custom.orders = res.orders;
                if (!res.orders.length) {
                    this.custom.error = 'There is no production request for selected Serial Number.';
                }
                this.model.wait = false;
            })
            .catch(error => {
                this.model.wait = false;
                this.model.error = error;
            });
    }

    public addToProductionRequest(): void {
        this.model.wait = true;

        const data = new models.AddToProductionsOrderBm();
        data.comment = this.custom.comment;
        data.orderId = this.custom.orderId;
        data.sn = this.model.sn;

        this.eventsService
            .addToProductionRequest(data)
            .then(() => {
                this.backToEvents();
            })
            .catch(error => {
                this.model.wait = false;
                this.model.error = error;
            });
    }
}