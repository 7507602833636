import { SnDetailVm, HistoryItemVm } from "../../models";
import { RmaOrderVm } from "./rma-order.vm";

export class RmaOrderDetailVm
{
	constructor (init?: Partial<RmaOrderDetailVm>)
	{
		Object.assign(this, init);
		
	}
	public rmaOrderDetail: RmaOrderVm;
	public snDetail: SnDetailVm;
	public history: HistoryItemVm[];
}