﻿import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';

import { SalesOrderVm, Enums } from '../../../models';
import { ExternalSalesOrderService } from '../service';

@Component({
    selector: 'sales-order-list',
    templateUrl: './component.html',
})
export class ExtSalesOrderListComponent implements OnInit {
  salesOrders: SalesOrderVm[];

  public showOverlay = true;

  public pageSize: number = 20;

  p: number = 1;
  
  constructor(
      private service: ExternalSalesOrderService,
      private router: Router
  ) { }

  async ngOnInit(): Promise<void> {
      this.applyFilter();
  }

  navigateToDetails(id: number): void {
    this.router.navigateByUrl('ext/sales-order/detail/' + id)
  }

  async applyFilter(): Promise<void> {
    this.showOverlay = true;

      this.salesOrders = await this.service.getListVm();
      this.salesOrders.forEach(x => {
          (<any>x).isHighlighted = (x.status == Enums.SalesOrderStatus.Created);
      })

      this.showOverlay = false;
  }

  getStatusName(value: Enums.SalesOrderStatus): string {
      return Enums.SalesOrderStatus[value];
  }
}