import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { SettingsMainComponent } from './main/main.component';
import { ConfigItemsComponent } from './config-items/config-items.component';
import { LogRecordComponent } from './log-record/log-record.component'
import { ConfigItemService } from '../models';

@NgModule({
    imports: [SharedModule],
    declarations: [
        SettingsMainComponent,
        ConfigItemsComponent,
        LogRecordComponent
    ],
    providers: [
        ConfigItemService
    ],
    exports: [
        SettingsMainComponent,
        ConfigItemsComponent,
        LogRecordComponent
    ],
    entryComponents: [
        SettingsMainComponent,
        ConfigItemsComponent,
        LogRecordComponent
    ]
})
export class SettingsModule { }
