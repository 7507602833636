﻿export
    class FileResult {

    public fileName: string;

    public data: Blob;

    public get fileType(): string {
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    }

    constructor(name: string, data: Blob) {
        this.fileName = name;
        this.data = data;
    }
}