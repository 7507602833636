import { Routes } from '@angular/router'

import { AccountListComponent } from '../account/account-list/account-list.component'
import { AccountEditComponent } from '../account/account-edit/account-edit.component'
import { ConfigItemsComponent } from '../settings/config-items/config-items.component'
import { DashboardMainComponent } from '../dashboard/dashboard-main/dashboard-main.component'
import { EventMainComponent } from '../events/event-main/event-main.component'
import { FlowDebugListComponent } from '../flow-debug/flow-debug-list/flow-debug-list.component'
import { LogRecordComponent } from '../settings/log-record/log-record.component'
import { ProductListComponent } from '../product/product-list/product-list.component'
import { ProductEditComponent } from '../product/product-edit/product-edit.component'
import { ProductionRequestListComponent } from '../production-request/production-request-list/production-request-list.component'
import { ProductionRequestDetailComponent } from '../production-request/production-request-detail/production-request-detail.component'
import { ProductionRequestCreateComponent } from '../production-request/production-request-create/production-request-create.component'

import { SalesOrderListComponent } from '../sales-order/sales-order-list/sales-order-list.component'
import { SalesOrderDetailComponent } from '../sales-order/sales-order-detail/sales-order-detail.component'
import { SalesOrderCreateComponent } from '../sales-order/sales-order-create/sales-order-create.component'

import { ProfileEditComponent } from '../profile/profile-edit/profile-edit.component'
import { PropertyListComponent } from '../property/property-list/property-list.component'
import { RmaOrderListComponent } from '../rma-order/rma-order-list/rma-order-list.component'
import { RmaOrderDetailComponent } from '../rma-order/rma-order-detail/rma-order-detail.component'
import { RmaOrderCreateComponent } from '../rma-order/rma-order-create/rma-order-create.component'
import { SerialNumberListComponent } from '../serial-number/serial-number-list/serial-number-list.component'
import { SettingsMainComponent } from '../settings/main/main.component'
import { ShipRequestEditComponent } from '../ship-request/ship-request-edit/ship-request-edit.component'
import { ShipRequestListComponent } from '../ship-request/ship-request-list/ship-request-list.component'
import { SignInComponent }      from '../sign-in/sign-in.component'
import { SnRegistrationListComponent } from '../sn-registration/sn-registration-list/sn-registration-list.component'
import { SnRegistrationEditComponent } from '../sn-registration/sn-registration-edit/sn-registration-edit.component'
import { SnRegistrationCreateComponent } from '../sn-registration/sn-registration-create/sn-registration-create.component'
import { SoftwareListComponent } from '../software/software-list/software-list.component'
import { SoftwareEditComponent } from '../software/software-edit/software-edit.component'
import { SoftwarePermissionsComponent } from '../permission/software-permissions/software-permissions.component'
import { SoftwareVersionListComponent } from '../software-version/software-version-list/software-version-list.component'
import { SoftwareVersionEditComponent } from '../software-version/software-version-edit/software-version-edit.component'
import { UserListComponent } from '../user/user-list/user-list.component'
import { UserEditComponent } from '../user/user-edit/user-edit.component'
import { UserGroupListComponent } from '../user-group/user-group-list/user-group-list.component'
import { UserGroupEditComponent } from '../user-group/user-group-edit/user-group-edit.component'
import { UserPropertyGroupListComponent } from '../user-property-group/user-property-group-list/user-property-group-list.component'

import { ExtEventMainComponent } from '../ext/events/event-main/event-main.component'
import { ExtShipRequestListComponent } from '../ext/ship-request/list/ext-ship-request-list.component'
import { ExtShipRequestEditComponent } from '../ext/ship-request/edit/component'
import { ExtSnRegistrationComponent } from '../ext/sn-registration/component'

import { ExtProductionRequestListComponent } from '../ext/production-request/list/component'
import { ExtProductionRequestDetailComponent } from '../ext/production-request/detail/production-request-detail.component'

import { ExtSalesOrderListComponent } from '../ext/sales-order/list/component'
import { ExtSalesOrderDetailComponent } from '../ext/sales-order/detail/sales-order-detail.component'

import { ErrorServerComponent } from '../error/server.component'
import { ErrorMxdLinkExpiredComponent } from '../error/mxd-link-expired.component'
import { ErrorFileNotFoundComponent } from '../error/file-not-found.component'
import { AlSnRegistrationComponent } from '../alien/sn-registration/component';
import { ExtProfileEditComponent } from '../ext/profile-edit/component';
import { Urls } from './urls';
import { EventHistoryComponent } from '../event-history/event-history.component'

export const AppRoutes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: 'accounts', component: AccountListComponent },
  { path: 'account/create', component: AccountEditComponent },
  { path: 'account/edit/:id', component: AccountEditComponent },
  { path: 'config-items', component: ConfigItemsComponent },
  { path: 'dashboard', component: DashboardMainComponent },
  { path: 'flowDebug', component: FlowDebugListComponent },
  { path: 'logRecords', component: LogRecordComponent },
  { path: 'new-event', component: EventMainComponent },
  { path: 'permission-softwares', component: SoftwarePermissionsComponent },
  { path: Urls.ProductList, component: ProductListComponent },
  { path: 'product/create', component: ProductEditComponent },
  { path: 'product/edit/:id', component: ProductEditComponent },
  { path: Urls.ProductionRequestList, component: ProductionRequestListComponent },
  { path: Urls.ProductionRequestDetails, component: ProductionRequestDetailComponent },
  { path: 'productionRequest/create', component: ProductionRequestCreateComponent },

  { path: Urls.SalesOrderList, component: SalesOrderListComponent },
  { path: Urls.SalesOrderDetails, component: SalesOrderDetailComponent },
  { path: 'salesOrder/create', component: SalesOrderCreateComponent },

  { path: 'profile', component: ProfileEditComponent },
  { path: 'property', component: PropertyListComponent },
  { path: 'rmaOrders', component: RmaOrderListComponent },
  { path: 'rmaOrder/detail/:id', component: RmaOrderDetailComponent },
  { path: 'rmaOrder/create', component: RmaOrderCreateComponent },
  { path: 'settings', component: SettingsMainComponent },
  { path: 'sns', component: SerialNumberListComponent },
  { path: Urls.ShipRequestList, component: ShipRequestListComponent },
  { path: 'ship-request/create', component: ShipRequestEditComponent },
  { path: Urls.ShipRequestEdit, component: ShipRequestEditComponent },
  { path: 'sign-in', component: SignInComponent },
  { path: 'sn/detail/:id', component: EventMainComponent },
  { path: Urls.SnRegistrationList, component: SnRegistrationListComponent },
  { path: Urls.SnRegistrationCreate, component: SnRegistrationCreateComponent },
  { path: Urls.SnRegistrationEdit, component: SnRegistrationEditComponent },
  { path: 'softwares', component: SoftwareListComponent },
  { path: 'software/create', component: SoftwareEditComponent },
  { path: 'software/edit/:id', component: SoftwareEditComponent },
  { path: 'software-versions', component: SoftwareVersionListComponent },
  { path: 'software-versions/:id', component: SoftwareVersionEditComponent },
  { path: 'userGroups', component: UserGroupListComponent },
  { path: 'userGroup/create', component: UserGroupEditComponent },
  { path: 'userGroup/edit/:id', component: UserGroupEditComponent },
  { path: 'user-property-groups', component: UserPropertyGroupListComponent },
  { path: 'users', component: UserListComponent },
  { path: 'user/create', component: UserEditComponent },
  { path: 'user/edit/:id', component: UserEditComponent },

  { path: 'ext/new-event', component: ExtEventMainComponent },
  { path: 'ext/sn/detail/:id', component: ExtEventMainComponent },
  { path: 'ext/production-requests', component: ExtProductionRequestListComponent },
  { path: 'ext/production-request/detail/:id', component: ExtProductionRequestDetailComponent },

  { path: 'ext/sales-orders', component: ExtSalesOrderListComponent },
  { path: 'ext/sales-order/detail/:id', component: ExtSalesOrderDetailComponent },

  { path: 'ext/profile-edit', component: ExtProfileEditComponent },
  { path: 'ext/ship-request', component: ExtShipRequestListComponent },
  { path: 'ext/ship-request/create', component: ExtShipRequestEditComponent },
  { path: 'ext/ship-request/edit/:id', component: ExtShipRequestEditComponent },
  { path: Urls.ExtShipRequestEdit, component: ExtShipRequestEditComponent },
  { path: 'ext/sn-registration', component: ExtSnRegistrationComponent },  
  
  { path: 'al/sn-registration', component: AlSnRegistrationComponent },

  { path: 'error/server', component: ErrorServerComponent },
  { path: 'error/mxd-link-expired', component: ErrorMxdLinkExpiredComponent },
  { path: 'error/file-not-found', component: ErrorFileNotFoundComponent },

  { path: Urls.EventHistoryList, component: EventHistoryComponent }
];