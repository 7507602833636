﻿import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { ProductListComponent } from './product-list/product-list.component';
import { ProductEditComponent } from './product-edit/product-edit.component';
import { ProductService } from './product-service';

@NgModule({
    imports: [
        SharedModule
    ],
    providers: [
        ProductService
    ],
    declarations: [
        ProductListComponent,
        ProductEditComponent
    ],
    exports: [
        ProductListComponent,
        ProductEditComponent
    ],
    entryComponents: [
        ProductListComponent,
        ProductEditComponent
    ]
})
export class ProductModule { }