﻿import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';

import { AccountVm } from '../../models';
import { AccountService } from '../account.service'

@Component({
    selector: 'account-list',
    templateUrl: './account-list.component.html',
})
export class AccountListComponent implements OnInit {
    list: AccountVm[];

    constructor(
        private dataService: AccountService,
        private router: Router) {
    }

    async ngOnInit(): Promise<void> {
        this.list = await this.dataService.getListVm();
    }

    navigateToEdit(id: number): void {
        this.router.navigate(['/account/edit/' + id])
    }
}