import { Injectable } from "@angular/core";
import { BaseService } from "../core/services/base.service";
import { Http } from "@angular/http";
import { RmaOrderNewVm, SaveResult } from "../models";
import { RmaOrderDetailVm } from "./rma-order-detail/rma-order-detail.vm";
import { RmaOrderVm } from "./rma-order-detail/rma-order.vm";

@Injectable()
export class RmaOrderService extends BaseService
{
  private baseUri: string = '';

	constructor (http: Http)
	{
    super(http)
    this.baseUri = `rmaOrder/`
  }  

	public getListVm() : Promise<RmaOrderVm[]>
	{
		return this.get<RmaOrderVm[]>(this.baseUri + `GetListVm`);
	}
	public getNewVm() : Promise<RmaOrderNewVm>
	{
		const uriParams = {  };
		return this.get<RmaOrderNewVm>(this.baseUri + `GetNewVm`, uriParams);
	}
	public getDetailVm(id: number) : Promise<RmaOrderDetailVm>
	{
		const uriParams = {  };
		return this.get<RmaOrderDetailVm>(this.baseUri + `GetDetailVm/${id}`, uriParams);
	}
	public save(model: RmaOrderNewVm) : Promise<SaveResult>
	{
		const uriParams = {  };
		return this.post<SaveResult>(this.baseUri + `Save`, model, uriParams);
	}
	public delete(id: number) : Promise<SaveResult>
	{
		const uriParams = {  };
		return this.post<SaveResult>(this.baseUri + `Delete/${id}`, null, uriParams);
	}
	public markAsDone(id: number) : Promise<SaveResult>
	{
		const uriParams = {  };
		return this.post<SaveResult>(this.baseUri + `MarkAsDone/${id}`, null, uriParams);
	}
}